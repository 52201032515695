import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("publicSite", ["stylesLoading", "styles", "websiteId"]),
    siteTitle() {
      const configKey =
        this.$i18n.locale === "fr" ? "header_title_fr" : "header_title_en";
      return this.$store.getters["publicSite/getStyleSetting"](
        configKey,
        this.$t("common.title"),
      );
    },
    isTelus() {
      return this.websiteId === Number(61);
    },
  },
};
