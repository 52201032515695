export default {
  title: "Profile fields",
  systemName: "System name",
  labelFrench: "Label (french)",
  labelEnglish: "Label (english)",
  visible: "Visible",
  required: "Required",
  order: "Order",
  editField: "Edit field",
  saveSuccessMessage: "Field saved!",
  failedChangeOrder: "Failed to change the order.",
};
