export default {
  title: "Présentation visuelle",
  saveSuccessMessage: "Réglages mis à jour",
  headerAdmin: "En-tête admin",
  headerAdminName: "Nom de la compagnie",
  headerAdminLogo: "Logo de la compagnie",
  headerAdminInvertedLogo: "Logo de la compagnie inversé",
  header: "En-tête",
  headerColor: "Couleur de l’en-tête",
  headerTitle: "Titre de l’en-tête",
  headerTitleColor: "Couleur du titre",
  headerImage: "Image de l’en-tête",
  headerImageAlt: "Image de l’en-tête – autre option",
  headerLinkColor: "Couleur du lien – en-tête",
  headerLinkActiveColor: "Couleur du lien actif – en-tête",
  headerLinkHoverColor: "Couleur du lien au passage de la souris",
  page: "Page",
  fontSize: "Taille du texte",
  font: "Police de caractères",
  chooseFont: "Choisir la police",
  backgroundColor: "Couleur du fond",
  contentBackgroundColor: "Couleur du contenu – fond",
  textColor: "Couleur du texte",
  menuColor: "Couleur du menu",
  menuTextColor: "Couleur du texte du menu",
  nextButtonColor: "Couleur du bouton suivant",
  nextButtonTextColor: "Couleur du texte du bouton suivant",
  previousButtonColor: "Couleur du bouton précédent",
  previousButtonTextColor: "Couleur du texte du bouton précédent",
  uploadButtonColor: "Couleur du bouton de téléchargement",
  uploadButtonTextColor: "Couleur du texte du bouton de téléchargement",
  confirmationColor: "Couleur de la boîte de confirmation",
  confirmationTextColor: "Couleur du texte de la boîte de confirmation",
  footer: "Pied de page",
  footerColor: "Couleur du pied de page",
  footerLinkColor: "Couleur du lien",
  banner: "Bannière",
  banner_mobile: "Bannière mobile",
  bannerAlt: "Texte alternatif",
  bannerPosition: "Position de la bannière",
  bannerPositionTop: "Au dessus du header",
  bannerPositionMiddle: "En dessous du header",
  bannerPositionBottom: "En dessous du contenu",
};
