export default {
  title: "Champs de profil",
  systemName: "Nom système",
  labelFrench: "Libellé français",
  labelEnglish: "Libellé anglais",
  visible: "Visible",
  required: "Requis",
  order: "Ordre",
  editField: "Édition",
  saveSuccessMessage: "Enregistré",
  failedChangeOrder: "Le changement d'ordre à échoué.",
};
