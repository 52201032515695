export default {
  tokenKeyName: "auth_token",
  stagingTokenKeyName: "staging_auth_token",

  guestRedirectPath: "/admin/login",
  memberRedirectPath: "/admin/dashboard",
  superAdminRedirectPath: "/admin/websites",

  jwtRefreshEnabled: process.env.VUE_APP_JWT_REFRESH_ENABLED !== "true",
};
