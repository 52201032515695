import api from "../../api";

export default {
  state: {
    emails: [],
    email: {},
    currentEmailId: null,
    emailsFetched: false,
    emailsLoading: false,
    emailSaved: false,
  },

  mutations: {
    EMAILS_START_FETCHING: (state) => {
      state.emailsLoading = true;
      state.emailSaved = false;
    },

    EMAILS_FETCHED: (state, pages) => {
      state.emails = pages;
      state.emailsFetched = true;
      state.emailsLoading = false;
    },

    EMAIL_FETCHED: (state, email) => {
      state.email = email;
      state.currentEmailId = email.id;
      state.emailsFetched = true;
      state.emailsLoading = false;
    },

    EMAIL_SAVED: (state, page) => {
      state.email = page;
      state.emailsFetched = true;
      state.emailsLoading = false;
      state.emailSaved = true;
    },
  },

  actions: {
    fetchEmails({ commit }, payload) {
      commit("EMAILS_START_FETCHING");

      return api.getEmails(payload).then((fields) => {
        commit("EMAILS_FETCHED", fields);
      });
    },

    async fetchEmail({ commit }, payload) {
      commit("EMAILS_START_FETCHING");

      return api.getCurrentEmail(payload).then((email) => {
        commit("EMAIL_FETCHED", email);
      });
    },

    async editFetchedEmail({ commit }, payload) {
      commit("EMAILS_START_FETCHING");

      return api.editCurrentEmail(payload).then((email) => {
        commit("EMAIL_SAVED", email);
      });
    },
  },
};
