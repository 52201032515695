import api from "../../api";

export default {
  state: {
    profileFields: [],
    profileField: [],
    profileFieldsFetched: false,
    profileFieldsLoading: false,
    profileFieldSaved: false,
  },

  mutations: {
    PROFILE_FIELDS_START_FETCHING: (state) => {
      state.profileFieldsLoading = true;
      state.profileFieldSaved = false;
    },

    PROFILE_FIELDS_FETCHED: (state, fields) => {
      state.profileFields = fields;
      state.profileFieldsFetched = true;
      state.profileFieldsLoading = false;
    },

    PROFILE_FIELD_FETCHED: (state, field) => {
      state.profileField = field;
      state.profileFieldsFetched = true;
      state.profileFieldsLoading = false;
    },

    PROFILE_FIELD_EDITED: (state, field) => {
      state.profileField = field;
      state.profileFieldsFetched = true;
      state.profileFieldsLoading = false;
      state.profileFieldSaved = true;
    },
  },

  actions: {
    fetchProfileFields({ commit }, payload) {
      commit("PROFILE_FIELDS_START_FETCHING");

      return api.getProfileFields(payload).then((fields) => {
        commit("PROFILE_FIELDS_FETCHED", fields);
      });
    },

    async fetchProfileField({ commit }, payload) {
      commit("PROFILE_FIELDS_START_FETCHING");

      return api.getCurrentProfileField(payload).then((field) => {
        commit("PROFILE_FIELD_FETCHED", field);
      });
    },

    async editFetchedProfileField({ commit }, payload) {
      commit("PROFILE_FIELDS_START_FETCHING");

      return api.editCurrentProfileField(payload).then((field) => {
        commit("PROFILE_FIELD_EDITED", field);
      });
    },
  },
};
