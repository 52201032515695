export default {
  actualVsBudget: "Budget vs Actual",
  list: "List",
  graph: "Graph",
  code: "Code",
  account: "Account",
  attributed: "Attributed",
  budgeted: "Budgeted",
  left: "Left",
  percentOfBudget: "% of budget",
  remaining: "Remaining",
  budget: "Budget Reports",
  actual: "Actual",
  total: "Total",
  comparative: "Comparative",
  from: "From",
  to: "To",
  show: "Show",
  attributedColumnLabel: "Attributed\n{budgetName}",
  budgetedColumnLabel: "Budgeted\n{budgetName}",
  financialYear: "Financial year",
  backToCategories: "Back to categories",
};
