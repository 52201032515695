export default {
  validationError: "Please fill all the required fields and try again.",
  validationErrorSection:
    "Please fill all required fields in the following section(s): {sectionNames}.",
  internalError: "An error occured, please try again.",
  saveError: "An error occured while saving, please try again.",
  pageLoadingError:
    "An error occured while loading the page, please try again.",
  forbidden: "You are not allowed to perform this action.",
  requiredField: "Required field",
  invalidField: "This field is invalid.",
  required: "This field is required",
  allRequired: "Please fill in all required fields",
  percentTotal: "Total of percentages must be between 0 and 100%",
  failedToLoadUserPreference: "Failed to load user preferences.",
};
