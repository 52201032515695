import api from "@/api";

export default {
  state: {
    features: {},
    fetched: false,
    fetching: false,
  },

  mutations: {
    FEATURES_START_FETCHING: (state) => {
      state.fetching = true;
    },
    FEATURES_FETCHED: (state, features) => {
      state.fetching = false;
      state.fetched = true;

      const featuresObj = {};
      features.forEach((feature) => {
        featuresObj[feature.name.upperCase()] = feature;
      });
      state.features = featuresObj;
      Object.freeze(state.features);
    },
  },

  actions: {
    async fetchFeatures({ commit }) {
      commit("FEATURES_START_FETCHING");
      try {
        const features = await api.getFeatures();
        commit("FEATURES_FETCHED", features);
      } catch {
        commit("FEATURES_STOP_FETCHING");
      }
    },
  },
};
