<template>
  <div
    class="form-group"
    :class="{ 'invalid-fields': hasError }"
    @click="removeFieldError()"
  >
    <slot></slot>
    <div
      class="invalid-feedback"
      v-if="hasError"
      role="alert"
      :aria-describedby="id ? id : name"
      aria-invalid="true"
      aria-live="polite"
    >
      <font-awesome-icon
        icon="fa-solid fa-circle-exclamation"
        aria-hidden="true"
      />
      {{ error }}
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  props: {
    id: {
      type: Number,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
    },
    autocomplete: {
      type: String,
      required: false,
    },
    errors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  computed: {
    hasError() {
      return this.errors && this.errors[this.name] !== undefined;
    },
    error() {
      if ((this.name === "position" || this.name === "city") && this.hasError) {
        return `${this.$t("errors.selectPosition")}`;
      }
      if (this.type === "choices" && this.hasError) {
        return `${this.$t("errors.chooseOption")}`;
      }
      return this.hasError ? this.errors[this.name][0] : "";
    },
  },
  methods: {
    removeFieldError() {
      Vue.delete(this.errors, this.name);
    },
  },
};
</script>
