import _ from "lodash";
import api from "../../api";

export default {
  state: {
    websites: [],
    website: [],
    websitesFetched: false,
    websitesLoading: false,
  },

  getters: {
    getWebsiteById: (id) => (state) => {
      return _.find(state.websites, { id });
    },
  },

  mutations: {
    WEBSITES_START_FETCHING: (state) => {
      state.websitesLoading = true;
    },

    WEBSITES_FETCHED: (state, websites) => {
      state.websites = websites;
      state.websitesFetched = true;
      state.websitesLoading = false;
    },

    WEBSITE_CREATED: (state, website) => {
      state.websites.push(website);
    },

    WEBSITE_DELETED: (state, id) => {
      const object = _.find(state.websites, { id });

      if (object) {
        const index = state.websites.indexOf(object);
        state.websites.splice(index, 1);
      }
    },

    WEBSITE_FETCHED: (state, website) => {
      state.website = website;
      state.websitesFetched = true;
      state.websitesLoading = false;
    },

    WEBSITE_UPDATED: (state, site) => {
      const object = _.find(state.websites, { id: site.id });

      if (object) {
        const index = state.websites.indexOf(object);
        state.websites.splice(index, 1, site);
      }
    },
  },

  actions: {
    fetchWebsites({ commit }) {
      commit("WEBSITES_START_FETCHING");

      return api.getWebsites().then((websites) => {
        commit("WEBSITES_FETCHED", websites);
      });
    },

    async createWebsite({ commit }, payload) {
      const website = await api.createWebsite(payload);
      commit("WEBSITE_CREATED", website);
      return website;
    },

    async fetchWebsite({ commit }, payload) {
      commit("WEBSITES_START_FETCHING");

      return api.fetchWebsite(payload).then((website) => {
        commit("WEBSITE_FETCHED", website);
      });
    },

    async editFetchedWebsite({ commit }, payload) {
      commit("WEBSITES_START_FETCHING");

      return api.editWebsite(payload).then((website) => {
        commit("WEBSITE_FETCHED", website);
      });
    },

    async deleteWebsite({ commit }, id) {
      await api.deleteWebsite({ id });
      commit("WEBSITE_DELETED", id);
    },

    async updateWebsite({ commit }, website) {
      const response = await api.editWebsite(website);
      commit("WEBSITE_UPDATED", response);
      return response;
    },
  },
};
