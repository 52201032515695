export default {
  title: "Manage Groups",
  titleGroups: "Groups",
  titleGroupRelations: "Groups relations",
  createNew: "Add new group",
  createNewRelation: "Add new group relation",
  filterByName: "Filter by name",
  name: "Name",
  description: "Description",
  confirmDeleteMessage: "Are you sure you want to delete this group?",
  createGroup: "Create Group",
  createGroupRelation: "Create Group relation",
  editGroup: "Edit Group",
  editGroupRelation: "Edit Group Relation",
  nameInputLabel: "Group Name",
  descriptionInputLabel: "Description (optional)",
  saveSuccessMessage: "Group saved.",
  relationGroupNameLabel: "Group",
  relationTypeNameLabel: "Relation type",
  relationNameLabel: "Relation",
};
