export default {
  title: "Pages",
  name: "",
  editPageTitle: "Edit {pageTitle} page content",
  editPageBreadcrumb: "Edit page content",
  titleFr: "Title (FR)",
  titleEn: "Title (EN)",
  uploadFilesFr: "Upload files label (FR)",
  uploadFilesEn: "Upload files label (EN)",
  urlFr: "URL label (FR)",
  urlEn: "URL label (EN)",
  additionalInfoFr: "Additional information label (FR)",
  additionalInfoEn: "Additional information label (EN)",
  contentFr: "Content (FR)",
  contentEn: "Content (EN)",
  saveSuccessMessage: "Page saved!",
  names: {
    submission: "Submission",
    terms: "Terms",
    privacy: "Privacy",
    home: "Home",
    additional_info: "Additional Information",
  },
};
