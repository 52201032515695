import Vue from "vue";
import api from "@/api";

const mapping = {
  request_table_custom_cols: "requestTableCustomCols",
  dashboard_interval: "dashboardInterval",
  dashboard_custom_interval: "dashboardCustomInterval",
};

export default {
  state: {
    requestTableCustomCols: [],
    dashboardInterval: "all",
    dashboardCustomInterval: null,
    fetched: false,
    fetching: false,
  },

  mutations: {
    SET_DASHBOARD_INTERVAL: (state, value) => {
      state.dashboardInterval = value;
    },
    SET_DASHBOARD_CUSTOM_INTERVAL: (state, value) => {
      state.dashboardCustomInterval = value;
    },

    SET_REQUEST_TABLE_CUSTOM_COLS: (state, value) => {
      state.requestTableCustomCols = value;
    },

    USER_PREFERENCES_START_FETCHING: (state) => {
      state.fetched = false;
      state.fetching = true;
    },

    USER_PREFERENCES_FETCH_FAILED: (state) => {
      state.fetched = false;
      state.fetching = false;
    },

    USER_PREFERENCES_FETCHED: (state, preferences) => {
      if (Array.isArray(preferences)) {
        preferences.forEach((preference) => {
          if (preference && preference.key && mapping[preference.key]) {
            const stateProp = mapping[preference.key];
            let stateValue = "";
            try {
              stateValue = JSON.parse(preference.value);
            } catch {
              stateValue = preference.value;
            }
            Vue.set(state, stateProp, stateValue);
          }
        });
      }
      state.fetched = true;
    },
  },

  actions: {
    async fetchUserPreferences({ commit }, userId) {
      commit("USER_PREFERENCES_START_FETCHING");

      try {
        const preferences = await api.userPreferences.query({ userId });
        commit("USER_PREFERENCES_FETCHED", preferences);
      } catch {
        commit("USER_PREFERENCES_FETCH_FAILED");
      }
    },

    async saveDashboardIntervalPreferences({ commit }, { value }) {
      commit("SET_DASHBOARD_INTERVAL", value);

      try {
        await api.userPreferences.saveConfig({
          key: "dashboard_interval",
          value: value,
        });
      } catch (error) {
        this.$noty.error(this.$t("admin.error.failedToLoadUserPreference"));
      }
    },

    async saveDashboardCustomIntervalPreferences({ commit }, { value }) {
      commit("SET_DASHBOARD_CUSTOM_INTERVAL", value);

      try {
        await api.userPreferences.saveConfig({
          key: "dashboard_custom_interval",
          value: JSON.stringify(value),
        });
      } catch (error) {
        this.$noty.error(this.$t("admin.error.failedToLoadUserPreference"));
      }
    },

    async saveRequestColsPreferences({ commit }, { value }) {
      commit("SET_REQUEST_TABLE_CUSTOM_COLS", value);

      try {
        await api.userPreferences.saveConfig({
          key: "request_table_custom_cols",
          value: JSON.stringify(value),
        });
      } catch {
        // TODO: log error
      }
    },
  },
};
