import "./initialize";
import "babel-polyfill";
import "es6-promise/auto";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import authenticate from "@/stagingAuth";

library.add(faCircleExclamation);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.mixin({
  watch: {
    "$i18n.locale": {
      handler(newLocale) {
        document.documentElement.setAttribute("lang", newLocale);
      },
      immediate: true,
    },
  },
});

async function startApp() {
  let isAuthenticated = true; // Default to true for non-staging environments

  if (process.env.VUE_APP_APP_ENV === "staging") {
    isAuthenticated = await authenticate();
  }

  if (isAuthenticated) {
    new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  } else {
    console.error("Authentication failed. Application initialization aborted.");
  }
}

startApp();

export const bus = new Vue();
