<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
if (window.top !== window.self) {
  window.top.location = window.self.location;
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
