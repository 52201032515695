export default {
  title: "Budget Management",
  addNew: "Add new budget",
  budgetLabel: "Budget {period}",
  code: "Code",
  accounts: "Accounts",
  account: "Account",
  attributedAmount: "Amount",
  percentOfTotal: "% of total budget",
  confirmDeleteMessage: "Are you sure you want to delete this budget?",
  addBudget: "Add a budget",
  editBudget: "Edit budget",
  addAccount: "Add account",
  selectAccount: "Select account",
  name: "Name",
  accountName: "Account name",
  saveSuccessMessage: "Budget has been saved.",
  duplicateBudgetMessage: "A budget already exist for this period.",
  setup: "Budget set-up",
  fiscalYear: "Company fiscal year",
  fiscalYearShort: "Fiscal year",
  starts: "Starts",
  ends: "Ends",
  createBudget: "Create a budget",
  budgetAccounts: "Budget Accounts",
  invalidPeriodError: "Please select a valid date range.",
  enterFiscalYear:
    "In order to create a budget, please enter your fiscal year start in the {generalSettings} page. ",
};
