import accessibility from "@/lang/admin/en/accessibility";
import accounts from "./en/accounts";
import auth from "./en/auth";
import budgets from "./en/budgets";
import common from "./en/common";
import components from "./en/components";
import dashboard from "./en/dashboard";
import emailTemplates from "./en/emailTemplates";
import errors from "./en/errors";
import groups from "./en/groups";
import menu from "./en/menu";
import pages from "./en/pages";
import profileFields from "./en/profileFields";
import questions from "./en/questions";
import reports from "./en/reports";
import requests from "./en/requests";
import settings from "./en/settings";
import styles from "./en/styles";
import users from "./en/users";
import properties from "./en/properties";

export default {
  accessibility,
  accounts,
  auth,
  budgets,
  common,
  components,
  dashboard,
  emailTemplates,
  errors,
  groups,
  menu,
  pages,
  profileFields,
  questions,
  reports,
  requests,
  settings,
  styles,
  users,
  properties,
};
