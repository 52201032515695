export default {
  state: {
    activeMenu: "dashboard",
    lang: "fr",
    lightHeader: false,
    backRoute: null,
  },

  mutations: {
    SET_LANGUAGE: (state, lang) => {
      state.lang = lang;
    },

    SET_ACTIVE_MENU: (state, menu) => {
      state.activeMenu = menu;
    },

    TOGGLE_LIGHT_HEADER: (state, status) => {
      state.lightHeader = status;
    },

    SET_BACK_ROUTE: (state, route) => {
      state.backRoute = route;
    },
  },
};
