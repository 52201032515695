import api from "../../api";

export default {
  state: {
    styles: [],
    stylesFetched: false,
    stylesLoading: false,
    stylesSaved: false,
  },

  mutations: {
    STYLES_START_FETCHING: (state) => {
      state.stylesLoading = true;
      state.stylesSaved = false;
    },

    STYLES_FETCHED: (state, fields) => {
      state.styles = fields;
      state.stylesFetched = true;
      state.stylesLoading = false;
    },

    STYLES_UPDATED: (state, fields) => {
      state.styles = fields;
      state.stylesFetched = true;
      state.stylesLoading = false;
      state.stylesSaved = true;
    },
  },

  actions: {
    async fetchStyles({ commit }, payload) {
      commit("STYLES_START_FETCHING");
      let currentStyle = api.getCurrentStyle(payload);
      if (currentStyle !== false) {
        return currentStyle.then((styles) => {
          commit("STYLES_FETCHED", styles);
        });
      }
    },

    async editCurrentStyle({ commit }, payload) {
      commit("STYLES_START_FETCHING");

      return api.editCurrentStyle(payload).then((styles) => {
        commit("STYLES_UPDATED", styles);
      });
    },
  },
};
