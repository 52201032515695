const state = {
  skipToMainContent: false,
  skipToProgressIndicator: false,
  isNavbarRendered: false,
};

const mutations = {
  setSkipToMainContent(state, value) {
    state.skipToMainContent = value;
  },
  setSkipToProgressIndicator(state, value) {
    state.skipToProgressIndicator = value;
  },
  setIsNavbarRendered(state, payload) {
    state.isNavbarRendered = payload;
  },
};

const actions = {
  triggerSkipToMainContent({ commit }) {
    commit("setSkipToMainContent", true);
  },
  resetSkipToMainContent({ commit }) {
    commit("setSkipToMainContent", false);
  },
  triggerSkipToProgressIndicator({ commit }) {
    commit("setSkipToProgressIndicator", true);
  },
  resetSkipToProgressIndicator({ commit }) {
    commit("setSkipToProgressIndicator", false);
  },
  updateNavbarRendered({ commit }, payload) {
    commit("setIsNavbarRendered", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
