export default {
  title: "Accounts",
  viewAll: "View all accounts",
  addAccount: "Add account",
  createNewAccount: "Create new account",
  addExistingAccount: "Add existing account",
  associateOtherAccount: "Associate budget with existing account",
  replaceNewAccount: "Replace with new account",
  addAccountBudgetTitle: "Add account to budget",
  editAccountBudgetTitle: "Edit budget account",
  name: "Name",
  code: "Code",
  accountName: "Account name",
  accountCode: "Account code",
  confirmDeleteHeadText: "Delete",
  confirmDeleteMessagePart1: 'Are you sure you want to delete account "',
  confirmDeleteMessagePart2: '"?',
  confirmDeleteWarningText:
    "Previous entry with this account will be kept, but the account won't be offered for new requests and new budgets created.",
  confirmDeleteCtaText: "Delete",
  confirmDeleteAccountBudget:
    "Are you sure you want to delete this account from the budget?",
  deleteSuccess: "The account has been deleted successfully",
  updateSuccess: "The account has been modified successfully",
  saveSuccess:
    "The account had been saved successfully|The accounts have been saved successfully",
  unprocessable:
    "An error has occurred. Please validate the entries and try again",
  editAccountTitle: "Edit account",
  codeError: "The code must not be empty",
  nameError: "The name must not be empty",
  duplicateCode: "This code is already in use",
};
