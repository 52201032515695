export default {
  title: "Pages",
  name: "",
  editPageTitle: "Modifier le contenu de la {pageTitle}",
  editPageBreadcrumb: "Modifier le contenu de la page",
  contentFr: "Contenu français",
  contentEn: "Contenu anglais",
  saveSuccessMessage: "Enregistré",
  names: {
    submission: "Page de confirmation",
    terms: "Page sur les conditions d’utilisation",
    privacy: "Page sur la confidentialité",
    home: "Page d’accueil",
    additional_info: "Page des renseignements additionnels",
  },
};
