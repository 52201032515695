import axios from "axios";
import config from "./config";
import http from "@/api/http";

let token = localStorage.getItem(config.auth.stagingTokenKeyName);

const promptForStagingCredentials = async () => {
  const username = prompt("Enter staging username:");
  const password = prompt("Enter staging password:");

  const encodedCredentials = btoa(`${username}:${password}`);

  try {
    const response = await http.post(
      `${config.api.path}/staging-auth`,
      {},
      {
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
        },
      },
    );

    const authToken = response.config.headers.Authorization.split(" ")[1];
    localStorage.setItem(config.auth.stagingTokenKeyName, authToken);

    axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    return true;
  } catch (error) {
    alert("Staging authentication failed. Please try again.");
    console.error("Staging authentication failed:", error);
    return false;
  }
};

const authenticate = async (attempt = 0) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return true;
  }

  if (attempt >= 3) {
    alert("Maximum login attempts exceeded.");
    return false;
  }

  const isAuthenticated = await promptForStagingCredentials();
  if (!isAuthenticated) {
    return authenticate(attempt + 1);
  }

  return true;
};

export default authenticate;
