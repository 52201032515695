export default {
  data() {
    return {
      permissionEnum: {
        BenefitsManage: "benefits.manage",
        BudgetsView: "budgets.view",
        BudgetComparativeView: "budget.comparative.view",
        BudgetsManage: "budgets.manage",
        DashboardView: "dashboard.view",
        EmailManage: "emails.manage",
        EmailSend: "emails.send",
        GroupsManage: "groups.manage",
        GroupsView: "groups.view",
        PagesManage: "pages.manage",
        ProfileFieldsManage: "profile_fields.manage",
        PropertyEvaluationManage: "property.evaluation.manage",
        QuestionManage: "questions.manage",
        SubmissionsAccountManage: "submission.account.edit",
        SubmissionsAccountView: "submission.account.view",
        SubmissionsApprove: "submissions.approve",
        SubmissionsApproveOwn: "submissions.approve_own",
        SubmissionsAssign: "submissions.assign",
        SubmissionsAssignOwn: "submissions.assign_own",
        SubmissionsAttachment: "submissions.attach",
        SubmissionsAttachmentOwn: "submissions.attach_own",
        SubmissionsBudgetManage: "submissions.budget.manage",
        SubmissionsBudgetView: "submissions.budget.view",
        SubmissionsBudgetViewOwn: "submissions.budget.view_own",
        SubmissionsComment: "submissions.comment",
        SubmissionsCommentOwn: "submissions.comment_own",
        SubmissionsCreate: "submissions.create",
        SubmissionsDeleteComment: "submissions.delete_comment",
        SubmissionsDeleteCommentOwn: "submissions.delete_own_comment",
        SubmissionsDelete: "submissions.delete",
        SubmissionsDeleteOwn: "submissions.delete_own",
        SubmissionsEdit: "submissions.edit",
        SubmissionsEditOwn: "submissions.edit_own",
        SubmissionsEvaluationView: "submissions.evaluation.view",
        SubmissionsExportCsv: "submissions.export.csv",
        SubmissionsView: "submissions.view",
        SubmissionsViewOwn: "submissions.view_own",
        UsersManage: "users.manage",
        UsersManageOwn: "users.manage_own",
        SettingsManage: "settings.manage",
        StylesManage: "styles.manage",
        StylesManageOwn: "styles.manage_own",
        WebsiteManage: "website.manage",
      },
    };
  },

  methods: {
    hasPermission(permissionToVal) {
      return this.$store.state.permissions.items.includes(permissionToVal);
    },
  },
};
