export default {
  accepted: "Accepted",
  autoRejected: "Automatically rejected by the system:",
  averageAmountRequested: "Average amount requested",
  averageAmountAwared: "Average amount awarded",
  averageAudience: "Average audience",
  averageCostPerPerson: "Average cost per person",
  categoryOfRequests: "Requests categories",
  currentYear: "Year to date",
  customPeriod: "Custom period",
  details: "Details",
  evaluation: "Evaluation",
  fromBeginning: "All-time",
  fromMonthAgo: "Previous month",
  fromSixMonthsAgo: "Previous 6 months",
  fromThreeMonthsAgo: "Previous 3 months",
  fromYearAgo: "Previous year",
  lastYear: "Previous year",
  max: "Max.",
  min: "Min.",
  monthsShort: [
    "Jan.",
    "Feb.",
    "Mar.",
    "Apr.",
    "May.",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sep.",
    "Oct.",
    "Nov.",
    "Dec.",
  ],
  na: "N/A",
  negotiation: "Negotiation",
  numberOfRequests: "Number of requests",
  organisationType: "Organization type",
  pending: "Pending",
  ppl: "pp.",
  receivedLast30days: "Received in the last 30 days:",
  rejected: "Rejected",
  requestAccepted: "Accepted requests",
  requestReceived: "Received requests",
  reviewing: "In review",
  sixMonths: "Previous 6 months",
  sponsorshipType: "Sponsorship type",
  submissions: "Requests",
  thisYear: "This year",
  threeMonths: "Previous 3 months",
  title: "Dashboard",
  total: "Total",
  year: "Previous 12 months",
};
