<template>
  <header role="banner" class="navbar">
    <div class="container">
      <div class="navbar-header col-12 w-100 p-2">
        <router-link class="navbar-brand" :to="{ name: 'front.home' }">
          <span v-if="headerImage !== ''"
            ><img
              :class="!isTelus ? 'site-logo' : 'telus-logo'"
              :src="headerImage"
              :alt="headerImageAlt"
          /></span>
          <span v-else class="site-title">{{ headerImageAlt }}</span>
        </router-link>
        <div class="float-right header-reverse">
          <h1 class="site-title" v-if="!isTelus">{{ siteTitle }}</h1>
          <div
            v-if="displaySwitchLang"
            class="lang text-right"
            :class="!isTelus ? 'mb-4' : ''"
          >
            <a
              tabindex="1"
              role="button"
              @click.prevent="changeLang('fr')"
              @keydown.prevent.enter.space="changeLang('fr')"
              :class="{ 'lang-select': selectedLang === 'fr' }"
              >{{ $t("common.french") }}</a
            >
            |
            <a
              tabindex="2"
              role="button"
              @click.prevent="changeLang('en')"
              @keydown.prevent.enter.space="changeLang('en')"
              :class="{ 'lang-select': selectedLang === 'en' }"
              >{{ $t("common.english") }}</a
            >
          </div>
          <div v-else class="mb-4 lang-disable"></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import publicSettings from "@/mixins/public/settings";
import publicSite from "@/mixins/public-site";

export default {
  name: "header-nav",
  mixins: [publicSite, publicSettings],
  data() {
    return {
      // ...
    };
  },
  methods: {
    changeLang(lang) {
      localStorage.setItem("user_lang", lang);
      this.$i18n.locale = lang;
    },
  },
  computed: {
    selectedLang() {
      return this.$i18n.locale;
    },
    headerImage() {
      const locale = this.$i18n.locale;
      let logo =
        locale === "fr"
          ? this.styles.header_image_fr_url
          : this.styles.header_image_en_url;
      return logo || "";
    },
    headerImageAlt() {
      let alt = "";
      if (this.$i18n.locale === "fr") {
        alt = this.styles.header_alt_image_fr;
      } else {
        alt = this.styles.header_alt_image_en;
      }
      return alt;
    },
    subdomain() {
      const hostname = window.location.hostname;
      const segments = hostname.split(".");
      return segments[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.header-reverse {
  display: flex;
  flex-direction: column-reverse;
}
.telus-logo {
  max-width: 280px;
}
</style>
