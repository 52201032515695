<template>
  <div>
    <footer class="text-center col-12 footer-color py-3">
      <a
        class="footer-link-color"
        aria-label="privacy"
        href="/privacy"
        target="_blank"
        >{{ $t("footer.privacy") }}</a
      >
      |
      <a
        class="footer-link-color"
        aria-label="terms"
        href="/terms"
        target="_blank"
        >{{ $t("footer.terms") }}</a
      >
    </footer>
    <div class="extra-footer">
      <div class="container">
        <div v-if="!isTelus" class="sponsored-logo">
          <img src="../../../assets/logo.svg" alt="brickroad-logo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import publicSite from "@/mixins/public-site";

export default {
  name: "public-footer",
  mixins: [publicSite],
};
</script>

<style lang="scss">
.extra-footer {
  background-color: white;
  text-align: right;
  padding-bottom: 1em;
}
</style>
