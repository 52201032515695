<template>
  <div>
    <div v-if="displayBannerLogo" class="text-center pt-2 pb-2">
      <img
        class="banner-logo"
        :src="displayBannerLogo"
        :alt="displayBannerAlt"
      />
    </div>
  </div>
</template>

<script>
import publicSite from "@/mixins/public-site";

export default {
  name: "Banner",
  mixins: [publicSite],
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    displayBannerLogo() {
      switch (this.$i18n.locale) {
        case "fr":
          if (
            window.outerWidth < 500 &&
            this.styles.banner_mobile_image_fr &&
            this.styles.banner_mobile_image_fr !== ""
          ) {
            return this.styles.banner_mobile_image_fr_url;
          } else if (
            this.styles.banner_image_fr &&
            this.styles.banner_image_fr !== ""
          ) {
            return this.styles.banner_image_fr_url;
          }
          break;

        case "en":
          if (
            window.outerWidth < 500 &&
            this.styles.banner_mobile_image_en &&
            this.styles.banner_mobile_image_en !== ""
          ) {
            return this.styles.banner_mobile_image_en_url;
          } else if (
            this.styles.banner_image_en &&
            this.styles.banner_image_en !== ""
          ) {
            return this.styles.banner_image_en_url;
          }
          break;

        default:
          return null;
      }
    },
    displayBannerAlt() {
      switch (this.$i18n.locale) {
        case "fr":
          return this.styles.banner_alt_image_fr;

        case "en":
          return this.styles.banner_alt_image_en;

        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-logo {
  max-width: 100%;
  max-height: 90px;
}
</style>
