import api from "../../api";
import _ from "lodash";

export default {
  state: {
    items: [],
    loading: false,
    fetched: false,
  },

  mutations: {
    PRODUCTS_START_FETCHING: (state) => {
      state.loading = true;
    },

    PRODUCTS_FETCHED: (state, items) => {
      state.items = items;
      state.fetched = true;
      state.loading = false;
    },

    PRODUCT_CREATED: (state, item) => {
      state.items.push(item);
    },

    PRODUCT_UPDATED: (state, product) => {
      const object = _.find(state.items, { id: product.id });

      if (object) {
        const index = state.items.indexOf(object);
        state.items.splice(index, 1, product);
      }
    },

    PRODUCT_DELETED: (state, id) => {
      const object = _.find(state.items, { id });

      if (object) {
        const index = state.items.indexOf(object);
        state.items.splice(index, 1);
      }
    },
  },

  actions: {
    async fetchProducts({ state, commit }, query) {
      if (!state.loading) {
        commit("PRODUCTS_START_FETCHING");
        const items = await api.products.query(query);
        commit("PRODUCTS_FETCHED", items);
      }
    },

    async createProduct({ commit }, product) {
      const createdProduct = await api.products.create(product);
      commit("PRODUCT_CREATED", createdProduct);
      return createdProduct;
    },

    async updateProduct({ commit }, product) {
      const updatedProduct = await api.products.update(product);
      commit("PRODUCT_UPDATED", updatedProduct);
      return updatedProduct;
    },

    async deleteProduct({ commit }, { websiteId, productId }) {
      await api.products.delete({ websiteId, id: productId });
      commit("PRODUCT_DELETED", productId);
    },
  },
};
