export default {
  title: "Notifications par courriel",
  event: "",
  editTemplate: "Notification pour {templateName}",
  editTemplateBreadcrumb: "Modifier la notification par courriel",
  delay: "Délai d’envoi",
  delayHelpText: "Le délai d’envoi est en heures; 0 = Envoyer maintenant",
  subjectFrench: "Objet – français",
  subjectEnglish: "Objet – anglais",
  contentFrench: "Contenu français",
  contentEnglish: "Contenu anglais",
  active: "Activé ",
  saveSuccessMessage: "Courriel enregistré",
  names: {
    application_received: "Demande reçue",
    application_accepted: "Demande acceptée",
    application_rejected: "Demande rejetée",
    communication: "Communication",
    property_reminder: "Courriel de rappel (questionnaire d'évaluation)",
    property_invitation: "Courriel d'invitation (questionnaire d'évaluation)",
  },
  settings: "Réglages",
  defaultTemplate: "Modèle de courriel par défaut",
  defaultTemplateHelpText:
    "Le contenu du courriel peut être modifié avant d’accepter une demande",
  switchLabel: {
    application_received:
      "Envoi d’un courriel automatisé lorsqu’une demande est reçue",
    application_accepted:
      "Envoi d’un courriel automatisé lorsqu’une demande est acceptée",
    application_rejected:
      "Envoi d’un courriel automatisé lorsqu’une demande est rejetée",
    communication: "Envoi d'un courriel de communication au demandeur",
    property_reminder:
      "Envoi d'un courriel de rappel automatisé pour le remplissage d'une propriété",
  },
  missingFields: "Un champ requis est manquant",
};
