export default {
  title: "Email notifications",
  event: "",
  editTemplate: "Email notification for {templateName}",
  editTemplateBreadcrumb: "Edit email nofiticafion",
  delay: "Email delay",
  delayHelpText: "the delay is in hours, 0 = send now.",
  subjectFrench: "Subject (FR)",
  subjectEnglish: "Subject (EN)",
  contentFrench: "Content (FR)",
  contentEnglish: "Content (EN)",
  active: "Is active",
  saveSuccessMessage: "Email template saved!",
  names: {
    application_received: "Application received",
    application_accepted: "Application accepted",
    application_rejected: "Application rejected",
    communication: "Communication",
    property_reminder: "Reminder email (valuation questionnaire)",
    property_invitation: "Invitation email (valuation questionnaire)",
  },
  settings: "Settings",
  defaultTemplate: "Default Email Template",
  defaultTemplateHelpText:
    "The content of the email can also be edited before accepting an application",
  switchLabel: {
    application_received:
      "Send an email to applicant when receiving an application",
    application_accepted:
      "Send an email to applicant when accepting an application",
    application_rejected:
      "Send an email to applicant when rejecting an application",
    communication: "Send a communication email to the applicant",
    property_reminder:
      "Send an automated reminder email to applicant to fill a property form",
  },
  missingFields: "A missing field is required",
};
