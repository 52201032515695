export default {
  accepted: "Acceptée",
  autoRejected: "Rejeté automatiquement par le système :",
  averageAmountRequested: "Montant moyen demandé",
  averageAmountAwared: "Montant moyen attribué",
  averageAudience: "Audience moyenne",
  averageCostPerPerson: "Coût moyen par personne",
  categoryOfRequests: "Catégories des demandes",
  currentYear: "Année en cours",
  customPeriod: "Période personnalisée",
  details: "Détails",
  evaluation: "Évaluation",
  fromBeginning: "Tout le temps",
  fromMonthAgo: "Le dernier mois",
  fromSixMonthsAgo: "Depuis 6 mois",
  fromThreeMonthsAgo: "Depuis 3 mois",
  fromYearAgo: "Depuis un an",
  lastYear: "Année dernière",
  max: "Max.",
  min: "Min.",
  monthsShort: [
    "Jan.",
    "Fev.",
    "Mar.",
    "Avr.",
    "Mai.",
    "Jui.",
    "Jul.",
    "Aou.",
    "Sep.",
    "Oct.",
    "Nov.",
    "Dec.",
  ],
  organisationType: "Type d'organisation",
  na: "N/D",
  negotiation: "Négociation",
  numberOfRequests: "Nombre de demandes",
  pending: "En attente",
  ppl: "pers.",
  receivedLast30days: "Reçu dans les 30 derniers jours :",
  rejected: "Rejetée",
  requestAccepted: "Demandes acceptées",
  requestReceived: "Demandes reçues",
  reviewing: "En révision",
  sixMonths: "6 derniers mois",
  sponsorshipType: "Type de commandite",
  submissions: "Demandes",
  thisYear: "Cette année",
  threeMonths: "3 derniers mois",
  title: "Tableau de bord",
  total: "Total",
  year: "12 derniers mois",
};
