export default {
  title: "Requests",
  showTitle: "Request",
  referenceNumber: "Reference number",
  exportCSV: "Export in CSV",
  addRequest: "Add request",
  status: "Status",
  allStatus: "All status",
  pending: "Pending",
  rejected: "Rejected",
  accepted: "Accepted",
  evaluation: "Property evaluation",
  negotiation: "Negotiation",
  reviewing: "In review",
  setStatusTo: "Set to...",
  search: "Search",
  inAllFields: "in All fields",
  inOrganization: "in Company",
  inReference: "in Reference",
  inEmail: "in Email",
  inAddress: "in Address",
  inEventName: "in Event name",
  hideFilters: "Hide filters",
  showFilters: "Show filters",
  clearFilters: "Clear filters",
  groups: "Groups",
  period: "Date submitted",
  sinceInception: "Since inception",
  thisYear: "This year",
  last6months: "Last 6 months",
  last3months: "Last 3 months",
  lastMonths: "Last month",
  customPeriod: "Custom period",
  country: "Country",
  allCountry: "All countries",
  state: "State / Province",
  budgetYear: "Budget year",
  optionSelected: "option(s) selected",
  owner: "Owner",
  location: "City",
  amount: "Amount",
  sizeAudience: "Size of audience",
  typeSponsorship: "Type of sponsorship",
  category: "Category",
  typeOrganization: "Type of organization",
  dateEvent: "Date of event",
  periodStart: "Period start",
  periodEnd: "Period end",
  columns: "Columns",
  refresh: "Refresh",
  reference: "Reference",
  company: "Company",
  date: "Date",
  score: "Score",
  eventName: "Event name",
  attributedBudget: "Attributed amount",
  action: "Action",
  confirmDeleteMessage: "Are you sure you want to delete this request?",
  comments: "Comments",
  noComments: "No comments...",
  addComment: "Add comment",
  commentSingular: "comment",
  commentPlural: "comments",
  history: "History",
  event: "Event",
  by: "By",
  viewMessage: "View message",
  viewMore: "View more",
  submission: "Submission",
  referenceLabel: "Reference:",
  assigneeLabel: "Assignee:",
  groupLabel: "Groups:",
  statusLabel: "Status:",
  scoreLabel: "Score:",
  dateLabel: "Date:",
  langLabel: "Lang:",
  noteLabel: "Note:",
  none: "none",
  profileFields: "Profile fields",
  questions: "Questions",
  files: "Files added with request",
  adminFiles: "Files",
  uploadFiles: "Upload files",
  confirmationDeleteFiles: "Do you want to delete the file?",
  fileDeleted: "File successfully deleted",
  successAttachFile: "File(s) successfully added",
  noFiles: "No files",
  postNewComment: "Post a new comment",
  commentSuccessfullyAdded: "Comment successfully added",
  comment: "Comment",
  accept: "Accept Request",
  reject: "Reject Request",
  revertToPending: "Revert to Pending",
  openCommunication: "Communication",
  confirmCommentDeletion: "Are you sure you want to delete this comment?",
  attributeAmount: "Attribute amount",
  attributedAmount: "Attributed amount",
  noSponsorshipBudget: "No budget entered",
  attributedAmountsSaved: "Attributed amounts saved",
  numberOfYears: "Number of Years",
  startDate: "Start date",
  endDate: "End date",
  updateBudget: "Update budget",
  budgetYearLabel: "Year {year}",
  account: "Account",
  financialContribution: "Financial contribution",
  productContribution: "Contribution in products and services",
  total: "Total",
  subtotal: "Sub-total",
  addAccount: "Add Account",
  contributionType: "Type of contribution",
  financial: "Financial",
  inProductsAndServices: "In product and services",
  both: "Both",
  productsAndServices: "Products and services",
  addItems: "Add items",
  valuePlaceholder: "Value ($)",
  createNewProduct: "Create new product",
  editProductList: "List of products and services",
  editProductListLink: "Edit list items",
  productName: "Product name",
  assignRequest: "Assign request",
  editField: "Edit field",
  user: "User",
  changeGroup: "Change group",
  changeAssignee: "Change assignee",
  message: "Message",
  to: "To:",
  from: "From:",
  subject: "Subject:",
  messageContent: "Content:",
  sendMessage: "Send message:",
  sendMessageTooltip:
    'You can change default email notification settings in the section "Communications / Email contents"',
  asScheduled: "As scheduled",
  afterDelay: "After a delay of",
  hours: "hour(s)",
  immediately: "Immediately",
  dontSendEmail: "Change status without sending a message",
  willBeSentOn: "Will be sent on {dateTime}",
  sentOn: "Sent on {dateTime}",
  confirmEmailDeletion: "Do you really want to delete this email?",
  totalAttributed: "Total attributed:",
  totalFinancial: "Financial contribution:",
  totalProducts: "Products and services:",
  numberOfRequests: "Number of requests:",
  assignees: "Assignees",
  assigneeChanged: "Request successfully assigned",
  assignWarning: "Warning:",
  assignWarningMessage: "you may lose access to this page",
  assigneeRemoveSuccess: "No one is now assigned to this request",
  confirmDeleteAssignee:
    "Removing your user from this request will remove your ability to access it. Would you like to proceed?",
  groupRemoveSuccess: "No group is now assigned to this request",
  groupAssigned: "Group successfully assigned",
  groupModified: "Group successfully modified",
  assigneeModified: "Assignee successfully modified",
  eliminative: "Eliminative",
  points: "points",
  goback: "Back to requests",
  videoUrl: "Video URL",
  deleteField: "Delete field",
  deleteFieldConfirmation: "Do you really want to delete this answer",
  downloadFile: "Download file",
  downloadPdf: "Download PDF",
  downloadCSV: "Download CSV",
  exportCsv: "Export CSV",
  preparingDownload:
    "The download has been initiated. Once the file is ready, it will download automatically.",
  fileWillBeSentByEmail:
    "The file's download link will be sent to you via email.",
  statusUpdatedSuccessfully: "Status updated succesfully!",
  statusUpdateFailed: "Error while updating status",
  addRequestPage: {
    language: "Language",
    french: "French",
    english: "English",
    profile: "Profile",
    questions: "Application questions",
    additionalInfo: "Additional information",
  },
  months: "months",
  deleted: "deleted",
  missingBudgets:
    "One or more budgets have not yet been set up for the given years. Go to {budgets} to enable the feature.",
  revertToPendingWarning:
    "If you move this request back to pending, the financial information of the agreement will be deleted.",
  property: {
    steps: "Steps",
    howTo: "How to complete a property evaluation?",
    toQuestionnaire: "Go to questionnaire",
    questionnaireNotBeenShared:
      "The questionnaire has not been shared with the property owner.",
    questionnaireNotBeingShared:
      "The questionnaire is not being shared with the property owner.",
    questionnaireShared: "Questionnaire shared with ",
    questionnaireVisitedOn: "Questionnaire was visited on ",
    questionnaireUpdatedOn: "Questionnaire was last updated on ",
    questionnaireCompletedOn: "Questionnaire was completed on ",
    questionnaireExpiresAt:
      "The link to complete the questionnaire will expire ",
    questionnaireExpiredAt:
      "The link to complete the questionnaire has expired ",
    pushBackExpirationDate:
      "Click on 'Share questionnaire' to push back its expiration date",
    fillQuestionnaire: "Fill questionnaire",
    reviewAndComplete:
      "Property owner has completed the questionnaire. Review and complete the questionnaire.",
    generateScoreCard: "Generate scorecard",
    downloadSPF: "SPF Download",
    timeAllowed:
      "Property owner has {delay} to fill in the questionnaire and send it back. A reminder is sent automatically after 48 hours and every 7 days.",
    thirtyDays: "30 days",
    threeMonths: "3 months",
    open: "Open questionnaire",
    sendReminder: "Send reminder",
    shareQuestionnaire: "Share questionnaire",
    stopSharing: "Stop sharing questionnaire",
    questionnaireSharedNotif: "Questionnaire is now accessible",
    questionnaireNotSharedNotif: "Questionnaire is no longer accessible",
    questionnaireShareErrorNotif:
      "An error happened while sharing questionnaire",
    questionnaireExpired:
      "The questionnaire has expired and is no longer accessible.",
    questionnaireLifetimeAdded: "30 days added to the questionnaire lifetime",
    questionnaireLifetimeError: "An error happened while adding lifetime",
    cannotShareWhileRejected:
      "The questionnaire cannot be shared while the property is rejected",
    invitationSent: "Invitation email sent successfully",
    invitationError: "An error happened while sending an invitation email",
    sharingPropertyError: "An error happened while sharing the property",
    reminderSent: "Reminder email sent successfully",
    reminderError: "An error happened while sending a reminder email",
    scorecard: "Scorecard",
    brandProfileRequired:
      "A brand profile must be selected in General Settings to generate scorecard",
    propertyCompleteRequired:
      "Complete the questionnaire to generate the scorecard",
    questionnaireStillShared:
      "Do you want to keep the access to the questionnaire open?",
    questionnaireStillSharedWarning:
      "Anyone with the link can access and edit the questionnaire.",
    turnOffAndSave: "Turn off access",
    keepOnAndSave: "Keep access on",
  },
  tabs: {
    applicationInfo: "Application",
    propertyEvaluation: "Evaluation",
    agreementInfo: "Agreement",
  },
  updateStatus: "Update status",
};
