import api from "../api";
import config from "../config";
import router from "../router";
import Vue from "vue";

export default {
  state: {
    authenticated: false,
    user: {
      id: "",
      name: "",
      role: "",
      website_id: "",
      website: "",
      license_id: null,
    },
    userFetched: false,
  },

  mutations: {
    USER_AUTHENTICATED: (state) => {
      state.authenticated = true;
    },

    USER_LOGGED_OUT: (state) => {
      state.authenticated = false;
      state.user = {};
      state.userFetched = false;
    },

    CURRENT_USER_FETCHED: (state, currentUser) => {
      state.user.id = currentUser.id;
      state.user.name = currentUser.name;
      state.user.role = currentUser.role;
      state.user.license_id = currentUser.license_id;
      state.user.website = currentUser.website;
      state.user.website_id = currentUser.website_id;
      state.userFetched = true;
    },

    WEBSITE_ID_FETCHED: (state, websiteId) => {
      state.user.website_id = websiteId;
    },
  },

  actions: {
    async verifyTwoFactorCode({ dispatch }, { code, email, lang = "en" }) {
      const response = await api.verifyTwoFactorCode(code, email, lang);
      dispatch("authenticateUser", response);
    },
    async login({ dispatch }, { credentials, lang = "en" }) {
      const response = await api.getAuthToken(credentials, lang);
      await dispatch("authenticateUser", response);
    },
    async loginSaml({ dispatch }, { accessToken, idToken, lang = "en" }) {
      const response = await api.getAuthTokenSaml(accessToken, idToken, lang);
      dispatch("authenticateUser", response);
    },
    async authenticateUser({ commit, dispatch }, response) {
      localStorage.setItem(config.auth.tokenKeyName, response.token.token);
      localStorage.setItem("user_name", response.name);
      localStorage.setItem("user_id", response.id);
      localStorage.setItem("user_website", response.website);
      localStorage.setItem("user_websiteId", response.website_id);
      localStorage.setItem("user_role", response.role.name);
      localStorage.setItem("user_license_id", response.license_id);
      localStorage.removeItem("two_factor_email");

      commit("USER_AUTHENTICATED");
      commit("CURRENT_USER_FETCHED", {
        id: response.id,
        name: response.name,
        role: response.role.name,
        website_id: response.website_id,
        website: response.website,
        license_id: response.license_id,
      });
      dispatch("fetchUserPreferences");
      dispatch("fetchPermissions");
      if (response.license_id) {
        await dispatch("fetchCurrentLicense", response.license_id);
      }
    },
    logout({ commit }) {
      localStorage.removeItem(config.auth.tokenKeyName);
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_websiteId");
      localStorage.removeItem("user_website");
      localStorage.removeItem("user_role");
      localStorage.removeItem("user_license");
      localStorage.removeItem("permissions");

      // eslint-disable-next-line no-undef
      Vue.$honeybadger.resetContext();

      router.push(config.auth.guestRedirectPath);
      commit("USER_LOGGED_OUT");
    },

    setWebsiteId({ commit }, payload) {
      commit("WEBSITE_ID_FETCHED", payload);
    },

    forgotPassword(context, payload) {
      return api.forgotPassword(payload);
    },

    resetPassword(context, payload) {
      return api.resetPassword(payload, payload.lang);
    },

    changePassword(context, { form, lang }) {
      return api.changePassword(form, lang);
    },
  },
};
