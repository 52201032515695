export default {
  menu: {
    profile: "Your profile",
    project: "Questions",
    additional: "Additional information",
    confirmation: "Confirmation",
  },
  footer: {
    poweredBy: "Powered by",
    terms: "Terms of use",
    privacy: "Privacy",
  },
  common: {
    french: "Français",
    english: "English",
    title: "Sponsorship request",
    next: "Next",
    nextStep: "Next step",
    prevStep: "Previous step",
    sendRequest: "Send this request",
    maxChars: "Max {maxLength} characters.",
    value: "Value",
    browse: "Browse...",
    backToLogin: "Back to login page",
    enterDate: "Enter date",
    enterPosition: "Type and select address",
    enterCity: "Type and select city",
    to: "to",
    and: "and",
    logoAlt: "Logo of",
  },
  yourProfile: {
    title: "Your profile",
    firstName: "First name",
    lastName: "Last name",
    functionTitle: "Function title",
    enterprise: "Enterprise name",
    address: "Address",
    apartment: "Apt, P.O. or office",
    city: "City",
    province: "Province",
    zipCode: "Postal code",
    country: "Country",
    email: "Email",
    emailConfirmation: "Email confirmation",
    phoneNumber: "Phone number",
    phoneNumber2: "Phone number (2)",
  },
  otherInformation: {
    joinFiles:
      "You can attach up to 5 documents with your application (optional).",
    maxCapacity:
      "Accepted formats: JPEG, JPG, PNG, BMP, GIF, SVG, WEBP, PDF, ODT, DOCX and DOC. Maximum file size: 10MB",
    additionalInfo:
      "Additional information to help us process your application.",
  },
  confirmation: {
    title: "Thank you. Your request has been received.",
    message:
      "Please record this number, which has been assigned to your request.",
  },
  errors: {
    requiredField: "This field is required.",
    fillFormAgain:
      "Oops! It looks like there is some missing information. Please see the error in the following form.",
    payloadTooLarge: "The file is too large.",
    invalidCity: "City must be valid.",
    validationErrorSingleField: "There is an error in the following field:",
    validationErrorMultipleFields: "There are errors in the following fields:",
    validationErrorSingleQuestion:
      "There is an error in the following question:",
    validationErrorMultipleQuestions:
      "There are errors in the following questions:",
    selectPosition: "Select the field from the suggested list.",
    chooseOption: "Please choose an option.",
  },
  filepond: {
    labelMaxFileSizeExceeded: "File is too large.",
    labelMaxFileSize: "Maximum file size is {filesize}.",
    labelMaxTotalFileSizeExceeded: "Maximum total size exceeded.",
    labelMaxTotalFileSize: "Maximum total file size is {filesize}.",
    labelFileProcessingComplete: "Upload complete",
    labelTapToCancel: "tap to cancel",
    labelTapToRetry: "tap to retry",
    labelTapToUndo: "tap to undo",
    labelFileProcessing: "Uploading",
    labelIdle:
      'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>',
    fileValidateTypeLabelExpectedTypes: "Invalid file type",
    labelFileTypeNotAllowed: "Invalid file type",
    labelFileProcessingError: "Error",
  },
  leaveWarning: {
    head: "Confirm leave",
    body: "If you leave this page, your changes will be lost.",
    cancel: "Cancel",
    cta: "Leave",
  },
};
