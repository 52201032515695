export const calcBudgetAccountValue = (account, products = []) => {
  let total = 0;

  if (
    account.financialAmount &&
    (account.contributionType === "financial" ||
      account.contributionType === "both")
  ) {
    total += Number(account.financialAmount);
  }

  if (
    account.contributionType === "product" ||
    account.contributionType === "both"
  ) {
    for (const product of products) {
      total += Number(product.value);
    }
  }

  return total;
};
