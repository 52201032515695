export default {
  title: "Gestion des questions",
  createNew: "Nouvelle question",
  chooseTemplate: "Choisir un modèle",
  createNewFromTemplate: "Nouvelle question à partir d'un modèle",
  confirmDeleteMessage: "Voulez-vous vraiment effacer cette question?",
  createQuestion: "Nouvelle question",
  editQuestion: "Édition de la question",
  templateQuestion: "Question modèle",
  typeDisabledHelp:
    "Vous ne pouvez pas changer le format, car cette question à été créée à partir d'un modèle.",
  questionlabelFrench: "Libellé de la question – français",
  questionlabelEnglish: "Libellé de la question – anglais",
  questionType: "Sélectionnez le format de la question",
  choose: "Sélectionnez une réponse",
  text: "Texte",
  textarea: "Zone de texte",
  number: "Numérique",
  multiple: "Choix multiple",
  screening: "Question de sélection",
  choices_with_categories: "Choix multiple avec catégories",
  required: "Requis",
  allowMultiple: "Plus d’une réponse possible",
  conditional: "Conditionnel",
  selectConditionalQuestion: "Sélection de la question",
  selectChoices: "Sélection du choix",
  isCurrency: "Devise",
  maxLength: "Longueur maximale",
  minValue: "Valeur minimale",
  maxValue: "Valeur maximale",
  notesFrench: "Notes additionnelles - français",
  notesEnglish: "Notes additionnelles - anglais",
  notePosition: "Emplacement des notes",
  beforeQuestion: "Avant la question",
  afterQuestion: "Après la question",
  middle: "Centre",
  choices: "Choix de réponse",
  addChoice: "Ajouter un choix ou une réponse",
  choiceLabelFrench: "Libellé de la réponse – français",
  choiceLabelEnglish: "Libellé de la réponse – anglais",
  parent: "Catégorie parente",
  chooseParent: "Choisir un parent",
  confirmChoiceDeletion: "Voulez-vous vraiment effacer ce choix?",
  createChoice: "Créer un choix ou une réponse",
  editChoice: "Éditer le choix",
  choiceSaved: "Choix enregistré",
  isEliminative: "Éliminatoire",
  points: "Points",
  reviewer: "Vérificateur",
  geo: "Géolocalisation",
  scale: "Échelle",
  url: "URL",
  lowestLabelFr: "Libellé de gauche - français",
  lowestLabelEn: "Libellé de gauche - anglais",
  highestLabelFr: "Libellé de droite - français",
  highestLabelEn: "Libellé de droite - anglais",
  preview: "Prévisualiser la question",
  chooseQuestionTemplate: "Sélectionnez un modèle de template",
  calendar: "Calendrier",
  minMonthsRequired: "Délai minimum requis (mois)",
  questionSavedSuccessfully: "La question à été sauvegardée avec succès",
  french: "Français",
  english: "Anglais",
  failedChangeOrder: "Le changement d'ordre à échoué.",
  conditionalQuestion: "Cette question est conditionnelle à la question #",
};
