export default {
  title: "Comptes",
  viewAll: "Voir tous les comptes",
  addAccount: "Ajouter un compte",
  createNewAccount: "Créer un nouveau compte",
  addExistingAccount: "Ajouter un compte existant",
  associateOtherAccount: "Associer le budget avec compte existant",
  replaceNewAccount: "Remplacer par un nouveau compte",
  addAccountBudgetTitle: "Ajouter un compte au budget",
  editAccountBudgetTitle: "Modifier le compte du budget",
  name: "Nom",
  code: "Code",
  accountName: "Nom du compte",
  accountCode: "Code du compte",
  confirmDeleteHeadText: "Supprimer",
  confirmDeleteMessagePart1:
    "Êtes-vous certain de vouloir supprimer le compte « ",
  confirmDeleteMessagePart2: " »?",
  confirmDeleteWarningText:
    "Les entrées précédentes avec ce compte seront conservées, mais le compte ne sera plus proposé pour les nouvelles demandes et les nouveaux budgets créés.",
  confirmDeleteCtaText: "Supprimer",
  confirmDeleteAccountBudget:
    "Êtes-vous certain de vouloir supprimer ce compte du budget?",
  deleteSuccess: "Le compte à été effacé avec succès",
  updateSuccess: "Le compte à été modifié avec succès",
  saveSuccess:
    "Le compte a été enregistré avec succès|Les comptes ont été enregistrés avec succès",
  unprocessable:
    "Une erreur est survenue, veuillez valider les entrées et recommencer",
  editAccountTitle: "Modifier le compte",
  codeError: "Le code ne doit pas être vide",
  nameError: "Le nom ne doit pas être vide",
  duplicateCode: "Ce code est déjà utilisé",
};
