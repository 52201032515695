import api from "@/api";

export default {
  state: {
    values: [],
    fetched: false,
    fetching: false,
  },

  mutations: {
    SET_REQUESTS_KPIS: (state, values) => {
      state.values = values;
      state.fetched = true;
    },

    INVALIDATE_KPI_DATA: (state) => {
      state.fetched = false;
      state.fetching = false;
    },

    REQUESTS_KPIS_START_FETCHING: (state) => {
      state.fetched = false;
      state.fetching = true;
    },

    REQUESTS_KPIS_FETCH_FAILED: (state) => {
      state.fetched = false;
      state.fetching = false;
    },
  },

  actions: {
    fetchRequestsKpis({ commit }, websiteId) {
      commit("REQUESTS_KPIS_START_FETCHING");
      return api
        .getRequestsKpis(websiteId)
        .then((requestsKpis) => {
          commit("SET_REQUESTS_KPIS", requestsKpis);
        })
        .catch((error) => {
          commit("REQUESTS_KPIS_FETCH_FAILED");
          throw error;
        });
    },
  },
};
