import _ from "lodash";
import api from "../../api";

export default {
  state: {
    users: [],
    usersFetched: false,
    usersLoading: false,
  },

  mutations: {
    USERS_START_FETCHING: (state) => {
      state.usersLoading = true;
    },

    USERS_FETCHED: (state, users) => {
      state.users = users;
      state.usersFetched = true;
      state.usersLoading = false;
    },

    USER_CREATED: (state, user) => {
      state.users.push(user);
    },

    USER_UPDATED: (state, user) => {
      const object = _.find(state.users, { id: user.id });

      if (object) {
        const index = state.users.indexOf(object);
        state.users.splice(index, 1, user);
      }
    },

    USER_DELETED: (state, id) => {
      const object = _.find(state.users, { id });

      if (object) {
        const index = state.users.indexOf(object);
        state.users.splice(index, 1);
      }
    },
  },

  actions: {
    fetchUsers({ commit }) {
      commit("USERS_START_FETCHING");

      return api.getUsers().then((users) => {
        commit("USERS_FETCHED", users);
      });
    },

    async createUser({ commit }, payload) {
      const user = await api.createUser(payload);
      commit("USER_CREATED", user);
      return user;
    },

    async updateUser({ commit }, payload) {
      const user = await api.updateUser(payload);
      commit("USER_UPDATED", user);
      return user;
    },

    async deleteUser({ commit }, { websiteId, userId }) {
      await api.deleteUser({ websiteId, userId });
      commit("USER_DELETED", userId);
    },
  },
};
