import { mapState, mapActions } from "vuex";

export default {
  computed: mapState("accessibility", [
    "skipToMainContent",
    "skipToProgressIndicator",
    "isNavbarRendered",
  ]),
  watch: {
    skipToMainContent(newValue) {
      let isFocusMainContent = false;
      if (this.focusMainContent) isFocusMainContent = true;

      if (newValue && isFocusMainContent) {
        this.focusMainContent();
        this.resetSkipToMainContent();
      }
    },
    skipToProgressIndicator(newValue) {
      let isFocusProgressIndicator = false;
      if (this.focusProgressIndicator) isFocusProgressIndicator = true;
      if (newValue && isFocusProgressIndicator) {
        this.focusProgressIndicator();
        this.resetSkipToProgressIndicator();
      }
    },
  },
  methods: {
    ...mapActions("accessibility", [
      "triggerSkipToMainContent",
      "resetSkipToMainContent",
      "triggerSkipToProgressIndicator",
      "resetSkipToProgressIndicator",
      "updateNavbarRendered",
    ]),
    handleSkipToMainContent() {
      this.triggerSkipToMainContent();
    },
    handleSkipToProgressIndicator() {
      this.triggerSkipToProgressIndicator();
    },
    setFocusToTopOfPage() {
      this.$nextTick(() => {
        document.getElementById("top-of-page").focus();
      });
    },
  },
};
