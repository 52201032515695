export default {
  title: "Question management",
  createNew: "Create new question",
  createNewFromTemplate: "Create new question from template",
  chooseTemplate: "Choose a template",
  confirmDeleteMessage: "Are you sure you want to delete this question?",
  createQuestion: "Create Question",
  editQuestion: "Edit Question",
  templateQuestion: "Template question",
  typeDisabledHelp:
    "This question cannot be changed because it was created from a template.",
  questionlabelFrench: "Question label (FR)",
  questionlabelEnglish: "Question label (EN)",
  questionType: "Select question format",
  choose: "Select an answer",
  text: "Text",
  textarea: "Text area",
  number: "Number",
  multiple: "Multiple Choice",
  screening: "Screening question",
  choices_with_categories: "Multiple choice with categories",
  required: "Required",
  allowMultiple: "Allow multiple choices",
  conditional: "Conditional",
  selectConditionalQuestion: "Please select the conditional question",
  selectChoices: "Select choices",
  isCurrency: "Is currency",
  maxLength: "Max length",
  minValue: "Min value",
  maxValue: "Max value",
  notesFrench: "Additional notes (FR)",
  notesEnglish: "Additional notes (EN)",
  notePosition: "Additional note position",
  beforeQuestion: "Before question",
  afterQuestion: "After question",
  middle: "Middle",
  choices: "Choices",
  addChoice: "Add choice",
  choiceLabelFrench: "Choice label (FR)",
  choiceLabelEnglish: "Choice label (EN)",
  parent: "Parent category",
  chooseParent: "Choose a parent",
  confirmChoiceDeletion: "Are you sure you want to delete this choice?",
  createChoice: "Create choice",
  editChoice: "Edit choice",
  choiceSaved: "Choice saved.",
  isEliminative: "Is eliminative",
  points: "Points",
  reviewer: "Reviewer",
  geo: "Geolocation",
  scale: "Scale",
  url: "URL",
  lowestLabelFr: "Left label - french",
  lowestLabelEn: "Left label - english",
  highestLabelFr: "Right label - french",
  highestLabelEn: "Right label - english",
  chooseQuestionTemplate: "Choose a template model",
  preview: "Preview question",
  calendar: "Calendar",
  minMonthsRequired: "Minimum delay required (months)",
  questionSavedSuccessfully: "Question saved successfully",
  french: "French",
  english: "English",
  failedChangeOrder: "Failed to change the order.",
  conditionalQuestion: "This question is conditional to question #",
};
