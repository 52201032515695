<template>
  <div
    class="client-app comp-index-public"
    ref="topOfPage"
    id="top-of-page"
    tabindex="-1"
  >
    <div class="async-content" v-if="!stylesLoading && websiteId">
      <a
        href="#main-content"
        class="sr-only sr-only-focusable"
        @click.prevent="handleSkipToMainContent"
        @keydown.enter.prevent="handleSkipToMainContent"
      >
        {{ $t("admin.accessibility.skipToMainContent") }}
      </a>
      <a
        v-if="isNavbarRendered"
        href="#"
        class="sr-only sr-only-focusable"
        @click.prevent="handleSkipToProgressIndicator"
        @keydown.enter.prevent="handleSkipToProgressIndicator"
      >
        {{ $t("admin.accessibility.skipToProgressIndicator") }}
      </a>
      <Banner v-if="this.styles.banner_position === 'top'" />

      <header-nav></header-nav>

      <div class="page-content">
        <div class="container">
          <router-view></router-view>
        </div>
      </div>

      <public-footer></public-footer>
    </div>
    <modal></modal>
  </div>
</template>

<script>
import publicSettings from "@/mixins/public/settings";
import HeaderNav from "@/views/public/components/HeaderNav";
import PublicFooter from "@/views/public/components/PublicFooter";
import metaStyles from "@/mixins/meta-styles";
import publicSite from "@/mixins/public-site";
import Banner from "@/views/public/components/Banner";
import useAccessibility from "@/mixins/use-accessibility";
import Modal from "@/vendor/vue-modal-box/components/ModalContainer.vue";

export default {
  components: {
    Modal,
    HeaderNav,
    PublicFooter,
    Banner,
  },
  mixins: [metaStyles, publicSite, publicSettings, useAccessibility],
  methods: {
    async fetchData() {
      const { id } = await this.$store.dispatch("publicSite/fetchSite", {
        subdomain: this.getSubdomain(),
        hostname: window.location.hostname,
      });

      this.setAppSettings();

      if (id) {
        await this.$store.dispatch("publicSite/getPublicStyles", id);
        const favicon = document.getElementById("favicon");
        if (this.styles.favicon_image) {
          favicon.href = this.styles.favicon_image_url;
        }
      } else {
        // No public site found, redirect to admin app
        this.$router.push("/admin").catch(() => {
          // ...
        });
      }
    },
    getSubdomain() {
      const hostname = window.location.hostname;
      const segments = hostname.split(".");
      return segments[0];
    },
  },
  created() {
    this.fetchData();

    if (this.$route.query && this.$route.query.lang) {
      let lang = this.$route.query.lang;

      if (!["en", "fr"].includes(lang)) {
        lang = "en";
      }

      localStorage.setItem("user_lang", lang);
      this.$i18n.locale = lang;
    }
  },
};
</script>

<style lang="scss" scoped>
.client-app:deep(.async-content) {
  @import "styles/public.scss";
}

.page-content {
  background: white;
  min-height: 300px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.mw-container {
  min-width: 1000px;
}
</style>
