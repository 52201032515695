export default {
  menu: {
    profile: "COORDONNÉES",
    project: "Questions",
    additional: "Autres informations",
    confirmation: "Confirmation",
  },
  footer: {
    poweredBy: "Propulsé par",
    terms: "Conditions d’utilisation",
    privacy: "Confidentialité",
  },
  common: {
    french: "Français",
    english: "English",
    title: "Demande de commandite",
    next: "Suivant",
    nextStep: "Étape suivante",
    prevStep: "Étape précédente",
    sendRequest: "Envoyer cette demande",
    maxChars: "{maxLength} caractères.",
    value: "Valeur",
    browse: "Parcourir...",
    backToLogin: "Retour à la page de connexion",
    enterDate: "Entrez la date",
    enterPosition: "Saisir et sélectionner l'adresse",
    enterCity: "Saisir et sélectionner la ville",
    to: "au",
    and: "et",
    logoAlt: "Logo de",
  },
  yourProfile: {
    title: "Coordonnées",
    firstName: "Prénom",
    lastName: "Nom",
    functionTitle: "Titre de votre fonction",
    enterprise: "Nom de votre entreprise",
    address: "Adresse",
    apartment: "No App., C.P. ou bureau",
    city: "Ville",
    province: "Province",
    zipCode: "Code postal",
    country: "Pays",
    email: "Courriel",
    emailConfirmation: "Confirmation du courriel",
    phoneNumber: "Numéro de téléphone",
    phoneNumber2: "Numéro de téléphone (2)",
  },
  otherInformation: {
    joinFiles:
      "Vous pouvez joindre jusqu'à 5 documents à votre demande (optionnel)",
    maxCapacity:
      "Formats acceptés: JPEG, JPG, PNG, BMP, GIF, SVG, WEBP, PDF, ODT, DOCX et DOC. (10 mo max.)",
    additionalInfo:
      "Renseignements additionnels pour nous aider à traiter votre dossier",
  },
  confirmation: {
    title: "Merci, votre demande a bien été envoyée.",
    message: "Prenez en note ce numéro de dossier associé à votre demande :",
  },
  errors: {
    requiredField: "Ce champ est obligatoire.",
    fillFormAgain:
      "Oups ! Il semble qu'il manque des informations. Veuillez voir l'erreur dans le formulaire suivant.",
    payloadTooLarge: "Le fichier est trop volumineux.",
    invalidCity: "La ville doit être valide.",
    validationErrorSingleField: "Il y a une erreur dans le champ suivant:",
    validationErrorMultipleFields:
      "Il y a des erreurs dans les champs suivants:",
    validationErrorSingleQuestion:
      "Il y a une erreur dans la question suivante:",
    validationErrorMultipleQuestions:
      "Il y a des erreurs dans les questions suivantes:",
    selectPosition: "Veuillez sélectionner une adresse dans la liste suggérée.",
    chooseOption: "Veuillez choisir une option.",
  },
  filepond: {
    labelMaxFileSizeExceeded: "Le fichier est trop volumineux.",
    labelMaxFileSize: "La taille maximum est {filesize}.",
    labelMaxTotalFileSizeExceeded: "Taille totale maximale dépassée.",
    labelMaxTotalFileSize: "La taille totale maximale est de {filesize}.",
    labelFileProcessingComplete: "Téléversement terminé",
    labelTapToCancel: "cliquer pour annuler",
    labelTapToRetry: "cliquer pour réessayer",
    labelTapToUndo: "cliquer pour supprimer",
    labelFileProcessing: "Téléversement...",
    labelIdle: "Parcourir ou déposer vos fichiers",
    fileValidateTypeLabelExpectedTypes: "Type de fichier invalide",
    labelFileTypeNotAllowed: "Type de fichier invalide",
    labelFileProcessingError: "Erreur",
  },
  leaveWarning: {
    head: "Confirmer la sortie",
    body: "Si vous quittez cette page, vos modifications seront perdues.",
    cancel: "Annuler",
    cta: "Quitter",
  },
};
