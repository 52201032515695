import api from "../../api";

export default {
  state: {},

  mutations: {},

  actions: {
    createAccountBudget(context, accountBudget) {
      return api.accountBudgets.create(accountBudget);
    },
  },
};
