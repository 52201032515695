export default {
  actualVsBudget: "Réel VS Budget",
  list: "Liste",
  graph: "Graphique",
  code: "Code",
  account: "Compte",
  attributed: "Montant attribué",
  budgeted: "Budgété",
  left: "Restant",
  percentOfBudget: "% du budget",
  remaining: "Restant",
  budget: "Budget",
  actual: "Réel",
  total: "Total",
  comparative: "Comparatif",
  from: "De",
  to: "À",
  show: "Afficher",
  attributedColumnLabel: "Attribué\n{budgetName}",
  budgetedColumnLabel: "Budgété\n{budgetName}",
  financialYear: "Année financière",
  backToCategories: "Retour aux catégories",
};
