import userData from "@/mixins/user-data";
import { mapState } from "vuex";

export default {
  mixins: [userData],
  data() {
    return {
      licenseEnum: {
        STANDARD: "standard",
        MULTIUSER: "multiuser",
        VALUATION: "valuation",
        PUBLIX: "publix",
        LIGHT: "light",
      },
      featureEnum: {
        DASHBOARD: "dashboard",
        QUESTIONS: "questions",
        SUBMISSIONS: "submissions",
        EMAIL_CONTENTS: "email_contents",
        PROFILE_FIELDS: "profile_fields",
        PAGES: "pages",
        STYLES: "styles",
        SETTINGS: "settings",
        USERS: "users",
        SUBMISSION_APPROVER: "submission_approver",
        ROUTING_QUESTION: "routing_question",
        PROPERTY_EVALUATION: "property_evaluation",
        PUBLIX_PROPERTY_FORM: "publix_property_form",
        SHARE_PROPERTY: "share_property",
        REGION_ASSIGNMENT: "region_assignment",
        BUDGETS: "budgets",
        ACTIVITY_SUMMARY: "activity_summary",
        ADVANCED_DASHBOARD: "advanced_dashboard",
      },
    };
  },
  computed: {
    ...mapState({
      currentLicense: (state) => state.licenses.currentLicense.value,
      licenses: (state) => state.licenses.licenses.values,
      currentLicenseFetched: (state) => state.licenses.currentLicense.fetched,
      features: (state) => state.features.features,
    }),
  },
  methods: {
    hasLicense(license) {
      if (!this.currentLicenseFetched) {
        return false;
      }
      return this.currentLicense.name === license;
    },
    licenseType(id) {
      const keys = Object.keys(this.licenses);
      for (const key of keys) {
        if (this.licenses[key].id === id) {
          return key;
        }
      }
      return null;
    },
    hasLicenseFeature(feature) {
      if (!this.currentLicenseFetched) {
        return false;
      }
      return this.currentLicense.features.some(
        (licenseFeature) => licenseFeature.name === feature,
      );
    },
  },
};
