export default {
  title: "Demandes",
  showTitle: "Demande",
  referenceNumber: "Numéro de référence",
  exportCSV: "Exporter au format CSV",
  addRequest: "Ajouter une demande",
  status: "Statut",
  allStatus: "Tous",
  pending: "En attente",
  rejected: "Rejetée",
  accepted: "Acceptée",
  evaluation: "Évaluation",
  negotiation: "En négociation",
  reviewing: "En révision",
  setStatusTo: "Changer le statut à...",
  search: "Sélectionnez les critères de recherche",
  inAllFields: "Tous les champs",
  inOrganization: "Organisation",
  inReference: "Numéro de référence",
  inEmail: "Courriel",
  inAddress: "Adresse",
  hideFilters: "Masquer les filtres",
  showFilters: "Recherche avancée",
  clearFilters: "Effacer les filtres",
  groups: "Groupes",
  period: "Date de réception",
  sinceInception: "Depuis le début",
  thisYear: "Année en cours",
  last6months: "6 derniers mois",
  last3months: "3 derniers mois",
  lastMonths: "Dernier mois",
  customPeriod: "Période personnalisée",
  country: "Pays",
  allCountry: "Tout pays",
  state: "État / Province",
  budgetYear: "Année budgétaire",
  optionSelected: "option(s) sélectionnée(s)",
  owner: "Assignation",
  location: "Ville",
  amount: "Montant",
  sizeAudience: "Taille de l'audience",
  typeSponsorship: "Type de commandite",
  category: "Catégorie",
  typeOrganization: "Type d'organisation",
  donation: "Don",
  sponsorship: "Commandite",
  personalEndorsement: "Projet individuel",
  influencer: "Influenceur-euse/Ambassadeur-rice/Créateur-rice de contenu",
  team: "Equipe",
  league: "Ligue",
  venue: "Lieu/Site/Installation",
  media: "Média",
  productPlacement: "Placement de produit",
  organization: "Organisation",
  other: "Autre",
  dateEvent: "Date de l'évènement",
  periodStart: "Début de période",
  periodEnd: "Fin de période",
  columns: "Colonnes",
  refresh: "Actualiser",
  reference: "Numéro de référence",
  company: "Organisation",
  date: "Date",
  score: "Score",
  eventName: "Nom de l'évènement",
  attributedBudget: "Montant accordé",
  action: "Action",
  confirmDeleteMessage: "Voulez-vous vraiment effacer cette demande?",
  comments: "Commentaires",
  noComments: "Aucun commentaire...",
  addComment: "Ajouter un commentaire",
  commentSingular: "commentaire",
  commentPlural: "commentaires",
  history: "Historique",
  event: "Événement",
  by: "Par",
  viewMessage: "Voir message",
  viewMore: "Voir plus",
  submission: "Soumission",
  referenceLabel: "Numéro de référence:",
  assigneeLabel: "Assignée à:",
  groupLabel: "Groupes:",
  statusLabel: "Statut:",
  scoreLabel: "Score:",
  dateLabel: "Date:",
  langLabel: "Langue:",
  noteLabel: "Note:",
  none: "Aucun",
  profileFields: "Profil",
  questions: "Questions",
  files: "Fichier(s) ajouté(s) à la demande",
  adminFiles: "Fichiers",
  uploadFiles: "Joindre des fichiers",
  confirmationDeleteFiles: "Voulez-vous supprimer le fichier?",
  fileDeleted: "Fichier supprimé avec succès",
  successAttachFile: "Fichier(s) ajouté(s) avec succès",
  noFiles: "Aucun fichier",
  postNewComment: "Ajouter un commentaire",
  commentSuccessfullyAdded: "Commentaire ajouté avec succès",
  comment: "Commentaire",
  accept: "Accepter la demande",
  reject: "Refuser la demande",
  revertToPending: "Remettre en attente",
  openCommunication: "Communication",
  confirmCommentDeletion: "Voulez-vous effacer le commentaire?",
  attributeAmount: "Attribution du montant",
  attributedAmount: "Montant attribué",
  attributedAmountsSaved: "Montants attribués sauvegardés",
  noSponsorshipBudget: "Aucune information budgétaire",
  numberOfYears: "Nombre d’années de l’entente",
  startDate: "Date de début",
  endDate: "Date de fin",
  updateBudget: "Mise à jour",
  budgetYearLabel: "Année {year}",
  account: "Compte",
  financialContribution: "Contribution financière",
  productContribution: "Contribution en produits et services",
  total: "Total",
  subtotal: "Sous-total",
  addAccount: "Ajouter un compte",
  contributionType: "Type de contribution",
  financial: "Financière",
  inProductsAndServices: "En produits et services",
  both: "Mixte",
  productsAndServices: "Produits et services",
  addItems: "Ajouter un item",
  valuePlaceholder: "Valeur ($)",
  createNewProduct: "Créer un nouveau produit",
  editProductList: "Liste de produits et services",
  editProductListLink: "Éditer la liste",
  productName: "Nom du produit",
  assignRequest: "Assigner un responsable",
  editField: "Editer le champ",
  user: "Utilisateur",
  changeGroup: "Changer le groupe",
  changeAssignee: "Changer l'assignation'",
  message: "Message",
  to: "À:",
  from: "De:",
  subject: "Objet:",
  messageContent: "Contenu:",
  sendMessage: "Envoyer:",
  sendMessageTooltip:
    'You can change default email notification settings in the section "Communications / Email contents"',
  asScheduled: "Comme prévu",
  afterDelay: "Après un délai de",
  hours: "heure(s)",
  immediately: "Immédiatement",
  dontSendEmail: "Ne pas envoyer de message",
  willBeSentOn: "Sera envoyé le {dateTime}",
  sentOn: "Envoyé le {dateTime}",
  confirmEmailDeletion: "Voulez-vous effacer ce courriel?",
  totalAttributed: "Total attribué :",
  totalFinancial: "Contribution financière :",
  totalProducts: "Biens et services :",
  numberOfRequests: "Nombre de demandes :",
  assignees: "Assignée à",
  assigneeChanged: "Demande assignée avec succès",
  assignWarning: "Attention:",
  assignWarningMessage: "vous risquez de perdre l'accès à cette page",
  assigneeRemoveSuccess: "personne n'est désormais assigné à cette demande",
  confirmDeleteAssignee:
    " Retirer votre utilisateur de l’assignation va retirer votre accès à cette requête. Voulez-vous continuer ?",
  groupRemoveSuccess: "Aucun groupe n'est désormais assigné à cette demande",
  groupAssigned: "Groupe assigné avec succès",
  groupModified: "Groupe modifié avec succès",
  assigneeModified: "Assignation modifiée avec succès",
  eliminative: "Éliminatoire",
  points: "points",
  goback: "Retour aux demandes",
  videoUrl: "URL de la vidéo",
  deleteField: "Supprimer le champ",
  deleteFieldConfirmation: "Désirez-vous vraiment supprimer la réponse?",
  downloadFile: "Télécharger le fichier",
  downloadPdf: "Télécharger PDF",
  downloadCSV: "Télécharger CSV",
  fileWillBeSentByEmail:
    "Le lien pour télécharger le fichier vous sera envoyé par courriel.",
  statusUpdatedSuccessfully: "Statut mis à jour avec succès!",
  statusUpdateFailed: "Échec de la mise à jour du statut",
  addRequestPage: {
    language: "Langue",
    french: "Français",
    english: "Anglais",
    profile: "Profil",
    questions: "Questionnaire",
    additionalInfo: "Renseignements additionnels",
  },
  months: "mois",
  deleted: "supprimé",
  missingBudgets:
    "Un des budgets n'a pas été configuré pour une des années choisies. Allez à la {budgets} pour le configurer.",
  revertToPendingWarning:
    "Si vous remettez cette demande en attente, les informations financières de l'entente seront supprimées.",
  property: {
    steps: "Étapes",
    howTo: "Comment compléter une évaluation de propriété?",
    toQuestionnaire: "Aller au questonnaire",
    questionnaireNotBeenShared:
      "Le questionnaire n'a pas été partagé avec l'applicant.",
    questionnaireNotBeingShared:
      "Le questionnaire n'est pas partagé avec l'applicant.",
    questionnaireShared: "Questionnaire partagé avec ",
    questionnaireVisitedOn: "Questionnaire visité le ",
    questionnaireUpdatedOn: "Questionnaire mis à jour le ",
    questionnaireCompletedOn: "Questionnaire complété le ",
    questionnaireExpiresAt: "Le lien pour compléter le questionnaire expirera ",
    questionnaireExpiredAt: "Le lien pour remplir le questionnaire a expiré ",
    pushBackExpirationDate:
      "Cliquez sur 'Partager questionnaire' pour repousser sa date d'expiration",
    fillQuestionnaire: "Remplir le questionnaire",
    reviewAndComplete: "Réviser et compléter le questionnaire",
    generateScoreCard: "Générer le pointage",
    downloadSPF: "Télécharger SPF",
    timeAllowed:
      "L'applicant possède {delay} pour remplir et renvoyer le questionnaire. Un rappel est envoyé automatiquement après 48 heures et à tous les 7 jours.",
    thirtyDays: "30 jours",
    threeMonths: "3 mois",
    open: "Ouvrir le questionnaire",
    sendReminder: "Envoyer un rappel",
    shareQuestionnaire: "Partager le questionnaire",
    stopSharing: "Annuler le partage du questionnaire",
    questionnaireSharedNotif: "Le questionnaire est maintenant accessible",
    questionnaireNotSharedNotif: "Le questionnaire n'est plus accessible",
    questionnaireShareErrorNotif:
      "Une erreur est survenue lors du partage du questionnaire",
    questionnaireExpired:
      "Le questionnaire a expiré et il n'est plus accessible.",
    questionnaireLifetimeAdded:
      "30 jours ont été ajouté à la durée de vie du questionnaire",
    questionnaireLifetimeError:
      "Une erreur est survenue en allongeant la durée de vie du questionnaire",
    cannotShareWhileRejected:
      "Le questionnaire ne peut être partagé lorsque la demande est rejetée",
    invitationSent: "Invitation envoyée",
    invitationError: "Une erreur est survenue lors de l'envoi de l'invitation",
    sharingPropertyError:
      "Une erreur est survenue lors du partage de la propriété",
    reminderSent: "Rappel envoyé",
    reminderError: "Une erreur est survenue lors de l'envoi du rappel",
    scorecard: "Carte de pointage",
    brandProfileRequired:
      "Un profil doit être sélectionné dans les Réglages Généraux afin de pouvoir générer le pointage",
    propertyCompleteRequired:
      "Complétez le questionnaire pour pouvoir générer le pointage",
    questionnaireStillShared:
      "Voulez-vous garder ouvert l'accès au questionnaire ?",
    questionnaireStillSharedWarning:
      "Tout personne possédant le lien peut accéder au questionnaire et l'éditer.",
    turnOffAndSave: "Fermer l'accès",
    keepOnAndSave: "Garder l’accès ouvert",
  },
  tabs: {
    applicationInfo: "Application",
    propertyEvaluation: "Évaluation",
    agreementInfo: "Entente",
  },
  updateStatus: "Changer le statut",
};
