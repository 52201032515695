<template>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">
            {{ $t("admin.auth.changePassword") }}
          </h4>
        </div>
        <div class="modal-body">
          <p>{{ $t("admin.auth.forceChangePasswordModalBody") }}</p>
          <p>
            {{ $t("admin.auth.forceChangePasswordModalBody2") }}
          </p>
          <form>
            <form-group name="password" :errors="errors">
              <label for="password">{{ $t("admin.auth.newPassword") }}</label>
              <input
                type="password"
                class="form-control"
                id="password"
                v-model="form.password"
              />
            </form-group>

            <form-group name="conf_password" :errors="errors">
              <label for="conf_password">{{
                $t("admin.auth.confirmPassword")
              }}</label>
              <input
                type="password"
                class="form-control"
                id="conf_password"
                v-model="form.conf_password"
              />
            </form-group>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-success mr-2"
            @click="submit"
            :disabled="formDisabled || isProcessing"
          >
            {{ $t("admin.common.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import FormGroup from "@/components/common/FormGroup";

export default {
  name: "change-password",
  components: {
    FormGroup,
  },
  data() {
    return {
      isProcessing: false,
      form: {
        password: "",
        conf_password: "",
      },
      hasError: false,
      errors: {},
      errorType: null,
    };
  },
  computed: {
    formDisabled() {
      return (
        this.form.password.length === 0 || this.form.conf_password.length === 0
      );
    },
  },
  methods: {
    submit() {
      this.isProcessing = true;
      this.hasError = false;

      const payload = {
        form: this.form,
        lang: this.$i18n.locale,
      };

      this.$store
        .dispatch("changePassword", payload)
        .then(() => {
          this.isProcessing = false;
          this.errors = {};
          this.done(true);
        })
        .catch((error) => {
          this.isProcessing = false;
          this.errorType =
            error.type === "bad_request" ? "validation" : "internal";
          this.errors =
            this.errorType === "validation"
              ? _.get(error, "response.data.error")
              : {};
        });
    },
    cancel() {
      this.$router.push("/admin");
    },
  },
};
</script>
