export default {
  title: "Réglages",
  saveSuccessMessage: "Réglages mis à jour",
  applicationLang: "Paramètres de langue du site",
  applicationLangFr: "Français",
  applicationLangEn: "Anglais",
  score: "Score",
  twoFactorAuth: "Authentification à deux facteurs",
  enableTwoFactor: "Activer l'authentification à deux facteurs",
  minScore: "Score minimal",
  maxScore: "Score maximal",
  defaultReviewer: "Approbateur par défaut",
  user: "Utilisateur",
  activitySummary: "Sommaire d’activité",
  frequency: "Fréquence",
  weekly: "Hebdomadaire",
  monthly: "Mensuelle",
  never: "Jamais (aucun sommaire ne sera envoyé)",
  emailSettings: "Configuration du courriel",
  senderName: "Nom de l’expéditeur",
  senderAddress: "Courriel de l’expéditeur",
  smtpSettings: "Configuration du protocole SMTP",
  testMessage: "Envoyer un message de test",
  serverAddress: "Adresse du serveur",
  port: "Port",
  encryption: "Encryption",
  username: "Nom d’utilisateur",
  password: "Mot de passe",
  fiscalYear: "Exercice financier de l’entreprise",
  fiscalYearShort: "Année fiscale",
  brandProfile: "Profil (évaluation de propriété)",
  region: "Région",
  regionAssignment: "Attribution par région",
  confirmDeleteAssignmentByRegionMessage:
    "Êtes-vous sûr de vouloir supprimer cette attribution par région ?",
};
