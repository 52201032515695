<template>
  <a class="lang text-uppercase" @click.prevent="changeLang(otherLang)">{{
    otherLang
  }}</a>
</template>

<script>
export default {
  computed: {
    currentLang() {
      return this.$i18n.locale;
    },
    otherLang() {
      return this.currentLang === "fr" ? "en" : "fr";
    },
  },
  methods: {
    changeLang(lang) {
      localStorage.setItem("user_lang", lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>
