export default {
  title: "Propriétés",
  create: "Créer une propriété",
  propertyOwnerQuestions: "Questions pour l'applicant",
  adminQuestions: "Questions pour l'administrateur",
  sendLink: "Envoyer le lien",
  copyLink: "Copier le lien",
  learnMore: "En apprendre plus",
  thankYou: "Merci d'avoir complété le questionnaire",
  noLongerAvailable: "Le questionnaire n'est pas disponible",
  table: {
    noContent: "Aucune propriété",
    name: "Nom de la propriété",
    created: "Date de création",
    city: "Ville",
    type: "Type",
    startDate: "Date de début",
    askedPrice: "Montant demandé ($)",
    value: "Valeur ($)",
    status: "Statut",
    actions: "Actions",
  },
  form: {
    steps: {
      step1: "Général",
      step2: "Cible",
      step3: "Pertinence",
      step4: "Profil propriété",
      step5: "Médias détenus",
      step6: "Médias payés",
      step7: "Hospitalité",
      step8: "Admin",
      step9: "Indicateurs",
      step10: "Activation",
    },
    general: {
      publixQuestions: {
        questionPropertyName: "1. Donnez un nom à la propriété.",
        questionCity:
          "2. Please indicate where your event or project will take place.",
        questionCategory:
          "3. Quelle est la ville principale (ou marché) où se trouve la propriété?",
        questionLevel:
          "4. Quel niveau de commandite est offert par la propriété?",
        questionBenefits:
          "5. Veuillez sélectionner tous les énoncés qui s'appliquent dans le cas de la présente demande de commandite.",
        questionSocialAudienceHandle:
          "6. Indiquez le nom de chacune des plateformes numériques de la propriété (compte)",
      },
      tooManyRequests:
        "Il nous est impossible de traiter cette requête pour le moment. Veuillez réessayer dans une minute.",
      cannotRemoveDigitalAudience: "Erreur lors de la suppression",
      title: "Étape 1 : Général",
      jumbotronTtile:
        "Entrez uniquement l’information relative à cette propriété. Assurez-vous d’avoir en main les informations suivantes :",
      jumbotronList1: "L’ensemble des données sur la propriété et son audience",
      jumbotronList2:
        "Le détail de vos actions de communication : plan média de la propriété et les bénéfices offerts au commanditaire",
      jumbotronList3: "Les données de recherche, si disponible",
      questionPropertyName: "1. Donnez un nom à la propriété.",
      questionTimeFrame:
        "2. Indiquez la période de temps durant laquelle la propriété est dans le marché.",
      beginning: "Début",
      end: "Fin",
      questionCity:
        "3. Quelle est la ville principale (ou marché) où se trouve la propriété?",
      questionSponsorType: "4. Quel est le type de commandite de la propriété?",
      team: "Équipe",
      league: "Ligue",
      venue: "Lieu (stade, piste de course, etc.)",
      event: "Événement",
      organization: "Organisation",
      questionCategory:
        "5. Veuillez choisir la catégorie représentant le mieux la propriété.",
      questionLevel:
        "6. Quel niveau de commandite est offert par la propriété?",
      questionExclusivity:
        "7. Quelle sorte d’exclusivité vous est offerte dans la présente demande?",
      questionBenefits:
        "8. Veuillez sélectionner tous les énoncés qui s'appliquent dans le cas de la présente demande de commandite.",
      questionSocialAudience:
        "Indiquez l'audience pour chacune des plateformes numériques de la propriété auxquelles la marque aura accès :",
      questionSocialAudienceHandle:
        "9. Indiquez le nom de chacune des plateformes numériques de la propriété (compte)",
      facebook: "Facebook",
      tiktok: "TikTok",
      twitter: "X (Twitter)",
      snapchat: "Snapchat",
      youtube: "Youtube",
      linkedin: "LinkedIn",
      apps: "Application mobile (nombre de téléchargements)",
      instagram: "Instagram",
      website: "Site web (trafic annuel)",
      other: "Autre (préciser)",
      handleNotFound: "Le nom d'utilisateur est erroné ou invalide",
      questionCampaignDuration:
        "10. Quelle est la durée de la campagne de communication média de la propriété?",
      questionCampaignDurationTooltip:
        "Une campagne de communication est normalement composée de placements média achetés ou offerts gracieusement par un partenaire sur une ou plusieurs plateformes (télé, radio, imprimé, web, etc.).",
      questionMarketSize:
        "11. Inscrivez le nombre d’organisations ou d'événements similaires à la propriété dans le marché visé.",
      questionMarketSizeTooltip:
        "Similaire : Le nombre d’événements s'apparentant à celui de la propriété dans un même marché. Par exemple, une équipe de basketball et une équipe de baseball dans une même ville sont deux événements similaires. Il en va de même pour les festivals de musique.\n Marché : Le marché peut varier selon le type d’événement. Par exemple, une course automobile de calibre international sera dans le même marché qu’une autre course de ce même calibre, même si elle se situe dans une autre ville (à condition que ces courses constituent des manches du même championnat).",
      questionYears:
        "12. Depuis combien de temps la propriété est-elle en activité ?",
      outOfCoverageZone:
        "Le marché que vous avez sélectionné n'est actuellement pas pris en charge. Veuillez contacter l'administrateur système pour plus d'informations.",
      exclusivities: {
        category:
          "Exclusivité de catégorie (ex. : services bancaires, télécommunications, transport aérien, bière, etc.)",
        area: "Exclusivité d'un lieu ou d’une zone",
        communication:
          "Exclusivité dans les canaux de communication, dans le cadre d'un plan média",
        none: "Aucune exclusivité",
      },
      benefits: {
        allowSponsor: "a) La propriété permet au commanditaire :",
        distributeSamples: "De distribuer des échantillons sur place",
        promoteProducts: "De promouvoir ses produits et services sur place",
        dataAccess:
          "D'avoir accès à des données sur son public dans un but promotionnel",
        organizeEvents:
          "D'organiser des événements de réseautage ou des événements d’affaires",
        useEmployees: "De mettre ses employés à contribution comme bénévoles",
        useContent: "De créer et d'utiliser du contenu (photo, vidéo, etc.)",
        consumerInteraction:
          "Des interactions individuelles avec les consommateurs",
        useSponsorsProducts:
          "La propriété utilisera les produits et services du commanditaire",
        promoPlanType:
          "Connaissez-vous la valeur monétaire de votre plan média (campagne publicitaire) ?",
      },
      maximumCharacters: "Caractères maximum",
    },
    targets: {
      publixQuestions: {
        questionAwareness:
          "7. Indiquez le niveau de notoriété de la propriété auprès du grand public.",
        questionDirectAudience:
          "8. Quelle est l'audience directe et cumulative de la propriété? (Dans le cas d’un événement, indiquez l'assistance.)",
        questionAudited:
          "9. Est-ce que l'audience présente sur le site a été vérifiée par une firme utilisant une méthode reconnue ou existe-t-il des mécanismes en place permettant de la vérifier avec précision (exemple : des données de billetterie)?",
        questionSegments:
          "10. Attribuez une valeur en pourcentage à chaque catégorie suivante pour l'audience de la propriété. Le total doit être de 100 %.",
      },
      title: "Étape 2 : Cible",
      questionAwareness:
        "13. Indiquez le niveau de notoriété de la propriété auprès du grand public.",
      questionPropertyScope: "14. Quelle est l'envergure de la propriété?",
      questionAwarenessTooltip:
        "Jusqu’où, géographiquement, la propriété est-elle reconnue par une majorité de gens. Par exemple, un événement local peut être reconnu par la majorité dans sa région, mais par une petite portion de la population dans les autres régions. La réponse dans ce cas serait « régional », même si des gens connaissent l’événement en dehors de ces frontières.",
      questionAreas:
        "15. Veuillez évaluer la percée de la propriété dans chacun des secteurs géographiques suivants:",
      questionDirectAudience:
        "16. Quelle est l'audience directe et cumulative de la propriété? (Dans le cas d’un événement, indiquez l'assistance.)",
      questionAudited:
        "17. Est-ce que l'audience présente sur le site a été vérifiée par une firme utilisant une méthode reconnue ou existe-t-il des mécanismes en place permettant de la vérifier avec précision (exemple : des données de billetterie)?",
      questionAuditedTooltip:
        "Des firmes se spécialisent dans la mesure du public sur les sites d’événements. Si vous disposez de données vérifiées provenant de telles firmes, veuillez cocher « oui ». Dans le cas contraire, cochez « non ».",
      questionFirstVisitors:
        "18. Quel est, approximativement, le nombre de nouveaux visiteurs par édition?",
      questionVisitsPerPerson:
        "19. Quel est le nombre moyen de visites par personne lors d’une même édition?",
      questionVisitsPerPersonTooltip:
        "Inscrivez le nombre de visites effectuées par personne lors d’une même édition. Inscrivez un nombre entier ou décimal. Par exemple, la moyenne des visites pour l’ensemble du public est de 1,2.",
      questionSegments:
        "20. Attribuez une valeur en pourcentage à chaque catégorie suivante pour l'audience de la propriété. Le total doit être de 100 %.",
      questionSegmentsTooltip:
        "Si les informations ne sont pas disponibles, vous pouvez faire une estimation ou laisser les cases vides. Toutefois, laisser les cases vides affectera le calcul de la valeur.\n Les données sur le public peuvent être obtenues par le biais d’une firme de recherche spécialisée ou grâce aux données internes. Il est possible d’évaluer le profil en se basant sur un échantillon de la clientèle.",
      audienceOrigin: "Secteur géographique du public direct",
      questionEthnicity: "Quelle est votre ethnie ?",
    },
    brand: {
      publixQuestions: {
        questionSponsorNumber:
          "11. Indiquez le nombre de commanditaires de la propriété dans les catégories suivantes :",
      },
      title: "Étape 3 : Pertinence",
      questionCharacteristics:
        "21. Quelles caractéristiques sont propres à la marque de la propriété?",
      questionUsage:
        "22. Quelle est la probabilité que les produits/services de votre entreprise soient utilisés par les participants de la propriété ?",
      questionUsageTooltip:
        "Lors d’un événement ou d’un match, est-il possible que les participants consomment vos produits sur place?",
      questionConsuming:
        "23. Si l'événement était diffusé à la télévision, quelle serait la probabilité que les téléspectateurs consomment vos produits/services en le regardant?",
      questionConsumingTooltip:
        "En imaginant que l’événement soit télédiffusé, quelle serait la probabilité que vos produits soient consommés (par exemple, de la bière)?",
      questionAudienceClients:
        "24. Quelle est la probabilité que les consommateurs de vos produits/services fassent partie du public de l’événement?",
      questionAudienceClientsTooltip:
        "Est-ce que votre clientèle est identique au public de l’événement ou très différente?",
      questionAudienceEngagement:
        "25. Selon vous, quelle importance les fans d'une propriété ou les spectateurs d'un événement donné accordent-ils à la catégorie en général? (P. ex. jusqu'à quel point les partisans des Canadiens sont-ils des fans de hockey? Dans quelle mesure les spectateurs du festival de Saint-Tite s'intéressent-ils aux autres événements westerns?)",
      questionSponsorNumber:
        "26. Indiquez le nombre de commanditaires de la propriété dans les catégories suivantes :",
      questionNewSponsors:
        "27. Combien de nouveaux commanditaires se sont associés à la propriété au cours de la dernière année?",
      questionEventVisibillity:
        "28. Choisissez l'énoncé qui décrit le mieux votre visibilité sur le site dans le cas d'un événement.",
      localRegionalBrands: "Marques locales ou régionales",
      nationalBrands: "Marques nationales",
      internationalBrands: "Marques internationales",
      serviceMediaPartners: "Nombre de fournisseurs ou partenaires médias",
      visibility: {
        shared:
          "Votre marque est visible seulement en présence des autres entreprises partenaires",
        dedicated:
          "Votre marque possède une zone qui lui est propre et un espace pour une publicité distincte",
      },
      products: "Produits et services",
      competition: "Compétition",
    },
    management: {
      publixQuestions: {
        questionAccountManager:
          "12. La propriété a-t-elle désigné ou désignera-t-elle un gestionnaire de compte capable de répondre aux questions et aux besoins spécifiques de la commandite?",
        questionInformationSources:
          "13. Dans la liste ci-dessous, choisissez les types d'information qui vous sont fournis par la propriété.",
        questionRightsFee:
          "14. Quel est le montant des droits de la propriété pour cette commandite ?",
      },
      title: "Étape 4 : Profil propriété",
      questionAccountManager:
        "29. La propriété a-t-elle désigné ou désignera-t-elle un gestionnaire de compte capable de répondre aux questions et aux besoins spécifiques de la commandite?",
      questionSatisfaction:
        "30. Indiquez votre degré de satisfaction générale envers l’équipe de la propriété.",
      questionRateProperty:
        "31. Évaluez votre perception à l'égard de la propriété. Par perception, nous entendons le sentiment ou l'avis positif ou négatif que vous avez envers la propriété.",
      questionDescribeProperty:
        "32. Dites dans quelle mesure les énoncés suivants se rapportent à la propriété.",
      questionInformationSources:
        "33. Dans la liste ci-dessous, choisissez les types d'information qui vous sont fournis par la propriété.",
      questionDevPlan:
        "34. La propriété est-elle dotée d'un plan de développement durable?",
      questionMarketImportance:
        "35. Quelle importance votre organisation accorde-t-elle au marché dans lequel évolue la propriété?",
      questionAnnualBudget:
        "36. Indiquez le budget d'exploitation annuel approximatif de l'organisation commanditée.",
      commentAnnualBudget:
        "FR - The budget amount is kept confidential and is only used to calculate the value of your sponsorship.",
      questionRightsFee:
        "37. Quel est le prix demandé pour la présente demande de commandite?",
      questionActivationBudget:
        "38. Quel est le budget approximatif prévu pour l'exploitation (activation) de la commandite?",
      questionParnershipRevenues:
        "39. Quel est le revenu net direct découlant du partenariat?",
      statements: {
        sponsorSatisfactionOriented:
          "Les objectifs d'affaires de la propriété sont guidés principalement par la satisfaction des commanditaires.",
        sponsorUnderstanding:
          "La propriété mise sur une compréhension des besoins et objectifs des commanditaires.",
        valueForSponsor:
          "La propriété est motivée par la génération d'une plus grande valeur pour les commanditaires.",
        satisfactionMeasured:
          "La satisfaction des commanditaires est mesurée de façon systématique et fréquente.",
        goodService:
          "Une attention particulière est portée au service à la clientèle offert aux commanditaires.",
      },
      infoSources: {
        annualReport: "Bilan annuel",
        consumerSurvey: "Sondage consommateur (notoriété, satisfaction, etc.)",
        auditReport:
          "Rapport d'une firme externe sur la mesure de l'achalandage",
        caseStudies:
          "Études de cas (exemples d'activation d'autres commanditaires et de succès provenant de la commandite)",
        forum: "Forum des commanditaires",
      },
      annualBudget: {
        range1: "Moins de 50 000 $",
        range2: "De 50 000 $ à 99 999 $",
        range3: "De 100 000 $ à 499 999 $",
        range4: "De 500 000 $ à 999 999 $",
        range5: "De 1 à 4 999 999 millions $",
        range6: "De 5 à 9 999 999 millions $",
        range7: "De 10 à 20 millions $",
        range8: "Plus de 20 millions",
      },
      activationBudget: {
        experiential: "Experiential",
        media: "Media",
        athleteAppearance: "Athlete appearance",
        otherApprearance: "Appearance (other than athlete)",
      },
      budgetValue: "Budget et valeur",
    },
    benefits: {
      title: "Étape 5 : Bénéfices",
      ownedMediaTitle: "Étape 5 : Médias détenus",
      promotionalPlan: {
        title: "Plan promotionnel",
        titleDollars: "Plan promotionnel (dollars)",
        titleImpressions: "Plan promotionnel (annonces & impressions)",
        helpList1:
          "Il s’agit des valeurs globales prévues au plan de communication de la propriété et non celles qui sont spécifiques à la marque commanditaire visée.",
        helpList2:
          "Si des placements média sont offerts à titre gracieux, vous devez tout de même inscrire la valeur à la carte estimée.",
        helpText:
          "Entrez les détails du plan promotionnel de la propriété, c'est-à-dire les éléments qui donnent de la visibilité aux commanditaires.",
        helpTextAdNumber:
          "Entrez les détails du plan promotionnel de la propriété, c'est-à-dire les éléments qui donnent de la visibilité aux commanditaires.",
        signage: {
          title: "Affichage extérieur, intérieur et transit",
          enterTotal: "Veuillez entrer le nombre total d'impressions générées.",
        },
        print: {
          title: "Imprimés - magazines, hebdos et quotidiens",
          enterTotal:
            "Veuillez fournir le total d'impressions générées par la campagne. Cela inclut la distribution, les impressions et la circulation.",
        },
        digital: {
          title:
            "Numérique - campagne publicitaire web (bannières, mots clés et autres formats)",
          enterTotal: "Veuillez entrer le nombre total d'impressions générées.",
        },
        tv: {
          title: "Télévision - achats de publicités",
          enterTotal:
            "Veuillez fournir le nombre total d'annonces pour l'ensemble de la campagne mettant en avant notre marque.",
        },
        radio: {
          title: "Radio",
          enterTotal:
            "Veuillez entrer le nombre total d'impressions générées par la campagne radio.",
        },
        social: {
          title:
            "Médias sociaux (seulement les initiatives payées par la propriété, telles que les bannières ou la promotion de contenu.)",
          enterTotal:
            "Veuillez entrer le nombre total d'impressions garanties.",
        },
        promoPlanType:
          "Connaissez-vous la valeur monétaire de votre plan média (campagne publicitaire) ?",
        isBrandFeatured: "La publicité présente-t-elle {sponsorBrand}?",
        totalAdImpressions:
          "Veuillez fournir le nombre total d'impressions générées par la campagne",
        totalAdValue:
          "Veuillez entrer la valeur totale de la campagne (en dollars)",
      },
      specificBenefits: "Bénéfices propres à la proposition",
      specificBenefitsDetails:
        "Bénéfices offerts dans le cadre de l’entente de commandite (contractuels) et qui sont propres à cette proposition.",
      deliveryPercent: "Pourcentage délivré",
      benefit: "Type de bénéfice",
      breakdown: "Type de bénéfice",
      name: "Nom (optionnel)",
      description: "Commentaires / Description",
      timesOffered: "Nombre de fois offert",
      timesOfferedTooltip: "Le nombre de fois que le bénéfice est offert",
      predominance: "Prédominance",
      predominanceTooltip:
        "Le facteur de prédominance définit la qualité de la visibilité (le commanditaire est visible parmi plusieurs autres partenaires ou il est prédominant ou est seul commanditaire présent) et le nombre de personnes exposées à chacun des bénéfices selon l’estimation qu’il vous est possible de faire.",
      questionPredominance:
        "Quelle place est accordée au sponsor à travers cette communication? (en %)",
      exposure: "Exposition",
      exposureTooltip:
        "Correspond à la distribution totale / au trafic / au nombre de visiteurs / au nombre d'impressions estimées, etc., pour chaque bénéfice.",
      add: "Ajouter un bénéfice",
      hospitality: {
        title: "Hospitalité",
        helpText:
          "Vous devez entrer les bénéfices pour les clients privilégiés, soit les billets de courtoisie, les loges, les activités de réseautage, les repas ou tout autre bénéfice de nature similaire. Tentez d’estimer la valeur du marché pour les bénéfices qui ne s’achètent pas.",
        food: "Nourriture et boisson",
        tickets: "Billets",
        corporate: "Loge corporative et autres avantages",
      },
      broadcast: "Télédiffusion ou webdiffusion",
      expandall: "Montrer tous",
      collapseall: "Cacher tous",
      asset: "Bénéfice",
      selectAssetType: "Sélectionner un bénéfice",
    },
    paidMediaPlan: {
      title: "6. Médias payants",
      tv: {
        title: "Télévision",
        quantityLabel: "Nombre de spots - Télévision",
        quantityDesc:
          "Veuillez noter le nombre total de publicités télévisées différentes.",
        stationLabel: "Station de télévision (diffusion et câble)",
        stationDesc:
          "Veuillez indiquer quelle station de télévision diffusera la publicité.",
        detailsLabel: "Description du support télévisuel",
        detailsDesc: "Veuillez donner une brève description.",
        timingLabel: "Période de diffusion - Télévision",
        timingDesc:
          "Veuillez indiquer la ou les dates de diffusion de la publicité télévisée.",
        lengthLabel: "Durée totale de la publicité télévisée",
        lengthDesc:
          "Veuillez indiquer la durée totale de la publicité télévisée. Exemples : 15s, 30s, 60s, etc.",
      },
      radio: {
        title: "Radio",
        quantityLabel: "Nombre de spots - Radio",
        quantityDesc:
          "Veuillez noter le nombre total de publicités radio différentes.",
        totalImpressionsDesc: "Veuillez noter le nombre total d'impressions.",
        stationLabel: "Station de radio",
        stationDesc:
          "Veuillez indiquer quelle station de radio diffusera la publicité.",
        detailsLabel: "Description du support radiophonique",
        detailsDesc: "Veuillez donner une brève description.",
        timingLabel: "Période de diffusion - Radio",
        timingDesc:
          "Veuillez indiquer la ou les dates de diffusion de la publicité radio.",
        lengthLabel: "Durée totale de la publicité radio",
        lengthDesc:
          "Veuillez indiquer la durée totale de la publicité radio. Exemples : :15, :30, :60, etc.",
      },
      print: {
        title: "Imprimé",
        quantityLabel: "Quantité de supports imprimés",
        quantityDesc:
          "Veuillez noter le nombre total de fois où le support imprimé sera disponible sur le marché.",
        stationLabel: "Type de support imprimé",
        stationDesc:
          "Exemples : publicité dans un magazine, publicité dans un journal, affiches, dépliants, etc. Cela exclut tout matériel imprimé pour un événement spécifique. Veuillez inclure ces éléments dans la section Sur place.",
        detailsLabel: "Description du support imprimé",
        detailsDesc: "Veuillez donner une brève description.",
        timingLabel: "Période de disponibilité du support imprimé",
        timingDesc:
          "Veuillez indiquer les dates spécifiques de disponibilité du support imprimé sur le marché.",
        totalImpressionsLabel: "Distribution/Circulation du support imprimé",
        totalImpressionsDesc:
          "Cela comprend la distribution, les impressions et la circulation.",
        sizeLabel: "Caractéristiques/Format du support imprimé",
        sizeDesc:
          'Exemples : Pleine page (8,5" x 11"), 11" x 17", 5" x 7", etc.',
      },
      ooh: {
        title: "Affichage extérieur",
        quantityLabel: "Quantité de supports d'affichage extérieur",
        quantityDesc:
          "Veuillez noter le nombre total de fois où le support d'affichage extérieur sera disponible sur le marché.",
        stationLabel: "Type de support d'affichage extérieur",
        stationDesc:
          "Exemples : panneaux d'affichage numériques, panneaux d'affichage en vinyle, publicité dans les transports en commun, etc.",
        detailsLabel: "Description du support d'affichage extérieur",
        detailsDesc: "Veuillez donner une brève description.",
        timingLabel:
          "Nombre de mois où le support d'affichage extérieur sera disponible sur le marché",
        timingDesc:
          "Veuillez sélectionner le nombre approprié de mois où le support d'affichage extérieur sera disponible sur le marché.",
        totalImpressionsLabel: "Impressions totales d'affichage extérieur",
        totalImpressionsDesc: "Veuillez noter le nombre total d'impressions.",
      },
      online: {
        title: "En ligne",
        quantityLabel: "Quantité de supports en ligne",
        quantityDesc:
          "Veuillez noter le nombre total de fois où le support en ligne sera disponible sur le marché.",
        stationLabel: "Type de support en ligne",
        stationDesc:
          "Exemples : bannières publicitaires numériques, Adwords, achat programmatique, etc.",
        detailsLabel: "Description du support en ligne",
        detailsDesc:
          "Veuillez donner une brève description du support en ligne et préciser où il sera diffusé. Indiquez si les publicités sont vidéo, riches en médias/dynamiques ou statiques/standards.",
        timingLabel: "Période de disponibilité du support en ligne",
        timingDesc:
          "Veuillez indiquer les dates spécifiques de disponibilité du support en ligne sur le marché.",
        totalImpressionsLabel: "Impressions totales garanties - En ligne",
        totalImpressionsDesc:
          "Veuillez noter le nombre total d'impressions garanties.",
        sizeLabel: "Caractéristiques/Format - En ligne",
        sizeDesc:
          "Taille de la bannière publicitaire numérique ou type d'achat média.",
      },
      social: {
        title: "Social",
        quantityLabel: "Quantité de supports sociaux",
        quantityDesc:
          "Veuillez noter le nombre total de fois où le support social unique sera disponible sur le marché.",
        stationLabel: "Type de support social",
        stationDesc:
          "Exemples : Facebook, Instagram, X (Twitter), YouTube, etc.",
        detailsLabel: "Description du support social",
        detailsDesc:
          "Veuillez donner une brève description du type d'unité publicitaire sur les médias sociaux. Dynamique ou statique/standard.",
        timingLabel: "Période de disponibilité du support social",
        timingDesc:
          "Veuillez indiquer les dates spécifiques de disponibilité du support social sur le marché.",
        totalImpressionsLabel: "Impressions totales garanties - Social",
        totalImpressionsDesc:
          "Veuillez noter le nombre total d'impressions garanties.",
      },
      mediaLengths: {
        fifteen: "15s",
        thirty: "30s",
        sixty: "60s",
        other: "Autre",
      },
      add: "Ajouter une annonce de type {mediaType}",
    },
    hospitality: {
      title: "7. Hospitalité",
      tickets: {
        title: "Billets",
        itemName: "Nom",
        description: "Description",
        quantity: "Quantité",
        unitValue: "Valeur unitaire (dollars)",
        date: "Date",
        period: "Période [alternatif]",
        seasonTickets: "Billets de saison",
        annualPass: "Passe annuelle",
      },
      corporate: {
        title: "Loge corporative et autres",
        itemName: "Nom",
        description: "Description",
        quantity: "Quantité",
        unitValue: "Valeur unitaire (dollars)",
        date: "Date",
        period: "Période [alternatif]",
        seasonTickets: "Billets de saison",
        annualPass: "Passe annuelle",
      },
      food: {
        title: "Nourriture et boisson",
        itemName: "Nom",
        description: "Description",
        quantity: "Quantité",
        unitValue: "Valeur unitaire (dollars)",
        date: "Date",
        period: "Période [alternatif]",
        seasonTickets: "Billets de saison",
        annualPass: "Passe annuelle",
      },
      other: {
        title: "Autre",
        itemName: "Nom",
        description: "Description",
        quantity: "Quantité",
        unitValue: "Valeur unitaire (dollars)",
        date: "Date",
        period: "Période [alternatif]",
        seasonTickets: "Billets de saison",
        annualPass: "Passe annuelle",
      },
      add: "Ajouter {hospitalityType}",
    },
    activation: {
      title: "Étape 6 : Activation",
      titlePublix: "Étape 9 : Activation",
      paidMedia: "Achat média",
      category: "Catégorie",
      name: "Nom",
      description: "Description",
      mediaBuy: "Achat média",
      cost: "Coût",
      add: "Ajouter un article",
      asset: "Article",
      subTotal: "Sous-total",
      prodCost: "Coût de production",
      experiential: "Expérientiel et actifs détenus",
      offered: "Nombre de fois offert",
      predominance: "Prédominance",
      exposure: "Exposition",
    },
    metrics: {
      title: "Étape 7 : Indicateurs",
      titlePublix: "Step 8 : Indicateurs",
      add: "Ajouter",
      name: "Nom",
      type: "Type de donnée",
      value: "Valeur",
      comment: "Commentaires",
      metric: "Indicateur",
      commentSaved: "Commentaire sauvegardé avec succès.",
      commentDeleted: "Commentaire supprimé avec succès.",
      saveComment: "Sauvegarder le commentaire",
      newComment: "Nouveau commentaire",
      postedBy: "Écrit par",
    },
    admin: {
      title: "Étape 8: Divers",
      titlePublix: "Étape 7 : Divers",
    },
  },
};
