import _ from "lodash";
import api from "../../api";

export default {
  state: {
    users: [],
    usersFetched: false,
    usersLoading: false,
  },

  mutations: {
    SITE_USERS_START_FETCHING: (state) => {
      state.usersLoading = true;
    },

    SITE_USERS_FETCHED: (state, users) => {
      state.users = users;
      state.usersFetched = true;
      state.usersLoading = false;
    },

    SITE_USER_CREATED: (state, user) => {
      state.users.push(user);
    },

    SITE_USER_UPDATED: (state, user) => {
      const object = _.find(state.users, { id: user.id });

      if (object) {
        const index = state.users.indexOf(object);
        state.users.splice(index, 1, user);
      }
    },

    SITE_USER_DELETED: (state, id) => {
      const object = _.find(state.users, { id });

      if (object) {
        const index = state.users.indexOf(object);
        state.users.splice(index, 1);
      }
    },
  },

  actions: {
    async fetchSiteUsers({ commit }, websiteId) {
      commit("SITE_USERS_START_FETCHING");

      const users = await api.getSiteUsers(websiteId);
      commit("SITE_USERS_FETCHED", users);
      return users;
    },

    async createSiteUser({ commit }, payload) {
      const user = await api.createUser(payload);
      commit("SITE_USER_CREATED", user);
      return user;
    },

    async updateSiteUser({ commit }, payload) {
      const user = await api.updateUser(payload);
      commit("SITE_USER_UPDATED", user);
      return user;
    },

    async deleteSiteUser({ commit }, { websiteId, userId }) {
      await api.deleteUser({ websiteId, userId });
      commit("SITE_USER_DELETED", userId);
    },

    async sendWelcomeEmail(context, { websiteId, userId }) {
      await api.sendWelcomeEmail({ websiteId, userId });
    },

    async copyLoginLink(context, { websiteId, userId }) {
      return await api.copyLoginLink({ websiteId, userId });
    },
    exportToCognito(context, { websiteId, userId }) {
      return api.exportToCognito({ websiteId, userId });
    },
  },
};
