import api from "../../api";

export default {
  state: {
    pages: [],
    page: [],
    pagesFetched: false,
    pagesLoading: false,
    pageSaved: false,
  },

  mutations: {
    PAGES_START_FETCHING: (state) => {
      state.pagesLoading = true;
      state.pageSaved = false;
    },

    PAGES_FETCHED: (state, pages) => {
      state.pages = pages;
      state.pagesFetched = true;
      state.pagesLoading = false;
    },

    PAGE_FETCHED: (state, page) => {
      state.page = page;
      state.pagesFetched = true;
      state.pagesLoading = false;
    },

    PAGE_EXITED: (state, page) => {
      state.page = page;
      state.pagesFetched = true;
      state.pagesLoading = false;
      state.pageSaved = true;
    },
  },

  actions: {
    fetchPages({ commit }, payload) {
      commit("PAGES_START_FETCHING");

      return api.getPages(payload).then((fields) => {
        commit("PAGES_FETCHED", fields);
      });
    },

    async fetchPage({ commit }, payload) {
      commit("PAGES_START_FETCHING");

      return api.getCurrentPage(payload).then((page) => {
        commit("PAGE_FETCHED", page);
      });
    },

    async editFetchedPage({ commit }, payload) {
      commit("PAGES_START_FETCHING");

      return api.editCurrentPage(payload).then((page) => {
        commit("PAGE_EXITED", page);
      });
    },
  },
};
