import accessibility from "./fr/accessibility";
import accounts from "./fr/accounts";
import auth from "./fr/auth";
import budgets from "./fr/budgets";
import common from "./fr/common";
import components from "./fr/components";
import dashboard from "./fr/dashboard";
import emailTemplates from "./fr/emailTemplates";
import errors from "./fr/errors";
import groups from "./fr/groups";
import menu from "./fr/menu";
import pages from "./fr/pages";
import profileFields from "./fr/profileFields";
import questions from "./fr/questions";
import reports from "./fr/reports";
import requests from "./fr/requests";
import settings from "./fr/settings";
import styles from "./fr/styles";
import users from "./fr/users";
import properties from "./fr/properties";

export default {
  accessibility,
  accounts,
  auth,
  budgets,
  common,
  components,
  dashboard,
  emailTemplates,
  errors,
  groups,
  menu,
  pages,
  profileFields,
  questions,
  reports,
  requests,
  settings,
  styles,
  users,
  properties,
};
