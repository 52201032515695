<template>
  <div class="brickroad-app">
    <div class="d-flex">
      <left-app-menu v-if="isUserAuthenticated"></left-app-menu>
      <div
        :id="isUserAuthenticated ? 'mainContainer' : 'loginContainer'"
        class="container m-0 p-0"
      >
        <div v-if="!isUserAuthenticated" class="app-header">
          <language-switch
            class="lang-switch d-block text-right mr-2"
          ></language-switch>
        </div>
        <app-header
          v-if="isUserAuthenticated"
          :back-route="backRoute"
        ></app-header>
        <!-- We have the padding on this element only if there is breadcrumb to avoid problems with pages where there is not -->
        <div :class="{ 'page-content': $route?.meta.breadcrumb }">
          <b-breadcrumb
            v-if="$route?.meta.breadcrumb"
            :items="breadcrumbAddTranslations($route.meta.breadcrumb)"
            class="d-print-none"
          >
          </b-breadcrumb>
          <router-view
            :class="{ 'page-content': !$route?.meta.breadcrumb }"
          ></router-view>
        </div>
      </div>
    </div>
    <modal></modal>
  </div>
</template>

<script>
import { Settings as LuxonSettings } from "luxon";
import userData from "@/mixins/user-data";
import permissions from "@/mixins/permissions";
import AppHeader from "@/components/layout/AppHeader";
import LeftAppMenu from "@/components/layout/LeftAppMenu";
import Modal from "@/vendor/vue-modal-box/components/ModalContainer";
import LanguageSwitch from "@/components/layout/header/LanguageSwitch";
import ChangePasswordModal from "@/views/auth/ChangePasswordModal";
import { bus } from "@/main";
import { BBreadcrumb } from "bootstrap-vue";

export default {
  name: "admin-app",
  mixins: [userData, permissions],
  components: {
    AppHeader,
    LeftAppMenu,
    Modal,
    LanguageSwitch,
    BBreadcrumb,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  computed: {
    backRoute() {
      return this.$store.state.navigation.backRoute;
    },
    isUserAuthenticated() {
      return this.$store.state.auth.authenticated;
    },
  },
  methods: {
    displayNotyObjectInStorage() {
      const obj = JSON.parse(localStorage.getItem("notyObj"));

      if (obj) {
        localStorage.removeItem("notyObj");
        window.setTimeout(
          () => this.$noty[obj.type](obj.message),
          obj.timeout || 0,
        );
      }
    },
    setNavigationState(route) {
      // Handle active menu
      const activeMenu =
        route.meta && route.meta.activeMenu ? route.meta.activeMenu : "";
      this.$store.commit("SET_ACTIVE_MENU", activeMenu);

      // Handle 'go back' route
      const goBackRoute =
        route.meta && route.meta.backRoute ? route.meta.backRoute : null;
      this.$store.commit("SET_BACK_ROUTE", goBackRoute);
    },
    async fetchData() {
      if (this.isUserAuthenticated) {
        await this.fetchUserPreferences();
        this.fetchPermissions();
        this.fetchCurrentLicense();
        this.fetchRequestsKpis();
      }
      this.isLoaded = true;
    },
    fetchCurrentLicense() {
      if (
        this.currentUser.role !== "superadmin" &&
        !this.$store.state.licenses.currentLicense.fetched &&
        !this.$store.state.licenses.currentLicense.fetching
      ) {
        this.$store.dispatch("fetchCurrentLicense", this.userLicenseId);
      }
    },
    fetchRequestsKpis() {
      if (
        !this.$store.state.requestsKpis.fetched &&
        !this.$store.state.requestsKpis.fetching &&
        this.hasPermission(this.permissionEnum.DashboardView)
      ) {
        this.$store.dispatch("fetchRequestsKpis", this.websiteId);
      }
    },
    fetchPermissions() {
      if (
        !this.$store.state.permissions.fetched &&
        !this.$store.state.permissions.fetching
      ) {
        this.$store.dispatch("fetchPermissions");
      }
    },
    async fetchUserPreferences() {
      if (
        !this.$store.state.preferences.fetched &&
        !this.$store.state.preferences.fetching
      ) {
        await this.$store.dispatch("fetchUserPreferences");
      }
    },
    breadcrumbAddTranslations(breadcrumb) {
      return breadcrumb.map((crumb) => {
        return {
          ...crumb,
          text: this.$t(crumb.text, this.$route.meta),
        };
      });
    },
  },
  created() {
    this.setNavigationState(this.$route);
    this.fetchData();
    this.displayNotyObjectInStorage();

    if (this.$route.query && this.$route.query.lang) {
      let lang = this.$route.query.lang;

      if (!["en", "fr"].includes(lang)) {
        lang = "en";
      }

      localStorage.setItem("user_lang", lang);
      this.$i18n.locale = lang;
    }

    LuxonSettings.defaultLocale = this.$i18n.locale;

    bus.$on("changePassword", () => {
      this.$modal.open(ChangePasswordModal).done(() => {
        localStorage.setItem(
          "notyObj",
          JSON.stringify({
            type: "success",
            message: this.$t("admin.auth.passwordHasBeenChanged"),
            timeout: 1500,
          }),
        );
        window.setTimeout(() => window.location.reload(), 300);
      });
    });
  },
  watch: {
    $route(data) {
      this.setNavigationState(data);
    },
    "$store.state.styles.styles"(styles) {
      const favicon = document.getElementById("favicon");
      if (styles.favicon_image) {
        favicon.href = styles.favicon_image_url;
      }
    },
  },
};
</script>

<style lang="scss">
@import "styles/main.scss";

.colorText {
  color: #002d57;

  &:hover {
    color: #007bff;
    text-decoration: none;
  }
}

#mainContainer {
  position: relative;
  left: $menu-width;
  max-width: calc(100% - #{$menu-width});
}

#loginContainer {
  max-width: 100vw;
}

.breadcrumb {
  background-color: white;
  padding: 0;
}
</style>
