import _ from "lodash";
import api from "../../api";

export default {
  state: {
    questions: [],
    question: [],
    selectedQuestion: "",
    questionsFetched: false,
    questionsLoading: false,
    questionSaved: false,
  },

  getters: {
    getQuestionById: (state) => (id) => {
      return _.find(state.questions, (o) => Number(o.id) === Number(id));
    },
  },

  mutations: {
    QUESTIONS_START_FETCHING: (state) => {
      state.questionsLoading = true;
      state.questionSaved = false;
    },

    QUESTIONS_FETCHED: (state, questions) => {
      state.questions = questions;
      state.questionsFetched = true;
      state.questionsLoading = false;
    },

    QUESTION_FETCHED: (state, question) => {
      state.question = question;
      state.selectedQuestion = question.id;
      state.questionsFetched = true;
      state.questionsLoading = false;
    },

    QUESTION_SAVED: (state, question) => {
      state.question = question;
      state.questionsFetched = true;
      state.questionsLoading = false;
      state.questionSaved = true;
    },

    QUESTION_CREATED: (state, payload) => {
      state.questions.push(payload);
    },

    QUESTION_DELETED: (state, id) => {
      const object = _.find(state.questions, { id });

      if (object) {
        const index = state.questions.indexOf(object);
        state.questions.splice(index, 1);
      }
    },
  },

  actions: {
    async fetchQuestions({ commit }, websiteId) {
      commit("QUESTIONS_START_FETCHING");
      const questions = await api.questions.query({ websiteId });
      commit("QUESTIONS_FETCHED", questions);
      return questions;
    },

    async fetchQuestion({ commit }, { websiteId, id }) {
      commit("QUESTIONS_START_FETCHING");

      return api.questions.get({ websiteId, id }).then((question) => {
        commit("QUESTION_FETCHED", question);
      });
    },

    async createQuestion({ commit }, payload) {
      const question = await api.questions.create(payload);
      commit("QUESTION_CREATED", question);
      return question;
    },

    async cloneQuestion({ commit }, payload) {
      const question = await api.cloneQuestion(
        payload.websiteId,
        payload.questionId,
      );
      commit("QUESTION_CREATED", question);
      return question;
    },

    async editQuestion({ commit }, payload) {
      commit("QUESTIONS_START_FETCHING");
      const question = await api.questions.update(payload);
      commit("QUESTION_SAVED", question);
    },

    async deleteQuestion({ commit }, payload) {
      await api.questions.delete(payload);
      commit("QUESTION_DELETED", payload.id);
    },
  },
};
