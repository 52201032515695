<template>
  <div class="modal-container">
    <component :is="component" :data="data" :visible="isVisible"></component>
  </div>
</template>

<script>
export default {
  computed: {
    isVisible() {
      return this.$modal.state.isVisible;
    },
    component() {
      return this.$modal.state.component;
    },
    data() {
      return this.$modal.state.data;
    },
  },
};
</script>
