export default {
  title: "Properties",
  create: "Create a Property",
  propertyOwnerQuestions: "Questions for property owner",
  adminQuestions: "Questions for administrator",
  sendLink: "Send link",
  copyLink: "Copy link",
  learnMore: "Learn more",
  thankYou: "Thank you for completing the questionnaire",
  noLongerAvailable: "This questionnaire is no longer available",
  table: {
    noContent: "No property created yet!",
    name: "Property Name",
    created: "Creation Date",
    city: "City",
    type: "Type",
    startDate: "Start Date",
    askedPrice: "Asked price ($)",
    value: "Value ($)",
    status: "Status",
    actions: "Actions",
  },
  form: {
    steps: {
      step1: "General",
      step2: "Audience",
      step3: "Sponsor Information",
      step4: "Sponsorship Management",
      step5: "Owned Media",
      step6: "Paid Media",
      step7: "Hospitality",
      step8: "Admin",
      step9: "Custom Metrics",
      step10: "Activation",
    },
    general: {
      publixQuestions: {
        questionPropertyName: "1. What is the name of your request or project?",
        questionCity:
          "2. Please indicate where your event or project will take place.",
        questionCategory:
          "3. Which category best reflects the nature of your organization or event?",
        questionLevel: "4. What level of sponsorship are you offering?",
        questionBenefits:
          "5. Select all benefits and rights that apply to the sponsorship offer.",
        questionSocialAudienceHandle:
          "6. Indicate your audience for each of the following digital platforms (where applicable):",
      },
      tooManyRequests:
        "We are unable to process this request at this time. Please try again in a minute.",
      cannotRemoveDigitalAudience: "Error while deleting",
      title: "Step 1: General",
      jumbotronTtile:
        "Only enter the information relative to the property. Make sure to have the following information at hand: ",
      jumbotronList1: "All the data on the property and its audience.",
      jumbotronList2:
        "Details of your communications actions: media plan and the benefits offered to the sponsor.",
      jumbotronList3: "The research data, if available.",
      questionPropertyName: "1. What is the name of your request or project?",
      questionTimeFrame:
        "2. Indicate the timeframe in which your organization or event will be active.",
      beginning: "Beginning",
      end: "End",
      questionCity:
        "3. Please indicate where your event or project will take place.",
      questionSponsorType: "4. What type of sponsorship are you offering?",
      personalEndorsement: "Personal endorsement",
      influencer: "Influencer / Ambassador / Content creator",
      team: "Team",
      league: "League",
      venue: "Venue (stadium, racetrack, etc.)",
      event: "Event",
      organization: "Organization",
      media: "Media",
      productPlacement: "Product placement",
      questionCategory:
        "5. Which category best reflects the nature of your organization or event?",
      questionLevel: "6. What level of sponsorship are you offering?",
      questionExclusivity:
        "7. What type of exclusivity is being offered as part of the sponsorship?",
      questionBenefits:
        "8. Select all benefits and rights that apply to the sponsorship offer.",
      questionSocialAudience:
        "Indicate the size of the audience for each of the property's digital platforms to which the brand has access.",
      questionSocialAudienceHandle:
        "9. Indicate your audience for each of the following digital platforms (where applicable):",
      facebook: "Facebook",
      tiktok: "TikTok",
      twitter: "X (Twitter)",
      snapchat: "Snapchat",
      youtube: "Youtube",
      linkedin: "LinkedIn",
      handleNotFound: "This username is invalid or does not exist",
      apps: "Smartphone apps (number of downloads.)",
      instagram: "Instagram",
      website:
        "Website (annual traffic) (indicate the number of annual visits.)",
      other: "Other? (specify)",
      questionCampaignDuration:
        "10. What is the duration of your media communications campaign?",
      questionCampaignDurationTooltip:
        "A communications campaign is usually composed of paid or donated advertising on one or more platforms (television, radio, print, Web, etc.).",
      questionMarketSize:
        "11a. How many organizations or events are similar to the property in the target market?",
      questionMarketSizeTooltip:
        "Similar: other events resembling the property’s event in the same market. For example, a basketball team and a baseball team operating in the same city are considered similar events, as are music festivals.\n Market: may vary according to event type. For example, two international car races, even if held in different cities, are considered part of the same market if they are legs of the same championship.",
      questionYears:
        "12. How long has your organization or event been in existence?",
      outOfCoverageZone:
        "The market you have selected is currently not supported. Please contact the system administrator for more information.",
      exclusivities: {
        category: "Category exclusivity",
        area: "Area or space exclusivity",
        communication:
          "Communications exclusivity, in the case of a media plan",
        none: "No exclusivity",
      },
      benefits: {
        allowSponsor: "a) The property allows the sponsor to:",
        distributeSamples: "Distribute samples on site",
        promoteProducts: "Hold on-site activations",
        dataAccess: "Provide access to database/email list",
        organizeEvents: "Offer hospitality & hosting",
        useEmployees: "Provide employee engagement opportunities",
        useContent: "Create and/or use custom content",
        consumerInteraction: "Offer one-on-one access to consumers",
        useSponsorsProducts:
          "The property will use the sponsor’s products or services",
      },
      maximumCharacters: "Maximum characters",
    },
    targets: {
      publixQuestions: {
        questionAwareness:
          "7. General public’s awareness of the organization or event.",
        questionDirectAudience:
          "8. How many people are directly exposed to your event or organization? (In the case of an event, indicate attendance numbers.)",
        questionAudited:
          "9. Have the above numbers been audited by a third party or by internal mechanisms to verify accuracy (i.e., ticket sales)?",
        questionSegments:
          "10. Allocate a percentage to each segment of the property’s direct audience. Total of percentages must equal 100%",
      },
      title: "Step 2: Audience",
      questionAwareness:
        "13. General public’s awareness of the organization or event.",
      questionPropertyScope:
        "14. What is the scope of your event or organization?",
      questionAwarenessTooltip:
        "Indicate the geographic scale on which the property is known by most of the general public. For instance, in the case of a local event familiar to most people in the region but to only a small proportion outside the region, the answer would be “regional” despite the small degree of spillover.",
      questionAreas:
        "15a. Please rate the property’s breakthrough in each of following geographic areas",
      questionDirectAudience:
        "16. How many people are directly exposed to your event or organization? (In the case of an event, indicate attendance numbers.)",
      questionAudited:
        "17. Have the above numbers been audited by a third party or by internal mechanisms to verify accuracy (i.e., ticket sales)?",
      questionAuditedTooltip:
        "If you have audited figures from a firm specializing in audience measurement on event sites or other verifiable data, select “yes.” If not, select “no.”",
      questionFirstVisitors:
        "18. What is the approximate number of first-time visitors per event or season?",
      questionVisitsPerPerson:
        "19. What is the approximate average number of visits per person per event or season?",
      questionVisitsPerPersonTooltip:
        "Indicate the average number of visits per person. This can be a whole number or a decimal. For example, the average number of visits for the general public is 1.2.",
      questionSegments:
        "20. Allocate a percentage to each segment of the property’s direct audience. Total of percentages must equal 100%",
      questionSegmentsTooltip:
        "If this information is unavailable, you can provide estimates or leave these fields blank. Leaving the fields blank, however, will affect the calculation of the sponsorship value. Audience figures can be obtained through a specialized research firm or internal data. Audience profiles can be evaluated using customer sampling.",
      audienceOrigin: "What is the direct audience origin?",
      questionEthnicity: "What is your ethnicity?",
    },
    brand: {
      publixQuestions: {
        questionSponsorNumber:
          "11. How many sponsors does your organization have in each category?",
      },
      title: "Step 3: Property’s brand, products & services, competition",
      questionCharacteristics:
        "21a. Evaluate how accurately each statement describes the property’s brand.",
      questionUsage:
        "22a. What is the likelihood of your company’s products/services being used by the property participants?",
      questionUsageTooltip:
        "In the case of an event or a game, for example, is it likely that participants will consume your products?",
      questionConsuming:
        "23a. What is the likelihood of your company’s products/services being consumed while watching the event if it were broadcast?",
      questionConsumingTooltip:
        "Assuming the event were to be broadcast on television, what is the likelihood that viewers would be consuming your products (your brand of beer, for example) while watching?",
      questionAudienceClients:
        "24a. What is the likelihood that consumers of your company’s products/services would be part of the event audience?",
      questionAudienceClientsTooltip:
        "Is your clientele the same as or different from the event audience?",
      questionAudienceEngagement:
        "25a. In your opinion, what interest do spectators have in attending the type of event organized by the property (e.g., a hockey event, such as a game by the local team, or a country and western event, such as a regional rodeo festival, etc.)? Circle the number that best reflects your opinion.",
      questionSponsorNumber:
        "26. How many sponsors does your organization have in each category?",
      questionNewSponsors:
        "27. How many new sponsors signed on with your organization in the last year?",
      questionEventVisibillity:
        "28. Which statement best describes your on-site visibility, in the case of an event?",
      localRegionalBrands: "Local or regional brands",
      nationalBrands: "National brands",
      internationalBrands: "International brands",
      serviceMediaPartners: "Service providers and media partners",
      visibility: {
        shared:
          "The sponsor brand is visible alongside the other partner companies.",
        dedicated:
          "The sponsor brand has a dedicated zone and space for its own distinct advertising.",
      },
      products: "Products and services",
      competition: "Competition",
    },
    management: {
      publixQuestions: {
        questionAccountManager:
          "12. Will your organization be assigning an account manager to the sponsor to address questions and specific needs for the sponsorship? ",
        questionInformationSources:
          "13. Select all information sources that your organization will make available to the sponsor. Select as many as apply.",
        questionRightsFee:
          "14. What is the property's rights fee for this sponsorship?",
      },
      title: "Step 4: Management, support, and budget",
      questionAccountManager:
        "29. Will your organization be assigning an account manager to the sponsor to address questions and specific needs for the sponsorship? ",
      questionSatisfaction:
        "30a. Indicate your overall degree of satisfaction with the property team.",
      questionRateProperty:
        "31a. Rate how you regard the property. By regard, we mean how positively or negatively you think or feel about the property?",
      questionDescribeProperty:
        "32a. Rate each of the following statements with regards to how they best describe the property.",
      questionInformationSources:
        "33. Select all information sources that your organization will make available to the sponsor. Select as many as apply.",
      questionDevPlan:
        "34. Does your organization have a sustainable development plan?",
      questionMarketImportance:
        "35a. How important to your organization is the market in which the property operates?",
      questionAnnualBudget:
        "36. What is the approximate annual operating budget of your organization?",
      commentAnnualBudget:
        "The budget amount is kept confidential and is only used to calculate the value of your sponsorship.",
      questionRightsFee:
        "37. What is the asking price for this sponsorship request?",
      questionActivationBudget:
        "38a. What is the estimated annual sponsorship activation budget for this property?",
      questionParnershipRevenues:
        "39a. Please indicate the total direct revenue from the partnership.",
      statements: {
        sponsorSatisfactionOriented:
          "The property’s business goals are primarily guided by sponsor satisfaction.",
        sponsorUnderstanding:
          "The property focuses on understanding sponsors’ needs and objectives.",
        valueForSponsor:
          "The property’s motivation is to build greater value for sponsors.",
        satisfactionMeasured:
          "Sponsor satisfaction is measured frequently and systematically.",
        goodService:
          "Special attention is paid to providing good customer service to sponsors.",
      },
      infoSources: {
        annualReport: "Annual report",
        consumerSurvey: "Consumer survey (notoriety, satisfaction, etc.)",
        auditReport: "External audience audit report",
        caseStudies:
          "Case studies (examples of successful sponsorship activations)",
        forum: "Sponsors' forum",
      },
      annualBudget: {
        range1: "Less than $50,000",
        range2: "$50,000 - $99,999",
        range3: "$100,000 - $499,999",
        range4: "$500,000 - $999,999",
        range5: "$1 million - $4,999,999",
        range6: "$5 million - $9,999,999",
        range7: "$10 million - $20 million",
        range8: "More than $20 million",
      },
      activationBudget: {
        experiential: "Experiential",
        media: "Media",
        athleteAppearance: "Athlete appearance",
        otherApprearance: "Appearance (other than athlete)",
      },
      budgetValue: "Budget and value",
    },
    benefits: {
      title: "Step 5: Assets",
      ownedMediaTitle: "Step 5: Owned media",
      promotionalPlan: {
        title: "Communications plan",
        titleDollars: "Communications plan (dollars)",
        titleImpressions: "Communications plan (ads & impressions)",
        helpList1:
          "These are the global amounts for your plan and are not specific to the brand",
        helpList2:
          "In the case of a trade in service, enter the estimated value (rate card) of free placements.",
        helpText:
          "Enter the total value (media rate cards) of each initiative used to promote your event or organization in which the sponsor logo will appear.",
        helpTextAdNumber:
          "Enter the number of impressions/ads of each initiative.",
        signage: {
          title: "Signage (outdoor, indoor & directional)",
          enterTotal: "Please note the total number of impressions generated.",
        },
        print: {
          title: "Print",
          enterTotal:
            "Please provide total impressions generated by the campaign. This includes the distribution, impressions, and circulation.",
        },
        digital: {
          title: "Digital (banners, keywords or other formats)",
          enterTotal: "Please note the total number of guaranteed impressions.",
        },
        tv: {
          title: "TV",
          enterTotal:
            "Please provide the total number of ads for the entire campaign featuring our brand.",
        },
        radio: {
          title: "Radio",
          enterTotal:
            "Please note the total number of impressions generated by the radio campaign.",
        },
        social: {
          title:
            "Social (only initiatives paid for by the property, such as banners or content promotion.)",
          enterTotal: "Please note the total number of guaranteed impressions.",
        },
        promoPlanType:
          "Do you know the monetary value of your media plan (advertising campaign)?",
        isBrandFeatured: "Does the ad feature {sponsorBrand}?",
        totalAdImpressions:
          "Please provide the total of impressions generated by the campaign",
        totalAdValue:
          "Please provide the total value of the campaign (in dollars)",
      },
      specificBenefits: "Specific benefits to the proposal",
      specificBenefitsDetails:
        "Benefits offered under the sponsorship agreement (contractual) that are specific to this proposal.",
      deliveryPercent: "Delivery %",
      benefit: "Benefit",
      breakdown: "Asset breakdown (contractual)",
      name: "Name",
      description: "Comments / Description",
      timesOffered: "Number of times offered",
      timesOfferedTooltip:
        "This is the number of times the benefit is offered under this proposal",
      predominance: "Predominance",
      predominanceTooltip:
        "Factor of predominance (1 being the lowest, 5 the highest). This figure helps qualify the quality of the visibility. On a stage, for example, is the sponsor present among numerous other partners (score of 1-3), or alone and predominant (score of 4-5)? Corresponds to total distribution / traffic / number of visitors / estimated impressions, etc., for each benefit.",
      questionPredominance:
        "How much is the sponsor allowed throughout this communication? (in %)",
      exposure: "Exposure",
      exposureTooltip:
        "Corresponds to total distribution / traffic / number of visitors / estimated impressions, etc., for each benefit.",
      add: "Add Benefit",
      hospitality: {
        title: "Hospitality",
        helpText:
          "You must include all VIP benefits, including complimentary tickets, VIP boxes or green rooms, networking events, dinners, and so on. Try to assign an estimated market value to unpaid benefits.",
        food: "Food and Beverages",
        tickets: "Tickets",
        corporate: "Corporate Box and Hosting",
      },
      broadcast: "Broadcast value",
      expandall: "Expand All",
      collapseall: "Collapse All",
      asset: "Asset",
      selectAssetType: "Select an asset type",
    },
    paidMediaPlan: {
      title: "6. Paid media",
      tv: {
        title: "TV",
        quantityLabel: "Number of Spots - TV",
        quantityDesc:
          "Please note the total number of different TV advertisements.",
        stationLabel: "Television Station (Broadcast & Cable)",
        stationDesc:
          "Please note which television station advertisement will air.",
        detailsLabel: "Description of TV Advertisement",
        detailsDesc: "Please give a brief description.",
        timingLabel: "Timing/Flight - TV",
        timingDesc:
          "Please provide date/s of when the TV advertisement will air.",
        lengthLabel: "Length of total TV Advertisement",
        lengthDesc:
          "Please provide length of total TV advertisement. Examples include :15, :30, :60, etc.",
      },
      radio: {
        title: "Radio",
        quantityLabel: "Number of Spots - Radio",
        quantityDesc:
          "Please note the total number of different radio advertisements.",
        totalImpressionsDesc: "Please note the total number of impressions.",
        stationLabel: "Radio Station",
        stationDesc: "Please note which radio station advertisement will air.",
        detailsLabel: "Description of Radio Advertisement",
        detailsDesc: "Please give a brief description.",
        timingLabel: "Timing/Flight - Radio",
        timingDesc:
          "Please provide date/s of when the radio advertisement will air.",
        lengthLabel: "Length of total radio advertisement",
        lengthDesc:
          "Please provide length of total radio advertisement. Examples include :15, :30, :60, etc.",
      },
      print: {
        title: "Print",
        quantityLabel: "Quantity of Print Channel",
        quantityDesc:
          "Please note the total number of times the print channel will be in market.",
        stationLabel: "Type of Print Channel",
        stationDesc:
          "Examples include magazine ad, newspaper ad, posters, flyers, etc. This excludes any day of event print materials. Please include those in the On-Site section.",
        detailsLabel: "Description of Print Channel",
        detailsDesc: "Please give a brief description.",
        timingLabel: "Timing/Flights of Print Channel",
        timingDesc:
          "Please note the specific in market date/s of the print channel.",
        totalImpressionsLabel: "Distribution/Circulation of Print Channel",
        totalImpressionsDesc:
          "This includes the distribution, impressions, and circulation.",
        sizeLabel: "Specs/Size of Print Channel",
        sizeDesc:
          'Examples include Full Page (8.5" x 11"), 11" x 17", 5" x 7", etc.',
      },
      ooh: {
        title: "OOH",
        quantityLabel: "Quantity of OOH Channel",
        quantityDesc:
          "Please note the total number of times the OOH channel will be in market.",
        stationLabel: "Type of OOH Channel",
        stationDesc:
          "Examples include digital billboards, vinyl billboards, transit, etc.",
        detailsLabel: "Description of OOH Channel",
        detailsDesc: "Please give a brief description.",
        timingLabel: "Number of Months OOH Channel will be In-Market",
        timingDesc:
          "Please select the appropriate number of months the OOH will be in-market.",
        totalImpressionsLabel: "Total OOH Impressions",
        totalImpressionsDesc: "Please note the total number of impressions.",
      },
      online: {
        title: "Online",
        quantityLabel: "Quantity of Online Channel",
        quantityDesc:
          "Please note the total number of times the online channel will be in market.",
        stationLabel: "Type of Online Channel",
        stationDesc:
          "Examples include digital banner ads, Adwords, programmatic buy, etc.",
        detailsLabel: "Description of Online Channel",
        detailsDesc:
          "Please give a brief description of the online channel and be specific with the name of where the online channel will run. Please note if the ads are video, rich media/dynamic, or static/standard.",
        timingLabel: "Timing/Flights of Online Channel",
        timingDesc:
          "Please note the specific in market date/s of the online channel.",
        totalImpressionsLabel: "Guaranteed Total Impressions - Online",
        totalImpressionsDesc:
          "Please note the total number of guaranteed impressions.",
        sizeLabel: "Specs/Size - Online",
        sizeDesc: "Size of digital banner ad or type of media buy.",
      },
      social: {
        title: "Social",
        quantityLabel: "Quantity of Social Channel",
        quantityDesc:
          "Please note the total number of times the unique social channel will be in market.",
        stationLabel: "Type of Social Channel",
        stationDesc:
          "Examples include Facebook, Instagram, X (Twitter), YouTube, etc.",
        detailsLabel: "Description of Social Channel",
        detailsDesc:
          "Please give a brief description of the social media ad unit type./dynamic, or static/standard.",
        timingLabel: "Timing/Flights of Social Channel",
        timingDesc:
          "Please note the specific in market date/s of the social channel.",
        totalImpressionsLabel: "Guaranteed Total Impressions - Social",
        totalImpressionsDesc:
          "Please note the total number of guaranteed impressions.",
      },
      mediaLengths: {
        fifteen: "15s",
        thirty: "30s",
        sixty: "60s",
        other: "Other",
      },
      add: "Add a {mediaType} advertisement",
    },
    hospitality: {
      title: "7. Hospitality",
      tickets: {
        title: "Tickets",
        itemName: "Name",
        description: "Description",
        quantity: "Quantity",
        unitValue: "Unit value (dollars)",
        date: "Date",
        period: "Period [alternative]",
        seasonTickets: "Season tickets",
        annualPass: "Annual pass",
      },
      corporate: {
        title: "Corporate Box and Hosting",
        itemName: "Name",
        description: "Description",
        quantity: "Quantity",
        unitValue: "Unit value (dollars)",
        date: "Date",
        period: "Period [alternative]",
        seasonTickets: "Season tickets",
        annualPass: "Annual pass",
      },
      food: {
        title: "Food and Beverages",
        itemName: "Name",
        description: "Description",
        quantity: "Quantity",
        unitValue: "Unit value (dollars)",
        date: "Date",
        period: "Period [alternative]",
        seasonTickets: "Season tickets",
        annualPass: "Annual pass",
      },
      other: {
        title: "Other",
        itemName: "Name",
        description: "Description",
        quantity: "Quantity",
        unitValue: "Unit value (dollars)",
        date: "Date",
        period: "Period [alternative]",
        seasonTickets: "Season tickets",
        annualPass: "Annual pass",
      },
      add: "Add {hospitalityType}",
    },
    activation: {
      title: "Step 6: Activation",
      titlePublix: "Step 9: Activation",
      paidMedia: "Paid media",
      category: "Category",
      name: "Name",
      description: "Description",
      mediaBuy: "Media buy",
      cost: "Cost",
      add: "Add item",
      asset: "Asset",
      subTotal: "Subtotal",
      prodCost: "Production cost ",
      experiential: "Experiential and owned assets",
      offered: "Number of times offered",
      predominance: "Predominance",
      exposure: "Exposure",
    },
    metrics: {
      title: "Step 7: Custom Metrics",
      titlePublix: "Step 8: Custom Metrics",
      add: "Add metric",
      name: "Metric name",
      type: "Type of data",
      value: "Value",
      comment: "Comments",
      metric: "Metric",
      commentSaved: "Comment saved successfully",
      commentDeleted: "Comment deleted successfully",
      saveComment: "Save comment",
      newComment: "New comment",
      postedBy: "Posted by",
    },
    admin: {
      title: "Step 8: Misc",
      titlePublix: "Step 7: Misc",
    },
  },
};
