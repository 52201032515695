<template>
  <div class="brandContainer">
    <img
      v-if="displayLogo"
      :alt="displayName"
      :class="{ 'logo-svg': websiteLogo && websiteLogo.endsWith('.svg') }"
      :src="displayLogo"
      class="adminLogo"
    />
    <img
      v-else-if="invertedLogo"
      :alt="displayName"
      :class="{ 'logo-svg': websiteLogo && websiteLogo.endsWith('.svg') }"
      :src="invertedLogo"
      class="adminLogo"
    />
    <span v-else :style="{ color: textColor }" class="adminName">
      {{ displayName }}
    </span>
  </div>
</template>

<script>
import userData from "@/mixins/user-data";
import config from "@/config";

export default {
  name: "AdminBrand",
  mixins: [userData],
  data() {
    return {
      baseUrl: "",
      isLoaded: false,
    };
  },
  props: {
    defaultName: {
      type: String,
      required: false,
      default: "Elevent admin",
    },
    textColor: {
      type: String,
      required: false,
      default: config.defaults.adminTextHeaderColor,
    },
  },
  methods: {
    async fetchData() {
      this.isLoaded = false;

      if (this.currentUser.website_id != null) {
        await this.$store.dispatch("fetchStyles", this.currentUser.website_id);
      }

      this.isLoaded = true;
    },
  },
  computed: {
    websiteLogo() {
      return this.$store.state.styles.styles.header_admin_logo_url;
    },
    displayName() {
      return !this.isLoaded
        ? ""
        : this.$store.state.styles.styles.header_admin_name
          ? this.$store.state.styles.styles.header_admin_name
          : !this.displayLogo
            ? this.defaultName
            : "";
    },
    displayLogo() {
      return this.$store.state.styles.styles.header_admin_logo_url;
    },
    invertedLogo() {
      return this.$store.state.styles.styles.header_admin_inverted_logo_url;
    },
  },
  created() {
    this.baseUrl = config.api.host;
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.brandContainer {
  display: flex;
  align-items: center;
  height: 43px;
}

.adminLogo {
  max-width: 140px;
  vertical-align: middle;
  max-height: 43px;
}

.adminName {
  @include mx(16px);
  max-width: 500px;
}

.logo-svg {
  height: 35px;
}
</style>
