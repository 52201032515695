export default {
  title: "Utilisateurs",
  createNew: "Créer un utilisateur",
  filterPlaceholder: "Filtrer par nom ou courriel",
  name: "Nom",
  email: "Courriel",
  role: "Rôle",
  access: "Accès",
  confirmDeleteMessage: "Êtes-vous sûr de vouloir supprimer cet utilisateur?",
  createUser: "Créer un utilisateur",
  editUser: "Modifier un utilisateur",
  generatePassword: "Générer mot de passe",
  updatePassword: "Changer mot de passe",
  changePassword: "Changer le mot de passe",
  forceChangePassword:
    "Forcer le changement de mot de passe à la prochaine connexion",
  password: "Mot de passe",
  confirmPassword: "Confirmation du mot de passe",
  saveSuccessMessage: "Utilisateur enregistré",
  userIncompatibleSingleLoginLink:
    "Le lien de connexion unique ne peut être créé pour cet utilisateur.<br>L'utilisateur doit avoir accès à Brickroad pour que le lien fonctionne.",
  roles: {
    approver: "Vérificateur",
    siteadmin: "Administrateur de site",
    viewer: "Lecteur",
    manager: "Gestionnaire",
  },
  loginLinkCopied: "Votre lien de connexion a été copié avec succès.",
  loginLinkCopiedError: "Le lien n’a pu être copié, veuillez recommencer.",
  welcomeEmailSent: "Le message a été envoyé avec succès.",
  welcomeEmailSentError: "Le message n’a pu être envoyé, réessayez de nouveau.",
  exportingToCognito: "L'insertion de l'utilisateur dans Cognito est en cours.",
  exportCognitoSuccess: "Utilisateur copié dans Cognito avec succès.",
  exportCognitoFailed: {
    user_not_eligible:
      "L'utilisateur n'est pas admissible à l'ajout dans Cognito.",
    user_already_exists: "L'utilisateur existe déjà dans Cognito.",
    insertion_failed: "Échec de la copie de l'utilisateur dans Cognito.",
  },
};
