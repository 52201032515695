export default {
  title: "Gestion budgétaire",
  addNew: "Créer un poste budgétaire",
  budgetLabel: "Budget {period}",
  code: "Code budgétaire",
  accounts: "Compte",
  account: "Compte",
  attributedAmount: "Montant",
  percentOfTotal: "% du budget total",
  confirmDeleteMessage: "Voulez-vous effacer ce budget?",
  addBudget: "Ajouter un budget",
  editBudget: "Modifier le budget",
  addAccount: "Ajouter un compte",
  selectAccount: "Sélectionner un compte",
  name: "Nom",
  accountName: "Nom du compte",
  saveSuccessMessage: "Le budget a été sauvegardé.",
  duplicateBudgetMessage: "Un budget existe déjà pour cette période.",
  setup: "Création du budget",
  fiscalYear: "Exercice financier de l’entreprise",
  fiscalYearShort: "Année fiscale",
  starts: "Début",
  ends: "Fin",
  createBudget: "Créer un budget",
  budgetAccounts: "Comptes du budget",
  invalidPeriodError: "Veuillez sélectionner une période valide.",
  enterFiscalYear:
    "Afin de créer un budget, veuillez saisir le début de votre exercice fiscal dans la page {generalSettings}.",
};
