import _ from "lodash";
import shortid from "shortid";
import moment from "moment";
import api from "@/api";
import Validator from "@/providers/Validator";
import { calcBudgetAccountValue } from "@/utils/budget";

const getBudget = (props = {}) => {
  return _.assign(
    {
      key: shortid.generate(),
      budgetId: null,
    },
    props,
  );
};

const getAccountBudget = (props = {}) => {
  return _.assign(
    {
      id: null,
      key: shortid.generate(),
      budgetId: null,
      accountId: null,
      contributionType: null,
      financialAmount: null,
    },
    props,
  );
};

const getProduct = (props = {}) => {
  return _.assign(
    {
      id: null,
      key: shortid.generate(),
      accountKey: "",
      productId: null,
      value: null,
    },
    props,
  );
};

const validationRules = {
  "budgetAccounts.*.accountId": "required",
  "budgetAccounts.*.contributionType": "required",
  "budgetAccounts.*.financialAmount":
    "required_unless:budgetAccounts.*.contributionType,product",
  "budgetAccountProducts.*.productId": "required",
  "budgetAccountProducts.*.value": "required",
};

const defaultState = {
  isLoading: false,
  isEditing: false,
  websiteId: null,
  submissionId: null,
  sponsorshipBudgetId: null,
  numberOfYears: "",
  startDate: "",
  endDate: "",
  budgets: [],
  budgetAccounts: [],
  budgetAccountProducts: [],
  deletedItems: [],
  error: false,
};

export default {
  state: _.assign(defaultState, {}),

  getters: {
    getBudgetAccountsByBudget: (state) => (id) => {
      return _.filter(
        state.budgetAccounts,
        (o) => Number(o.budgetId) === Number(id),
      );
    },
    getProductsByAccount: (state) => (key) => {
      return _.filter(state.budgetAccountProducts, (o) => o.accountKey === key);
    },
    getSponsorshipBudgetValue: (state) => {
      let total = 0;

      for (const budget of state.budgets) {
        const budgetAccounts = _.filter(
          state.budgetAccounts,
          (o) => Number(o.budgetId) === Number(budget.budgetId),
        );

        for (const account of budgetAccounts) {
          const products = _.filter(
            state.budgetAccountProducts,
            (o) => o.accountKey === account.key,
          );
          total += calcBudgetAccountValue(account, products);
        }
      }

      return total;
    },
  },

  mutations: {
    TOGGLE_SPONSORSHIP_BUDGET_LOADING: (state, isLoading) => {
      state.isLoading = isLoading;
    },

    TOGGLE_SPONSORSHIP_BUDGET_EDIT_MODE: (state, isEditing) => {
      state.isEditing = isEditing;
    },

    RESET_SPONSORSHIP_BUDGET: (state) => {
      state.submissionId = null;
      state.sponsorshipBudgetId = null;
      state.numberOfYears = "";
      state.startDate = "";
      state.endDate = "";
      state.budgets = [];
      state.budgetAccounts = [];
      state.budgetAccountProducts = [];
      state.deletedItems = [];
    },

    SET_SPONSORSHIP_BUDGET_SUBMISSION_ID: (
      state,
      { websiteId, submissionId },
    ) => {
      state.websiteId = websiteId;
      state.submissionId = submissionId;
    },

    SET_SPONSORSHIP_BUDGET_START_DATE: (state, date) => {
      state.startDate = date;
    },

    SET_SPONSORSHIP_BUDGET_END_DATE: (state, date) => {
      state.endDate = date;
    },

    SET_SPONSORSHIP_BUDGET_NUMBER_OF_YEARS: (state, years) => {
      state.numberOfYears = years;
    },

    SET_SPONSORSHIP_BUDGETS: (state, budgets) => {
      state.budgets = budgets;
    },

    SET_SPONSORSHIP_BUDGET_ACCOUNTS: (state, budgetAccounts) => {
      state.budgetAccounts = budgetAccounts;
    },

    SPONSORSHIP_BUDGET_CREATED: (state, budget) => {
      state.sponsorshipBudgetId = budget.id;
    },

    ADD_SPONSORSHIP_BUDGET_ACCOUNT: (state, budgetId) => {
      state.budgetAccounts.push(
        getAccountBudget({
          budgetId,
        }),
      );
    },

    UPDATE_SPONSORSHIP_BUDGET_ACCOUNT: (state, object) => {
      const match = _.find(state.budgetAccounts, { key: object.key });
      const index = state.budgetAccounts.indexOf(match);
      state.budgetAccounts.splice(index, 1, object);
    },

    DELETE_SPONSORSHIP_BUDGET_ACCOUNT: (state, key) => {
      const match = _.find(state.budgetAccounts, { key });
      const index = state.budgetAccounts.indexOf(match);
      state.budgetAccounts.splice(index, 1);

      if (match.id) {
        state.deletedItems.push({
          type: "sponsorshipBudgetAccounts",
          id: match.id,
        });
      }
    },

    ADD_SPONSORSHIP_BUDGET_ACCOUNT_PRODUCT: (state, accountKey) => {
      state.budgetAccountProducts.push(
        getProduct({
          accountKey,
        }),
      );
    },

    UPDATE_SPONSORSHIP_BUDGET_ACCOUNT_PRODUCT: (state, object) => {
      const match = _.find(state.budgetAccountProducts, { key: object.key });
      const index = state.budgetAccountProducts.indexOf(match);
      state.budgetAccountProducts.splice(index, 1, object);
    },

    DELETE_SPONSORSHIP_BUDGET_ACCOUNT_PRODUCT: (state, key) => {
      const match = _.find(state.budgetAccountProducts, { key });
      const index = state.budgetAccountProducts.indexOf(match);
      state.budgetAccountProducts.splice(index, 1);

      if (match.id) {
        state.deletedItems.push({
          type: "sponsorshipBudgetAccountProducts",
          id: match.id,
        });
      }
    },

    SPONSORSHIP_BUDGET_FETCHED: (
      state,
      { id, numberOfYears, startDate, endDate },
    ) => {
      state.sponsorshipBudgetId = id;
      state.numberOfYears = numberOfYears;
      state.startDate = startDate;
      state.endDate = endDate;
    },

    PUSH_SPONSORSHIP_BUDGET_ACCOUNT: (state, budgetAccount) => {
      state.budgetAccounts.push(budgetAccount);
    },

    PUSH_SPONSORSHIP_BUDGET_ACCOUNT_PRODUCT: (state, product) => {
      state.budgetAccountProducts.push(product);
    },

    REMOVE_SPONSORSHIP_BUDGET_DELETED_ITEM: (state, item) => {
      const index = state.deletedItems.indexOf(item);
      if (index > -1) {
        state.deletedItems.splice(index, 1);
      }
    },

    SET_SPONSORSHIP_BUDGET_ERROR: (state, status) => {
      state.error = status;
    },
  },

  actions: {
    async initSponsorshipYearlyBudgets({ state, dispatch }, siteId) {
      if (moment(state.startDate).isAfter(moment(state.endDate))) {
        dispatch("setSponsorshipBudgetYears", []);
        return;
      }

      const matchingBudgets = [];
      state.isLoading = true;
      const budgets = await api.budgets.query({ websiteId: siteId });
      state.isLoading = false;

      if (budgets) {
        const sortedBudgets = _.orderBy(budgets, ["start_date"], ["asc"]);

        for (let i = 0; i < sortedBudgets.length; i++) {
          const budget = sortedBudgets[i];

          if (
            moment(state.startDate).isSameOrBefore(budget.end_date) &&
            moment(state.endDate).isSameOrAfter(budget.start_date)
          ) {
            matchingBudgets.push(budget);
            // if the agreement lasts only a year, only create a budget for one year
            if (parseInt(state.numberOfYears) === 1) {
              break;
            }
          }
        }
      }

      dispatch("setSponsorshipBudgetYears", matchingBudgets);
    },

    setSponsorshipBudgetYears({ state, commit }, budgets = []) {
      const budgetYears = [];

      budgets.forEach((budget) => {
        budgetYears.push(
          getBudget({
            budgetId: budget.id,
          }),
        );
      });

      const budgetAccountIds = budgetYears.map((acc) => acc.budgetId);
      const budgetAccounts = state.budgetAccounts.filter((b) => {
        return budgetAccountIds.includes(b.budgetId);
      });

      commit("SET_SPONSORSHIP_BUDGETS", budgetYears);
      commit("SET_SPONSORSHIP_BUDGET_ACCOUNTS", budgetAccounts);
    },

    validateSponsorshipBudget({ state, commit }) {
      const validation = new Validator(state, validationRules);

      if (validation.fails()) {
        commit("SET_SPONSORSHIP_BUDGET_ERROR", true);
        return false;
      }

      commit("SET_SPONSORSHIP_BUDGET_ERROR", false);
      return true;
    },

    async saveSponsorshipBudget({ state, getters, dispatch }) {
      if (
        state.numberOfYears &&
        state.startDate &&
        state.endDate &&
        state.budgets &&
        state.budgetAccounts
      ) {
        const totalValue = getters.getSponsorshipBudgetValue;

        await dispatch("saveSponsorshipBudgetData", { totalValue });
        await dispatch("saveSponsorshipBudgetAccounts");
        await dispatch("saveSponsorshipBudgetAccountProducts");
        await dispatch("processSponsorshipBudgetDeletedItems");
      }
    },

    async findExistingSponsorshipBudget(
      { commit },
      { websiteId, submissionId },
    ) {
      commit("TOGGLE_SPONSORSHIP_BUDGET_LOADING", true);
      commit("TOGGLE_SPONSORSHIP_BUDGET_EDIT_MODE", false);
      commit("RESET_SPONSORSHIP_BUDGET");
      commit("SET_SPONSORSHIP_BUDGET_SUBMISSION_ID", {
        websiteId,
        submissionId,
      });

      let budget = null;

      try {
        budget = await api.sponsorshipBudgets.getBySubmission({
          websiteId,
          submissionId,
        });

        if (budget) {
          commit("SPONSORSHIP_BUDGET_FETCHED", {
            id: budget.id,
            numberOfYears: budget.number_of_years,
            startDate: budget.start_date,
            endDate: budget.end_date,
          });

          if (budget.accounts && budget.accounts.length) {
            budget.accounts.forEach((budgetAccount) => {
              const account = getAccountBudget({
                id: budgetAccount.id,
                budgetId: budgetAccount.budget_id,
                accountId: budgetAccount.account_id,
                contributionType: budgetAccount.contribution_type,
                financialAmount: budgetAccount.financial_amount,
              });

              commit("PUSH_SPONSORSHIP_BUDGET_ACCOUNT", account);

              if (budgetAccount.products && budgetAccount.products.length) {
                budgetAccount.products.forEach((product) => {
                  commit(
                    "PUSH_SPONSORSHIP_BUDGET_ACCOUNT_PRODUCT",
                    getProduct({
                      id: product.id,
                      accountKey: account.key,
                      productId: product.product_id,
                      value: product.value,
                    }),
                  );
                });
              }
            });
          }
        }
      } catch {
        // ...
      }

      if (!budget) {
        commit("TOGGLE_SPONSORSHIP_BUDGET_EDIT_MODE", true);
      }

      commit("TOGGLE_SPONSORSHIP_BUDGET_LOADING", false);
    },

    async saveSponsorshipBudgetData({ state, commit }, { totalValue }) {
      const data = {
        websiteId: state.websiteId,
        submission_id: state.submissionId,
        number_of_years: state.numberOfYears,
        start_date: state.startDate,
        end_date: state.endDate,
        attributed_amount: totalValue,
      };

      if (!state.sponsorshipBudgetId) {
        const budget = await api.sponsorshipBudgets.create(data);
        commit("SPONSORSHIP_BUDGET_CREATED", budget);
      } else {
        const budget = await api.sponsorshipBudgets.update({
          ...data,
          id: state.sponsorshipBudgetId,
        });

        commit("SPONSORSHIP_BUDGET_UPDATED", budget);
      }
    },

    async deleteSponsorshipBudget({ state, commit }) {
      if (state.sponsorshipBudgetId) {
        await api.sponsorshipBudgets.delete({
          websiteId: state.websiteId,
          id: state.sponsorshipBudgetId,
        });
        commit("RESET_SPONSORSHIP_BUDGET");
        commit("TOGGLE_SPONSORSHIP_BUDGET_EDIT_MODE", true);
      }
    },

    async saveSponsorshipBudgetAccounts({ state, commit, dispatch }) {
      const budgetAccounts = [...state.budgetAccounts];

      for (let i = 0; i < budgetAccounts.length; i += 1) {
        const budgetAccount = budgetAccounts[i];

        // Clear financial amount or products depending on contribution type
        // Set financial amount = 0 if contribution type is 'product'
        const financialAmount =
          budgetAccount.contributionType === "product"
            ? 0
            : budgetAccount.financialAmount;

        // If contribution type is 'financial', clear all products for this account
        if (budgetAccount.contributionType === "financial") {
          dispatch("deleteProductsByAccount", budgetAccount.key);
        }

        const data = {
          websiteId: state.websiteId,
          sponsorship_budget_id: state.sponsorshipBudgetId,
          account_id: budgetAccount.accountId,
          budget_id: budgetAccount.budgetId,
          contribution_type: budgetAccount.contributionType,
          financial_amount: financialAmount,
        };

        if (!budgetAccount.id) {
          const createdAccount =
            await api.sponsorshipBudgetAccounts.create(data);

          commit("UPDATE_SPONSORSHIP_BUDGET_ACCOUNT", {
            ...budgetAccount,
            id: createdAccount.id,
          });
        } else {
          await api.sponsorshipBudgetAccounts.update({
            ...data,
            id: budgetAccount.id,
          });
        }
      }
    },

    async saveSponsorshipBudgetAccountProducts({ state, commit }) {
      const accountProducts = [...state.budgetAccountProducts];

      for (let i = 0; i < accountProducts.length; i += 1) {
        const budgetAccountProduct = accountProducts[i];
        const budgetAccount = _.find(state.budgetAccounts, {
          key: budgetAccountProduct.accountKey,
        });

        if (budgetAccount) {
          const data = {
            websiteId: state.websiteId,
            sponsorship_budget_account_id: budgetAccount.id,
            product_id: budgetAccountProduct.productId,
            value: budgetAccountProduct.value,
          };

          if (!budgetAccountProduct.id) {
            const createdProduct =
              await api.sponsorshipBudgetAccountProducts.create(data);

            commit("UPDATE_SPONSORSHIP_BUDGET_ACCOUNT_PRODUCT", {
              ...budgetAccountProduct,
              id: createdProduct.id,
            });
          } else {
            await api.sponsorshipBudgetAccountProducts.update({
              ...data,
              id: budgetAccountProduct.id,
            });
          }
        }
      }
    },

    async processSponsorshipBudgetDeletedItems({ state, commit }) {
      const deletedItems = [...state.deletedItems];

      for (let i = 0; i < deletedItems.length; i += 1) {
        const deletedItem = deletedItems[i];

        await api[deletedItem.type].delete({
          websiteId: state.websiteId,
          id: deletedItem.id,
        });

        commit("REMOVE_SPONSORSHIP_BUDGET_DELETED_ITEM", deletedItem);
      }
    },

    deleteProductsByAccount({ state, commit }, accountKey) {
      const productsToDelete = _.filter(state.budgetAccountProducts, {
        accountKey,
      });

      if (productsToDelete) {
        for (const product of productsToDelete) {
          commit("DELETE_SPONSORSHIP_BUDGET_ACCOUNT_PRODUCT", product.key);
        }
      }
    },
  },
};
