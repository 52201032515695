export default {
  save: "Enregistrer",
  cancel: "Annuler",
  yes: "Oui",
  no: "Non",
  inPart: "En partie",
  delete: "Effacer",
  confirmDeletion: "Confirmer la suppression",
  select: "Sélectionner",
  selectWithDots: "Sélectionner...",
  loading: "Chargement",
  print: "Impression",
  download: "Télécharger",
  quickFilter: "Filtre rapide :",
  back: "Retour",
  previous: "Précédent",
  next: "Suivant",
  continue: "Continuer",
  saveAndContinue: "Sauvegarder et continuer",
  saveAndQuit: "Sauvegarder et quitter",
  saveAndFinish: "Sauvegarder et terminer",
  send: "Envoyer",
  submit: "Soumettre",
  close: "Fermer",
  assign: "Assigner",
  search: "Rechercher",
  apply: "Appliquer",
  clear: "Vider",
  add: "Ajouter",
  edit: "Modifier",
  leavePage: "Quitter la page",
  choose: "Choisir...",
  unsavedChanges:
    "Vous avez des changements non sauvegardés. Quitter sans sauvegarder?",
  or: "Ou",
  on: "Le",
  none: "Aucun",
  other: "Autre",
  total: "Total",
  required: "Requis",
  estimated: "Estimé",
  realData: "Réel",
  linkCopied: "Le lien a été copié dans le presse-papier!",
  linkCopiedError: "Le lien n'a pas pu être copié.",
  from: "De",
  to: "À",
  brand: "notre marque",
  brandCharacteristics: {
    title: "Caractéristiques de la marque",
    brandSincere: "La marque est sincère",
    brandExciting: "La marque est excitante",
    brandCompetent: "La marque est compétente",
    brandSophisticated: "La marque est sophistiquée",
    brandSolid: "La marque est robuste",
  },
  scales: {
    totallyInaccurate: "Non caractéristique de la marque",
    totallyAccurate: "Très caractéristique de la marque",
    disagree: "Pas du tout d'accord",
    agree: "Tout à fait d'accord",
    lowAssociation: "Faible association",
    highAssociation: "Forte association",
    unlikely: "Très improbable",
    likely: "Très probable",
    notImportant: "Aucune importance",
    important: "Très grande importance",
    notSatisfied: "Pas du tout satisfait",
    verySatisfied: "Très satisfait",
    lowRegard: "Perception très négative",
    highRegard: "Perception très positive",
    notAtAll: "Pas du tout",
    entirely: "Dans une mesure extrêmement forte",
    low: "Basse",
    high: "Haute",
  },
  geo: {
    local: "Locale",
    regional: "Régionale",
    provincial: "Provinciale",
    national: "Nationale",
    international: "Internationale",
    northAmerica: "Amérique du Nord",
    southAmerica: "Amérique du Sud",
    asia: "Asie",
    europe: "Europe",
  },
  gender: {
    label: "Sexe",
    female: "Femme",
    male: "Homme",
  },
  age: {
    label: "Âge",
    cat1: "Moins de 18 ans",
    cat2: "De 18 à 24 ans",
    cat3: "De 25 à 34 ans",
    cat4: "De 35 à 44 ans",
    cat5: "De 45 à 54 ans",
    cat6: "De 55 à 64 ans",
    cat7: "65 ans et plus",
  },
  household: {
    label: "Type de ménage",
    cat1: "Couples sans enfants",
    cat2: "Couples avec enfants",
    cat3: "Familles monoparentales",
    cat4: "Personnes seules",
  },
  education: {
    label: "Niveau de scolarité complété",
    cat1: "Aucun diplôme",
    cat2: "Secondaire",
    cat3: "Apprenti/école de métiers",
    cat4: "Collégial",
    cat5: "Universitaire premier cycle (baccalauréat, certificat)",
    cat6: "Universitaire deuxième et troisième cycles (maîtrise, doctorat)",
  },
  income: {
    label: "Revenu familial",
    cat1: "Moins de 50 000 $",
    cat2: "Entre 50 000 $ et 79 999 $",
    cat3: "Entre 80 000 $ et 99 999 $",
    cat4: "Entre 100 000 $ et 150 000 $",
    cat5: "Plus de 150 000 $",
  },
  audience: {
    onsite: "Sur le site",
    tv: "Audience télévisuelle",
    webcast: "Diffusion web",
    digital: "Numérique",
  },
  origin: {
    local: "Local",
    regional: "Provincial (dans la même province)",
    national: "National (dans une autre province canadienne)",
    international:
      "International (dans un autre pays, incluant les États-Unis)",
  },
  ethnicity: {
    cat1: "Autochtone ou natif de l'Alaska",
    cat2: "Asiatique",
    cat3: "Noir ou Afro-Américain",
    cat4: "Hispanique ou Latino",
    cat5: "Hawaïen de souche ou autre insulaire du Pacifique",
    cat6: "Blanc",
    cat7: "Autre",
    cat8: "Préfère ne pas répondre",
  },
};
