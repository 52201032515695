<template>
  <div class="placeholder-content" :style="applyStyle()">
    <div class="placeholder-content_item"></div>
    <div class="placeholder-content_item"></div>
    <div class="placeholder-content_item"></div>
    <div class="placeholder-content_item"></div>
    <div class="placeholder-content_item"></div>
    <div class="placeholder-content_item"></div>
    <div class="placeholder-content_item"></div>
    <div class="placeholder-content_item"></div>
    <div class="placeholder-content_item"></div>
    <div class="placeholder-content_item"></div>
    <div class="placeholder-content_item"></div>
  </div>
</template>

<script>
export default {
  name: "Placeholder",
  props: {
    width: {
      type: String,
      required: false,
      default() {
        return "100%";
      },
    },
    height: {
      type: String,
      required: false,
      default() {
        return "75px";
      },
    },
    animationDuration: {
      type: String,
      required: false,
      default() {
        return "1s";
      },
    },
    backgroundSize: {
      type: String,
      required: false,
      default() {
        return "1300px";
      },
    },
  },
  methods: {
    applyStyle() {
      return {
        width: this.width,
        height: this.height,
        animationDuration: this.animationDuration,
        backgroundSize: this.backgroundSize,
      };
    },
  },
};
</script>

<style lang="scss">
.placeholder-content {
  overflow: hidden;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
}
.placeholder-content_item {
  width: 100%;
  position: absolute;
  z-index: 2;
}
.placeholder-content_item:after,
.placeholder-content_item:before {
  width: inherit;
  height: inherit;
  content: "";
  position: absolute;
}

@-webkit-keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}

@-moz-keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}

@-ms-keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}

@-o-keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
</style>
