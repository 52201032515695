import api from "../api";
import config from "../config";

export default {
  namespaced: true,

  state: {
    websiteId: null,
    website: {},
    styles: {},
    stylesFetched: false,
    stylesLoading: false,
    appSettings: {
      english_is_active: true,
      french_is_active: true,
    },
  },

  getters: {
    getStyleSetting:
      (state) =>
      (key, defaultValue = null) => {
        return state.styles && state.styles[key]
          ? state.styles[key]
          : defaultValue;
      },

    getAppSettings: (state) => () => {
      return state.appSettings;
    },
  },

  mutations: {
    SET_APP_SETTINGS: (state, appSettings) => {
      state.appSettings = appSettings;
    },

    SET_WEBSITE_ID: (state, id) => {
      state.websiteId = id;
    },

    SET_WEBSITE: (state, site) => {
      state.website = site;
    },

    STYLES_START_FETCHING: (state) => {
      state.stylesLoading = true;
    },

    GET_STYLES: (state, payload) => {
      state.styles = payload;
      state.stylesFetched = true;
      state.stylesLoading = false;
    },
  },

  actions: {
    async fetchSite({ commit }, { subdomain, hostname }) {
      let site = {};
      const shouldUseSubdomain = hostname.indexOf(`.${config.app.domain}`) > -1;

      if (shouldUseSubdomain) {
        site = await api.getSiteBySubdomain(subdomain);
      } else {
        site = await api.getSiteByHostname(hostname);
      }

      const appSettings = {
        english_is_active: true,
        french_is_active: true,
      };

      if (site.settings) {
        site.settings.forEach((setting) => {
          appSettings[setting.key] = setting.value === "1";
        });
      }

      commit("SET_WEBSITE", site);
      commit("SET_APP_SETTINGS", appSettings);
      commit("SET_WEBSITE_ID", site.id);
      return site;
    },

    getPublicStyles({ commit }, payload) {
      commit("STYLES_START_FETCHING");

      return api.getPublicStyles(payload).then((fields) => {
        commit("GET_STYLES", fields);
      });
    },
  },
};
