import _ from "lodash";
import api from "../../api";
import moment from "moment";

export default {
  state: {
    items: [],
    loading: false,
    fetched: false,
  },

  getters: {
    getBudgetById: (state) => (id) => {
      return _.find(state.items, (o) => Number(o.id) === Number(id));
    },

    getBudgetYears: (state) => {
      return _.map(state.items, (o) => ({
        startDate: moment(o.start_date).year(),
      }));
    },
  },

  mutations: {
    BUDGETS_START_FETCHING: (state) => {
      state.loading = true;
    },

    BUDGETS_FETCHED: (state, items) => {
      state.items = items;
      state.fetched = true;
      state.loading = false;
    },

    BUDGET_CREATED: (state, item) => {
      state.items.push(item);
    },

    BUDGET_UPDATED: (state, item) => {
      const object = _.find(state.items, { id: item.id });

      if (object) {
        const index = state.items.indexOf(object);
        state.items.splice(index, 1, item);
      }
    },

    BUDGET_DELETED: (state, id) => {
      const object = _.find(state.items, { id });

      if (object) {
        const index = state.items.indexOf(object);
        state.items.splice(index, 1);
      }
    },
  },

  actions: {
    async fetchBudgets({ state, commit }, query) {
      if (!state.loading) {
        commit("BUDGETS_START_FETCHING");
        const items = await api.budgets.query(query);
        commit("BUDGETS_FETCHED", items);
      }
    },

    async createBudget({ commit }, budget) {
      const createdBudget = await api.budgets.create(budget);
      commit("BUDGET_CREATED", createdBudget);
      return createdBudget;
    },

    async updateBudget({ commit }, budget) {
      const updatedBudget = await api.budgets.update(budget);
      commit("BUDGET_UPDATED", updatedBudget);
      return updatedBudget;
    },

    async deleteBudget({ commit }, { websiteId, id }) {
      await api.budgets.delete({ websiteId, id });
      commit("BUDGET_DELETED", id);
    },
  },
};
