export default {
  computed: {
    isUserAuthenticated() {
      return this.$store.state.auth.authenticated;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    websiteId() {
      return this.currentUser.website_id;
    },
    userWebsite() {
      if (this.currentUser.website === undefined) {
        return null;
      }
      return JSON.parse(this.currentUser.website);
    },
    userLicenseId() {
      let website = this.userWebsite;
      if (!website) {
        return "";
      }
      return website.license_id;
    },
  },
  methods: {
    isUserCognitoExportable(user) {
      if (!user.website) {
        return false;
      }
      return (
        user.website.login_type === "cognito" ||
        user.website.login_type === "cognito_and_local"
      );
    },
    isPasswordReadOnly(website) {
      if (website) {
        return website.login_type === "cognito_with_identity_provider";
      }
    },
  },
};
