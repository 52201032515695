export default {
  imageInput: {
    uploadFile: "Télécharger un fichier",
    view: "Visualiser",
    delete: "Effacer",
  },
  budgetSelector: {
    select: "Sélectionnez l’exercice financier",
  },
  productSelector: {
    addNew: "Ajouter",
  },
  editProductList: {
    rename: "Renommer",
    delete: "Effacer",
    confirmDeleteMessage: "Êtes-vous sûr de vouloir supprimer ce produit?",
    emptyList: "Aucun produit n'a été enregistré",
  },
};
