export default {
  title: "Gestion des groupes",
  titleGroups: "Groupes",
  titleGroupRelations: "Relations avec les groupes",
  createNew: "Ajout d’un groupe",
  createNewRelation: "Ajouter une nouvelle relation de groupe",
  filterByName: "Filtrer par nom",
  name: "Nom",
  description: "Description",
  confirmDeleteMessage: "Êtes-vous sûr de vouloir supprimer ce groupe?",
  createGroup: "Créer un groupe",
  createGroupRelation: "Créer une relation de groupe",
  editGroup: "Modifier un groupe",
  editGroupRelation: "Modifier une relation de groupe",
  nameInputLabel: "Nom du groupe",
  descriptionInputLabel: "Description (optionnel)",
  saveSuccessMessage: "Groupe enregistré.",
  relationGroupNameLabel: "Group name",
  relationTypeNameLabel: "Type de relation",
  relationNameLabel: "Relation",
};
