export default {
  validationError: "Veuillez remplir tous les champs requis et réessayer.",
  validationErrorSection:
    "Veuillez remplir tous les champs requis dans les sections suivantes : {sectionNames}.",
  saveError:
    "Une erreur s’est produite lors de l’enregistrement. Veuillez réessayer.",
  pageLoadingError:
    "Une erreur s’est produite lors du chargement de la page. Veuillez réessayer.",
  internalError: "Une erreur s’est produite. Veuillez réessayer.",
  forbidden: "Vous n’êtes pas autorisé à effectuer cette action.",
  requiredField: "Champ requis",
  invalidField: "Ce champ est invalide.",
  allRequired: "Tous les champs de cette question sont obligatoires",
  percentTotal: "Le total doit être entre 0 et 100%",
  failedToLoadUserPreference:
    "Échec de chargement des préférences utilisateur.",
};
