export default {
  save: "Save",
  cancel: "Cancel",
  yes: "Yes",
  no: "No",
  inPart: "In part",
  delete: "Delete",
  confirmDeletion: "Confirm deletion",
  select: "Select",
  selectWithDots: "Select...",
  loading: "Loading",
  print: "Print",
  download: "Download",
  quickFilter: "Quick Filter:",
  back: "Back",
  previous: "Previous",
  next: "Next",
  continue: "Continue",
  saveAndContinue: "Save and continue",
  saveAndQuit: "Save and quit",
  saveAndFinish: "Save and finish",
  send: "Send",
  submit: "Submit",
  close: "Close",
  assign: "Assign",
  search: "Search",
  apply: "Apply",
  clear: "Clear",
  add: "Add",
  edit: "Edit",
  choose: "Choose...",
  leavePage: "Leave page",
  unsavedChanges: "You have unsaved changes. Leave and discard changes?",
  or: "Or",
  on: "On",
  none: "None",
  other: "Other",
  total: "Total",
  required: "Required",
  notApplicable: "Not applicable",
  estimated: "Estimated",
  realData: "Actual",
  linkCopied: "The link was successfully copied!",
  linkCopiedError: "The link could not be copied.",
  from: "From",
  to: "To",
  brand: "our brand",
  brandCharacteristics: {
    title: "Brand characteristics",
    brandSincere: "The brand is sincere",
    brandExciting: "The brand is exciting",
    brandCompetent: "The brand is competent",
    brandSophisticated: "The brand is sophisticated",
    brandSolid: "The brand is solid",
  },
  scales: {
    totallyInaccurate: "Totally inaccurately",
    totallyAccurate: "Totally accurate",
    disagree: "Strongly disagree",
    agree: "Strongly agree",
    lowAssociation: "Low association",
    highAssociation: "High association",
    unlikely: "Very unlikely",
    likely: "Very likely",
    notImportant: "Not at all important",
    important: "Very important",
    notSatisfied: "Not satisfied",
    verySatisfied: "Very satisfied",
    lowRegard: "Very low regard",
    highRegard: "Very high regard",
    notAtAll: "Not at all",
    entirely: "Entirely",
    low: "Low",
    high: "High",
  },
  geo: {
    local: "Local",
    regional: "Regional",
    provincial: "In-State/Provincial",
    national: "National",
    international: "International",
    northAmerica: "North American",
    southAmerica: "South American",
    asia: "Asian",
    europe: "European",
  },
  gender: {
    label: "Gender",
    female: "Female",
    male: "Male",
  },
  age: {
    label: "Age",
    cat1: "Under 18",
    cat2: "18 to 24 years",
    cat3: "25 to 34 years",
    cat4: "35 to 44 years",
    cat5: "45 to 54 years",
    cat6: "55 to 64 years",
    cat7: "65 years or older",
  },
  audience: {
    onsite: "On-site",
    tv: "TV",
    webcast: "Webcast",
    digital: "Digital",
  },
  origin: {
    local: "Local",
    regional: "Regional (same state or province)",
    national: "National (multiple states or province)",
    international: "International (other countries)",
  },
  household: {
    label: "Household",
    cat1: "Couples without Children",
    cat2: "Couples with Children",
    cat3: "Single-parent Households",
    cat4: "One-person Households",
  },
  education: {
    label: "Education",
    cat1: "No Diploma",
    cat2: "High School Diploma",
    cat3: "Apprenticeship or Trade Certificate",
    cat4: "College Diploma",
    cat5: "Undergraduate Degree (bachelor's, certificate)",
    cat6: "Graduate Degree (master's, doctorate)",
  },
  income: {
    label: "Family Income",
    cat1: "Less than $50,000",
    cat2: "$50,000 to $79,999",
    cat3: "$80,000 to $99,999",
    cat4: "$100,000 to $150,000",
    cat5: "Over $150,000",
  },
  ethnicity: {
    cat1: "American Indian or Alaska Native",
    cat2: "Asian",
    cat3: "Black or African American",
    cat4: "Hispanic or Latino",
    cat5: "Native Hawaiian or Other Pacific Islander",
    cat6: "White",
    cat7: "Other",
    cat8: "Prefer not to answer",
  },
  metrics: {
    amount: "$",
    percent: "%",
    decimal: "Decimal Number",
  },
};
