export default {
  computed: {
    displaySwitchLang() {
      const settings = this.$store.getters["publicSite/getAppSettings"]();
      return settings.english_is_active && settings.french_is_active;
    },
  },
  methods: {
    forceLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("user_lang", lang);
    },
    setAppSettings() {
      const settings = this.$store.getters["publicSite/getAppSettings"]();

      if (!settings.english_is_active || !settings.french_is_active) {
        this.forceLang(settings.english_is_active ? "en" : "fr");
      }
    },
  },
};
