export default {
  dashboard: "Dashboard",
  questions: "Questions",
  requests: "Requests",
  emailTemplates: "Email contents",
  profileFields: "Profile fields",
  pages: "Pages",
  styles: "Styles",
  reports: "Budget reports",
  actualVsBudget: "Budget vs Actual",
  comparative: "Comparative",
  budgets: "Budget Management",
  settings: "Settings",
  generalSettings: "General Settings",
  groups: "Groups",
  users: "Users",
};
