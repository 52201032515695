import api from "./api";
import app from "./app";
import auth from "./auth";
import defaults from "./defaults";

export default {
  api,
  app,
  auth,
  defaults,
};
