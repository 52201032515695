import Vue from "vue";
import ModalMixin from "./mixins/modal";

const vm = new Vue({
  data: {
    isVisible: false,
    component: "",
    data: {},
    // TODO: handle on complete callback
    onDone: null,
  },
});

const VueModal = {
  state: vm.$data,
  options: {},

  open(component, data = {}, callback = null) {
    if (typeof component.mixins === "undefined") {
      component.mixins = [];
    }
    component.mixins.push(ModalMixin);

    this.state.isVisible = true;
    this.state.component = component;
    this.state.data = data;
    this.state.onDone = callback;

    return this;
  },

  close() {
    this.state.isVisible = false;
  },

  detach() {
    this.state.component = "";
    this.state.data = {};
    this.state.onDone = null;
  },

  triggerOnDone(data) {
    if (typeof this.state.onDone === "function") {
      this.state.onDone(data);
    }
  },

  done(callback) {
    this.state.onDone = callback;
  },
};

export default {
  install: function (Vue, options) {
    VueModal.options = options;
    Vue.prototype.$modal = VueModal;
    Vue.$modal = VueModal;
  },
};
