export default [
  {
    path: "",
    redirect: "dashboard",
  },
  {
    path: "login",
    name: "admin.login",
    component: () =>
      import(/* webpackChunkName: "admin-login" */ "@/views/auth/Login.vue"),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "token-login/:token",
    name: "admin.tokenlogin",
    component: () =>
      import(
        /* webpackChunkName: "token-login" */ "@/views/auth/TokenLogin.vue"
      ),
  },
  {
    path: "twofactor",
    name: "admin.twofactor",
    component: () =>
      import(
        /* webpackChunkName: "admin-twofactor" */ "@/views/auth/TwoFactor.vue"
      ),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "password/forgot",
    name: "password.forgot",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "@/views/auth/ForgotPassword.vue"
      ),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "password/reset/:token",
    name: "password.reset",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "@/views/auth/ResetPassword.vue"
      ),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "dashboard",
    name: "dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/views/siteadmin/Dashboard.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "dashboard",
    },
  },
  {
    path: "password/change",
    name: "password.change",
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ "@/views/auth/ChangePassword.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "questions",
    name: "questions",
    component: () =>
      import(
        /* webpackChunkName: "questions" */ "@/views/siteadmin/questions/Questions.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "questions",
    },
  },
  {
    path: "questions/create",
    name: "questions.create",
    component: () =>
      import(
        /* webpackChunkName: "create-edit-questions" */ "@/views/siteadmin/questions/CreateEdit.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "questions",
      breadcrumb: [
        {
          text: "admin.questions.title",
          to: { name: "questions" },
        },
        { text: "admin.questions.createQuestion", active: true },
      ],
    },
  },
  {
    path: "questions/edit/:id",
    name: "questions.edit",
    component: () =>
      import(
        /* webpackChunkName: "create-edit-questions" */ "@/views/siteadmin/questions/CreateEdit.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "questions",
      breadcrumb: [
        {
          text: "admin.questions.title",
          to: { name: "questions" },
        },
        { text: "admin.questions.editQuestion", active: true },
      ],
    },
  },
  {
    path: "questions/:questionId/choices/create",
    name: "questions.choices.create",
    component: () =>
      import(
        /* webpackChunkName: "create-edit-choice" */ "@/views/siteadmin/questions/CreateEditChoice.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "questions",
      breadcrumb: [
        {
          text: "admin.questions.title",
          to: { name: "questions" },
        },
        {
          text: "admin.questions.editQuestion",
          to: { name: "questions.create" },
        },
        { text: "admin.questions.createChoice", active: true },
      ],
    },
  },
  {
    path: "questions/:questionId/choices/edit/:choiceId",
    name: "questions.choices.edit",
    component: () =>
      import(
        /* webpackChunkName: "create-edit-choice" */ "@/views/siteadmin/questions/CreateEditChoice.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "questions",
      breadcrumb: [
        {
          text: "admin.questions.title",
          to: { name: "questions" },
        },
        {
          text: "admin.questions.editQuestion",
          to: { path: "/admin/questions/edit/:questionId" },
        },
        { text: "admin.questions.editChoice", active: true },
      ],
    },
    beforeEnter(to, _, next) {
      to.meta.breadcrumb[1].to.path = `/admin/questions/edit/${to.params.questionId}`;
      next();
    },
  },
  {
    path: "requests",
    name: "requests",
    component: () =>
      import(
        /* webpackChunkName: "requests" */ "@/views/siteadmin/requests/Requests.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "requests",
    },
  },
  {
    path: "requests/status/:status",
    name: "requests.byStatus",
    component: () =>
      import(
        /* webpackChunkName: "requests" */ "@/views/siteadmin/requests/Requests.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "requests",
    },
  },
  {
    path: "requests/auto-rejected",
    name: "requests.autoRejected",
    component: () => import("@/views/siteadmin/requests/Requests.vue"),
    meta: {
      requiresAuth: true,
      activeMenu: "requests",
    },
  },
  {
    path: "requests/last-30-Days",
    name: "requests.last30Days",
    component: () => import("@/views/siteadmin/requests/Requests.vue"),
    meta: {
      requiresAuth: true,
      activeMenu: "requests",
    },
  },
  {
    path: "requests/show/:requestId",
    name: "requests.show",
    component: () =>
      import(
        /* webpackChunkName: "show-requests" */ "@/views/siteadmin/requests/Show.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "requests",
      // The Request page has a different breadcrumb depending on the page it's accessed from
      breadcrumb: [],
      requestsBreadcrumb: [
        {
          text: "admin.requests.title",
          to: { name: "requests" },
        },
        { text: "admin.requests.showTitle", active: true },
      ],
      actualVsBudgetBreadcrumb: [
        {
          text: "admin.reports.actualVsBudget",
          to: {
            path: `/admin/reports/actual-vs-budget/:budgetId`,
          },
        },
        {
          text: "admin.reports.account",
          to: {
            path: `/admin/reports/actual-vs-budget/:budget:id/account/:accountId`,
          },
        },
        { text: "admin.requests.title", active: true },
      ],
      previousPage: {
        name: "requests",
      },
    },
    beforeEnter(to, from, next) {
      // Determine which breadcrumb to use
      if (from.name === "reports.actual-budget.account") {
        to.meta.actualVsBudgetBreadcrumb[0].to.path = `/admin/reports/actual-vs-budget/${from.params.budgetId}`;
        to.meta.actualVsBudgetBreadcrumb[1].to.path = `/admin/reports/actual-vs-budget/${from.params.budgetId}/account/${from.params.accountId}`;
        to.meta.breadcrumb = to.meta.actualVsBudgetBreadcrumb;
        to.meta.previousPage = to.meta.actualVsBudgetBreadcrumb[1].to;
      } else {
        to.meta.breadcrumb = to.meta.requestsBreadcrumb;
        to.meta.previousPage = to.meta.requestsBreadcrumb[0].to;
      }
      next();
    },
  },
  {
    path: "requests/add",
    name: "requests.add",
    component: () =>
      import(
        /* webpackChunkName: "show-requests" */ "@/views/siteadmin/requests/Add.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "requests",
      breadcrumb: [
        {
          text: "admin.requests.title",
          to: { name: "requests" },
        },
        { text: "admin.requests.addRequest", active: true },
      ],
    },
  },
  {
    path: "email-contents",
    name: "email-contents",
    component: () =>
      import(
        /* webpackChunkName: "email-contents" */ "@/views/siteadmin/emails/EmailContents.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "email-contents",
    },
  },
  {
    path: "email-contents/edit/:id",
    name: "email-contents.edit",
    component: () =>
      import(
        /* webpackChunkName: "email-contents" */ "@/views/siteadmin/emails/Edit.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "email-contents",
      breadcrumb: [
        {
          text: "admin.emailTemplates.title",
          to: { name: "email-contents" },
        },
        { text: "admin.emailTemplates.editTemplateBreadcrumb", active: true },
      ],
    },
  },
  {
    path: "profile-fields",
    name: "profile-fields",
    component: () =>
      import(
        /* webpackChunkName: "profile-fields" */ "@/views/siteadmin/profile/ProfileFields.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "profile-fields",
    },
  },
  {
    path: "profile-fields/edit/:id",
    name: "profile-fields.edit",
    component: () =>
      import(
        /* webpackChunkName: "profile-fields" */ "@/views/siteadmin/profile/Edit.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "profile-fields",
      breadcrumb: [
        {
          text: "admin.profileFields.title",
          to: { name: "profile-fields" },
        },
        { text: "admin.profileFields.editField", active: true },
      ],
    },
  },
  {
    path: "pages",
    name: "pages",
    component: () =>
      import(
        /* webpackChunkName: "pages" */ "@/views/siteadmin/pages/Pages.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "pages",
    },
  },
  {
    path: "pages/edit/:id",
    name: "pages.edit",
    component: () =>
      import(
        /* webpackChunkName: "pages" */ "@/views/siteadmin/pages/Edit.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "pages",
      breadcrumb: [
        {
          text: "admin.pages.title",
          to: { name: "pages" },
        },
        { text: "admin.pages.editPageBreadcrumb", active: true },
      ],
    },
  },
  {
    path: "styles",
    name: "styles",
    component: () =>
      import(/* webpackChunkName: "styles" */ "@/views/siteadmin/Styles.vue"),
    meta: {
      requiresAuth: true,
      activeMenu: "styles",
    },
  },
  {
    path: "styles/:id",
    name: "styles.edit",
    component: () =>
      import(/* webpackChunkName: "styles" */ "@/views/siteadmin/Styles.vue"),
    meta: {
      requiresAuth: true,
      activeMenu: "styles",
    },
  },
  {
    path: "settings",
    name: "settings",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/siteadmin/Settings.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "settings",
    },
  },
  {
    path: "websites",
    name: "websites",
    component: () =>
      import(
        /* webpackChunkName: "websites" */ "@/views/superadmin/websites/Websites.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "websites",
    },
  },
  {
    path: "websites/create",
    name: "websites.create",
    component: () =>
      import(
        /* webpackChunkName: "websites" */ "@/views/superadmin/websites/CreateEditWebsite.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "websites",
    },
  },
  {
    path: "websites/edit/:id",
    name: "websites.edit",
    component: () =>
      import(
        /* webpackChunkName: "websites" */ "@/views/superadmin/websites/CreateEditWebsite.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "websites",
    },
  },
  {
    path: "websites/copy/:sourceId",
    name: "websites.copy",
    component: () =>
      import(
        /* webpackChunkName: "websites" */ "@/views/superadmin/websites/CreateEditWebsite.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "websites",
    },
  },
  {
    path: "websites/:id/create-admin",
    name: "websites.create-admin",
    component: () =>
      import(
        /* webpackChunkName: "websites" */ "@/views/superadmin/websites/CreateAdmin.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "websites",
    },
  },
  {
    path: "users",
    name: "users",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/superadmin/users/Users.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "users",
    },
  },
  {
    path: "users/create",
    name: "users.create",
    component: () =>
      import(
        /* webpackChunkName: "create-users" */ "@/views/superadmin/users/CreateEditUser.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "users",
    },
  },
  {
    path: "users/edit/:id",
    name: "user.edit",
    component: () =>
      import(
        /* webpackChunkName: "edit-user" */ "@/views/superadmin/users/CreateEditUser.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "users",
    },
  },
  {
    path: "site-users",
    name: "site-users",
    component: () =>
      import(
        /* webpackChunkName: "site-users" */ "@/views/siteadmin/site-users/Users.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "site-users",
    },
  },
  {
    path: "site-users/create",
    name: "site-users.create",
    component: () =>
      import(
        /* webpackChunkName: "site-users" */ "@/views/siteadmin/site-users/CreateEditUser.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "site-users",
      breadcrumb: [
        {
          text: "admin.users.title",
          to: { name: "site-users" },
        },
        { text: "admin.users.createUser", active: true },
      ],
    },
  },
  {
    path: "site-users/edit/:id",
    name: "site-user.edit",
    component: () =>
      import(
        /* webpackChunkName: "site-user" */ "@/views/siteadmin/site-users/CreateEditUser.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "site-users",
      breadcrumb: [
        {
          text: "admin.users.title",
          to: { name: "site-users" },
        },
        { text: "admin.users.editUser", active: true },
      ],
    },
  },
  {
    path: "budgets",
    name: "budgets.index",
    component: () =>
      import(
        /* webpackChunkName: "budgets" */ "@/views/siteadmin/budgets/Budgets.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "budgets",
    },
  },
  {
    path: "budgets/setup",
    name: "budgets.setup",
    component: () =>
      import(
        /* webpackChunkName: "budgets" */ "@/views/siteadmin/budgets/Setup.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "budgets",
    },
  },
  {
    path: "budgets/create",
    name: "budgets.create",
    component: () =>
      import(
        /* webpackChunkName: "budgets" */ "@/views/siteadmin/budgets/CreateEditBudget.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "budgets",
      breadcrumb: [
        {
          text: "admin.budgets.title",
          to: { name: "budgets.index" },
        },
        { text: "admin.budgets.addBudget", active: true },
      ],
    },
  },
  {
    path: "budgets/edit/:id",
    name: "budgets.edit",
    component: () =>
      import(
        /* webpackChunkName: "budgets" */ "@/views/siteadmin/budgets/CreateEditBudget.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "budgets",
      breadcrumb: [
        {
          text: "admin.budgets.title",
          to: { name: "budgets.index" },
        },
        { text: "admin.budgets.editBudget", active: true },
      ],
    },
  },
  {
    path: "accounts",
    name: "accounts",
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "@/views/siteadmin/accounts/Accounts.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "budgets",
      breadcrumb: [
        {
          text: "admin.budgets.title",
          to: { name: "budgets.index" },
        },
        { text: "admin.accounts.title", active: true },
      ],
    },
  },
  {
    path: "reports/actual-vs-budget",
    name: "reports.actual-budget",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/siteadmin/reports/ActualVsBudget.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "reports.actual-budget",
    },
  },
  {
    path: "reports/actual-vs-budget/:budgetId",
    name: "reports.actual-budget-id",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/siteadmin/reports/ActualVsBudget.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "reports.actual-budget",
    },
  },
  {
    path: "reports/actual-vs-budget/:budgetId/account/:accountId",
    name: "reports.actual-budget.account",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/siteadmin/reports/ActualVsBudget.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "reports.actual-budget",
      breadcrumb: [
        {
          text: "admin.reports.actualVsBudget",
          to: { path: "/admin/reports/actual-vs-budget/:budgetId" },
        },
        { text: "admin.reports.account", active: true },
      ],
    },
    beforeEnter(to, _, next) {
      to.meta.breadcrumb[0].to.path = `/admin/reports/actual-vs-budget/${to.params.budgetId}`;
      next();
    },
  },
  {
    path: "reports/comparative",
    name: "reports.comparative",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/siteadmin/reports/Comparative.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "reports.comparative",
    },
  },
  {
    path: "groups",
    name: "groups.index",
    component: () =>
      import(
        /* webpackChunkName: "groups" */ "@/views/siteadmin/groups/Groups.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "groups",
    },
  },
  {
    path: "groups/create",
    name: "groups.create",
    component: () =>
      import(
        /* webpackChunkName: "groups" */ "@/views/siteadmin/groups/CreateEditGroup.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "groups",
      breadcrumb: [
        {
          text: "admin.groups.title",
          to: { name: "groups.index" },
        },
        { text: "admin.groups.createGroup", active: true },
      ],
    },
  },
  {
    path: "groups/edit/:id",
    name: "groups.edit",
    component: () =>
      import(
        /* webpackChunkName: "groups" */ "@/views/siteadmin/groups/CreateEditGroup.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "groups",
      breadcrumb: [
        {
          text: "admin.groups.title",
          to: { name: "groups.index" },
        },
        { text: "admin.groups.editGroupRelation", active: true },
      ],
    },
  },
  {
    path: "groups/create/relations",
    name: "groups.relations.create",
    component: () =>
      import(
        /* webpackChunkName: "groups" */ "@/views/siteadmin/groups/CreateEditGroupRelations.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "groups",
      breadcrumb: [
        {
          text: "admin.groups.title",
          to: { name: "groups.index" },
        },
        { text: "admin.groups.createGroupRelation", active: true },
      ],
    },
  },
  {
    path: "groups/edit/relations/:id",
    name: "groups.relations.edit",
    component: () =>
      import(
        /* webpackChunkName: "groups" */ "@/views/siteadmin/groups/CreateEditGroupRelations.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "groups",
      breadcrumb: [
        {
          text: "admin.groups.title",
          to: { name: "groups.index" },
        },
        { text: "admin.groups.editGroup", active: true },
      ],
    },
  },
  {
    path: "properties/create",
    name: "properties.create",
    component: () =>
      import(
        /* webpackChunkName: "properties" */ "@/views/siteadmin/requests/request/PropertyEvaluation.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "properties",
    },
  },
  {
    path: "properties/edit/:propertyId",
    name: "properties.edit",
    component: () =>
      import(
        /* webpackChunkName: "properties" */ "@/views/siteadmin/requests/request/PropertyEvaluation.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "properties",
    },
  },
  {
    path: "benefits",
    name: "benefits",
    component: () =>
      import(
        /* webpackChunkName: "benefits" */ "@/views/superadmin/benefits/Benefits.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "benefits",
    },
  },
  {
    path: "benefits/create",
    name: "benefits.create",
    component: () =>
      import(
        /* webpackChunkName: "create-benefits" */ "@/views/superadmin/benefits/CreateEditBenefits.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "benefits",
    },
  },
  {
    path: "benefits/edit/:id",
    name: "benefits.edit",
    component: () =>
      import(
        /* webpackChunkName: "edit-benefits" */ "@/views/superadmin/benefits/CreateEditBenefits.vue"
      ),
    meta: {
      requiresAuth: true,
      activeMenu: "benefits",
    },
  },
];
