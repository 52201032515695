export default {
  title: "Styles",
  saveSuccessMessage: "Theme updated.",
  headerAdmin: "Header admin",
  headerAdminName: "Company name",
  headerAdminLogo: "Company logo",
  headerAdminInvertedLogo: "Inverted company logo",
  header: "Header",
  headerColor: "Header colour",
  headerTitle: "Header title",
  headerTitleColor: "Header title colour",
  headerImage: "Header image",
  headerImageAlt: "Header image alt",
  headerLinkColor: "Header link colour",
  headerLinkActiveColor: "Header link active colour",
  headerLinkHoverColor: "Header link hover colour",
  page: "Page",
  fontSize: "Font size",
  font: "Font",
  chooseFont: "Choose Font",
  backgroundColor: "Background colour",
  contentBackgroundColor: "Content background colour",
  textColor: "Text colour",
  menuColor: "Menu colour",
  menuTextColor: "Menu text colour",
  nextButtonColor: "Next button colour",
  nextButtonTextColor: "Next button text colour",
  previousButtonColor: "Previous button colour",
  previousButtonTextColor: "Previous button text colour",
  uploadButtonColor: "Upload button colour",
  uploadButtonTextColor: "Upload button text colour",
  confirmationColor: "Confirmation box colour",
  confirmationTextColor: "Confirmation box text colour",
  footer: "Footer",
  footerColor: "Footer colour",
  footerLinkColor: "Footer link colour",
  banner: "Banner",
  banner_mobile: "Mobile banner",
  bannerAlt: "Alternative text",
  bannerPosition: "Banner position",
  bannerPositionTop: "Above header",
  bannerPositionMiddle: "Below header",
  bannerPositionBottom: "Below page content",
};
