import _ from "lodash";
import api from "../../api";
import shortid from "shortid";

export default {
  state: {
    items: [],
    loading: false,
    fetched: false,
  },

  mutations: {
    ACCOUNTS_START_FETCHING: (state) => {
      state.loading = true;
    },

    ACCOUNTS_FETCHED: (state, items) => {
      state.items = items;
      state.fetched = true;
      state.loading = false;
    },

    ACCOUNT_CREATED: (state, item) => {
      state.items.push(item);
    },
    ACCOUNT_UPDATED: (state, item) => {
      const object = _.find(state.items, { id: item.id });

      if (object) {
        const index = state.items.indexOf(object);
        state.items.splice(index, 1, item);
      }
    },
    ACCOUNT_DELETED: (state, id) => {
      const object = _.find(state.items, { id });

      if (object) {
        const index = state.items.indexOf(object);
        state.items.splice(index, 1);
      }
    },
  },

  actions: {
    async fetchAccounts({ state, commit }, query) {
      if (!state.loading) {
        commit("ACCOUNTS_START_FETCHING");
        const items = await api.accounts.query(query);
        items.forEach((item) => {
          item.key = shortid.generate();
        });
        commit("ACCOUNTS_FETCHED", items);
      }
    },

    async createAccount({ commit }, websiteId, account) {
      const createdAccount = await api.accounts.create(websiteId, account);
      commit("ACCOUNT_CREATED", createdAccount);
      return createdAccount;
    },

    async updateAccount({ commit }, account) {
      const updatedBudget = await api.accounts.update(account);
      commit("ACCOUNT_UPDATED", updatedBudget);
      return updatedBudget;
    },

    async deleteAccount({ commit }, { websiteId, id }) {
      await api.accounts.delete({ websiteId, id });
      commit("ACCOUNT_DELETED", id);
    },
  },
};
