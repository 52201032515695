export default {
  metaInfo() {
    return {
      title: this.siteTitle ? this.siteTitle : "BrickRoad",
      style: [
        {
          cssText: `
          body {
            background-color:  ${this.styles.background_color} !important;
            font-size: ${this.styles.font_size}px !important;
            color:  ${this.styles.text_color} !important;
          }

          *:not(.fa) {
            font-family: ${this.styles.font} !important;
          }

          .comp-index-public .card, .comp-index-public .card p, .comp-index-public .card li {
            font-size: ${this.styles.font_size}px !important;
          }
          .comp-index-public .alert-warning-custom span {
            font-size: ${this.styles.font_size}px !important;
            font-family: ${this.styles.font} !important;
          }
          .comp-index-public .lang {
            color: ${this.styles.header_link_color} !important;
          }
          .comp-index-public .lang-select {
            color: ${this.styles.header_link_active_color} !important;
          }
          .comp-index-public .navbar-header a,
          .comp-index-public .navbar-header .lang,
          .comp-index-public .navbar-header a {
              color: ${this.styles.header_link_color} !important;
          }
          .comp-index-public .navbar-header .lang-select {
              color: ${this.styles.header_link_active_color} !important;
          }
          .comp-index-public .navbar-header a:hover,
          .comp-index-public .navbar-header .lang:hover,
          .comp-index-public .navbar-header .lang-select:hover,
          .comp-index-public .navbar-header a:focus {
              color: ${this.styles.header_link_hover_color} !important;
          }
          .comp-index-public .site-title {
            color: ${this.styles.header_title_color} !important;
          }
          .comp-index-public .navbar {
            background-color: ${this.styles.header_color} !important;
          }
          .comp-index-public .card {
            background-color: ${this.styles.content_background_color} !important;
            color:  ${this.styles.text_color} !important;
          }
          .comp-index-public .nav-tabs {
              background-color: ${this.styles.menu_color} !important;
          }
          .comp-index-public .btn-next {
              background-color: ${this.styles.next_btn_color} !important;
              color: ${this.styles.next_btn_text_color} !important;
          }
          .comp-index-public .btn-prev {
              background-color: ${this.styles.prev_btn_color} !important;
              color: ${this.styles.prev_btn_text_color} !important;
          }
          .comp-index-public .btn-upload {
              background-color: ${this.styles.upload_btn_color} !important;
              color: ${this.styles.upload_btn_text_color} !important;
          }
          .comp-index-public .confirmation-content {
              background-color: ${this.styles.confirmation_color} !important;
              color: ${this.styles.confirmation_text_color} !important;
          }
          .comp-index-public .footer-link-color {
              color: ${this.styles.footer_link_color} !important;
          }
          .comp-index-public .footer-color {
              background-color: ${this.styles.footer_color} !important;
          }
          .comp-index-public .page-content {
            background-color: ${this.styles.content_background_color} !important;
          }
          .comp-index-public .nav-link {
            color: ${this.styles.menu_text_color} !important;
          }

          `,
          type: "text/css",
        },
      ],
    };
  },
};
