import Validator from "validatorjs";

Validator.register("total_100", function (object) {
  let total = calcTotal(object);
  if (total === 0) {
    return true;
  }
  return total === 100;
});

Validator.register("total_between_0_and_100", function (object) {
  let total = calcTotal(object);
  return total >= 0 && total <= 100;
});

Validator.register("all_required", function (object) {
  if (typeof object !== "object") {
    return false;
  }

  let hasError = false;

  Object.keys(object).forEach((key) => {
    const value = object[key];

    if (value === null || value === "") {
      hasError = true;
    }
  });

  return !hasError;
});

Validator.register("at_least_one", function (object) {
  if (typeof object !== "object") {
    return false;
  }

  return Object.values(object).some((value) => value !== "" && value !== null);
});

// Functions for fields
function calcTotal(object) {
  let total = 0;

  if (typeof object !== "object") {
    return false;
  }

  Object.keys(object).forEach((key) => {
    const value = Number(object[key]);

    if (value && !isNaN(value)) {
      total += value;
    }
  });
  return total;
}

export default Validator;
