export default {
  title: "Settings",
  saveSuccessMessage: "Setting updated.",
  applicationLang: "Site language settings",
  applicationLangFr: "French",
  applicationLangEn: "English",
  score: "Score",
  twoFactorAuth: "Two-factor authentication",
  enableTwoFactor: "Enable two-factor authentication",
  minScore: "Min score",
  maxScore: "Max score",
  defaultReviewer: "Default reviewer",
  user: "User",
  activitySummary: "Activity summary by email",
  frequency: "Frequency",
  weekly: "Weekly",
  monthly: "Monthly",
  never: "Never (no summary will be sent)",
  emailSettings: "Email settings",
  senderName: "Sender name",
  senderAddress: "Sender address",
  smtpSettings: "Configure SMTP settings",
  testMessage: "Send a test message",
  serverAddress: "Server address",
  port: "Port",
  encryption: "Encryption",
  username: "Username",
  password: "Password",
  fiscalYear: "Company fiscal year",
  fiscalYearShort: "Fiscal year",
  brandProfile: "Brand profile (property evaluation)",
  region: "Region",
  regionAssignment: "Assignment by region",
  confirmDeleteAssignmentByRegionMessage:
    "Are you sure you want to delete this Assignment by region?",
};
