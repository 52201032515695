import api from "../../api";

export default {
  state: {
    settings: [],
    brandProfiles: [],
    applicationUrls: [],
    settingsFetched: false,
    settingsLoading: false,
    settingSaved: false,
  },

  mutations: {
    SETTINGS_START_FETCHING: (state) => {
      state.settingsLoading = true;
    },

    SETTINGS_FETCHED: (state, fields) => {
      state.settings = fields;
      state.settingSaved = false;
      state.settingsFetched = true;
      state.settingsLoading = false;
    },

    SETTINGS_SAVED: (state, fields) => {
      state.settings = {
        ...state.settings,
        ...fields,
      };
      state.settingSaved = true;
      state.settingsFetched = true;
      state.settingsLoading = false;
    },

    BRAND_PROFILE_FETCHED: (state, brandProfiles) => {
      state.brandProfiles = brandProfiles;
      state.settingsFetched = true;
      state.settingsLoading = false;
    },

    APP_URLS_FETCHED: (state, urls) => {
      state.applicationUrls = urls;
    },
  },

  actions: {
    async fetchSettings({ commit }, payload) {
      commit("SETTINGS_START_FETCHING");

      return api.getCurrentSettings(payload).then((settings) => {
        commit("SETTINGS_FETCHED", settings);
      });
    },

    async editCurrentSettings({ commit }, payload) {
      commit("SETTINGS_START_FETCHING");

      return api.editCurrentSettings(payload).then((settings) => {
        commit("SETTINGS_SAVED", settings);
      });
    },

    async fetchBrandProfiles({ commit }, payload) {
      commit("SETTINGS_START_FETCHING");

      return api.getWebsiteBrandProfiles(payload).then((brandProfiles) => {
        commit("BRAND_PROFILE_FETCHED", brandProfiles);
      });
    },

    async fetchApplicationUrls({ commit }, payload) {
      try {
        const urls = await api.getApplicationUrls(payload);
        commit("APP_URLS_FETCHED", urls);
      } catch {
        //...
      }
    },
  },
};
