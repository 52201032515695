import api from "../../api";
import _ from "lodash";

export default {
  state: {
    items: [],
    relations: [],
    loading: false,
    fetched: false,
  },

  mutations: {
    GROUPS_START_FETCHING: (state) => {
      state.loading = true;
    },

    GROUPS_FETCHED: (state, items) => {
      state.items = items;
      state.fetched = true;
      state.loading = false;
    },

    GROUP_CREATED: (state, item) => {
      state.items.push(item);
    },

    GROUP_UPDATED: (state, group) => {
      const object = _.find(state.items, { id: group.id });

      if (object) {
        const index = state.items.indexOf(object);
        state.items.splice(index, 1, group);
      }
    },

    GROUP_DELETED: (state, id) => {
      const object = _.find(state.items, { id });

      if (object) {
        const index = state.items.indexOf(object);
        state.items.splice(index, 1);
      }
    },

    GROUP_RELATION_START_FETCHING: (state) => {
      state.loading = true;
    },

    GROUP_RELATION_FETCHED: (state, relations) => {
      state.relations = relations;
      state.fetched = true;
      state.loading = false;
    },

    GROUP_RELATION_CREATED: (state, relations) => {
      state.relations.push(relations);
    },

    GROUP_RELATION_UPDATED: (state, relation) => {
      const object = _.find(state.relations, { id: relation.id });

      if (object) {
        const index = state.relations.indexOf(object);
        state.relations.splice(index, 1, relation);
      }
    },

    GROUP_RELATION_DELETED: (state, id) => {
      const object = _.find(state.relations, { id });

      if (object) {
        const index = state.relations.indexOf(object);
        state.relations.splice(index, 1);
      }
    },
  },

  actions: {
    async fetchGroups({ state, commit }, query) {
      if (!state.loading) {
        commit("GROUPS_START_FETCHING");
        return await api.groups.query(query).then((items) => {
          commit("GROUPS_FETCHED", items);
        });
      }
    },

    async createGroup({ commit }, group) {
      const createdGroup = await api.groups.create(group);
      commit("GROUP_CREATED", createdGroup);
      return createdGroup;
    },

    async updateGroup({ commit }, group) {
      const updatedGroup = await api.groups.update(group);
      commit("GROUP_UPDATED", updatedGroup);
      return updatedGroup;
    },

    async deleteGroup({ commit }, { websiteId, groupId }) {
      await api.groups.delete({ websiteId, id: groupId });
      commit("GROUP_DELETED", groupId);
    },

    async fetchGroupRelations({ state, commit }, query) {
      if (!state.loading) {
        commit("GROUP_RELATION_START_FETCHING");
        return await api.groups.fetchGroupRelations(query).then((relations) => {
          commit("GROUP_RELATION_FETCHED", relations);
        });
      }
    },

    async createGroupRelation({ commit }, groupRelation) {
      return await api.groups
        .createGroupRelation(groupRelation)
        .then((groupRelation) => {
          commit("GROUP_RELATION_CREATED", groupRelation);
        });
    },

    async updateGroupRelation({ commit }, groupRelation) {
      return await api.groups
        .updateGroupRelation(groupRelation)
        .then((updatedGroupRelation) => {
          commit("GROUP_RELATION_UPDATED", updatedGroupRelation);
        });
    },

    async deleteGroupRelation({ commit }, { websiteId, groupRelationId }) {
      await api.groups
        .deleteGroupRelation({ websiteId, id: groupRelationId })
        .then(() => {
          commit("GROUP_RELATION_DELETED", groupRelationId);
        });
    },
  },
};
