export default {
  dashboard: "Tableau de bord",
  questions: "Questions",
  requests: "Demandes",
  emailTemplates: "Contenu des courriels",
  profileFields: "Champs de profil",
  pages: "Pages",
  styles: "Présentation visuelle",
  reports: "Rapports budgétaires",
  actualVsBudget: "Suivi budgétaire",
  comparative: "Comparatif",
  budgets: "Gestion des budgets",
  settings: "Réglages",
  generalSettings: "Réglages généraux",
  groups: "Groupes",
  users: "Utilisateurs",
};
