export default {
  changePassword: "Changer le mot de passe",
  loginLinkInvalid:
    "Votre lien de connexion a expiré. Contactez l’administrateur du site.",
  logout: "Déconnexion",
  signIn: "Connexion",
  twoFactorLogin: "Two factor login",
  incorrectVerificationCode: "Le code de vérification est invalide.",
  generateNewText1: "Vous n'avez pas encore reçu votre code?",
  generateNewText2: "Cliquez ici pour en générer un nouveau.",
  generateTokenConfirmation: "Un nouveau code vous à été envoyé avec succès.",
  generateTokenError: "Il y a eu un problème lors de l'envoi du nouveau code.",
  email: "Courriel",
  password: "Mot de passe",
  forgotPasswordLink: "Mot de passe oublié?",
  verificationCode: "Code de vérification",
  login: "Connexion",
  incorrectLoginMessage:
    "Identifiant ou mot de passe incorrect. Veuillez réessayer.\nTentative {attempt} de {maxAttempts}",
  newPasswordRequired: "Nouveau mot de passe requis.",
  forgotPasswordTitle: "Mot de passe oublié",
  enterEmailToRecoverPassword:
    "Veuillez entrer votre courriel dans le champ ci-dessous. Un lien permettant de réinitialiser votre mot de passe vous sera envoyé.",
  emailNotFound: "Courriel introuvable",
  passwordThrottled:
    "Vous avez récemment demandé la réinitialisation de votre mot de passe, veuillez vérifier votre messagerie.",
  readOnlyUser:
    "Votre mot de passe ne peut être récupéré ici. Veuillez contacter votre soutien informatique.",
  resetLinkSent:
    "Un lien permettant de réinitialiser votre mot de passe vous a été envoyé.",
  currentPassword: "Mot de passe actuel",
  newPassword: "Nouveau mot de passe",
  confirmPassword: "Confirmation du mot de passe",
  currentPasswordIncorrect: "Mot de passe incorrect",
  passwordHasBeenChanged: "Votre mot de passe a été changé",
  chooseNewPassword: "Veuillez choisir un nouveau mot de passe.",
  invalidPassword: "Le mot de passe doit contenir au moins 8 caractères.",
  invalidPasswordConfirmation:
    "Le mot de passe et la confirmation doivent correspondre.",
  invalidUser: "Veuillez vérifier votre adresse courriel et recommencer.",
  invalidResetToken:
    "Ce lien de réinitialisation a expiré ou n'est pas valide.",
  backToLogin: "Retour au Login",
  publicWebsite: "Site public",
  forceChangePasswordModalBody:
    "Votre mot de passe est expiré et doit être remplacé.",
  forceChangePasswordModalBody2:
    "Votre nouveau mot de passe doit comporter au moins un chiffre, une lettre majuscule ainsi qu’un symbole.",
};
