export default {
  imageInput: {
    uploadFile: "Upload file",
    view: "View",
    delete: "Delete",
  },
  budgetSelector: {
    select: "Select financial year...",
  },
  productSelector: {
    addNew: "Add new",
  },
  editProductList: {
    rename: "Rename",
    delete: "Delete",
    confirmDeleteMessage: "Are you sure you want to delete this group?",
    emptyList: "No product has been registered yet",
  },
};
