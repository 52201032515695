import Vue from "vue";
import VueI18n from "vue-i18n";
import frContent from "@/lang/fr";
import enContent from "@/lang/en";

Vue.use(VueI18n);

const supportedLanguages = ["fr", "en"];

const browserLanguage = navigator.language
  ? navigator.language.substring(0, 2)
  : "fr";
const storedLanguage = localStorage.getItem("user_lang");

let locale = browserLanguage;

if (storedLanguage && supportedLanguages.indexOf(storedLanguage) > -1) {
  locale = storedLanguage;
}

export default new VueI18n({
  locale,
  messages: {
    fr: frContent,
    en: enContent,
  },
});
